import { useMemo } from 'react';
import { useAppData, useQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';
import { useRouter } from '@tripledotstudios/react-core';

function configurationOptionsTransform(option, { availabilityState, availabilityStateLive }) {
  return Object.assign(option, {
    availabilityState,
    availabilityStateLive,
    label: `#${option.value} - ${option.label}`,
  });
}

export default function useObjectiveConfigurationOptions() {
  const { query } = useRouter();
  const { enums } = useAppData();
  const activityTypes = enums['Events::ActivityTypes'];
  const { response: objectiveConfigurations, isLoading: isObjectivesLoading } = useQuery(
    (params) => EventsRoutes.ObjectiveConfigurations.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: collectionSets, isLoading: isCollectionSetsLoading } = useQuery(
    (params) => EventsRoutes.CollectionSets.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: races, isLoading: isRacesLoading } = useQuery(
    (params) => EventsRoutes.Races.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );
  const { response: clientLeaderboards, isLoading: isLeaderboardsLoading } = useQuery(
    (params) => EventsRoutes.ClientLeaderboards.indexRequest(params, { showAlert: false }),
    { ...query, withoutPagination: true },
  );

  const configurationOptions = useMemo(() => ({
    [activityTypes.OBJECTIVE]: toOptions(objectiveConfigurations?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.COLLECTION_SET]: toOptions(collectionSets?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.RACE]: toOptions(races?.items || [], {
      transform: configurationOptionsTransform,
    }),
    [activityTypes.CLIENT_LEADERBOARD]: toOptions(clientLeaderboards?.items || [], {
      transform: configurationOptionsTransform,
    }),
  }), [objectiveConfigurations?.items?.length, collectionSets?.items?.length, races?.items?.length]);

  return {
    configurationOptions,
    isLoading: isObjectivesLoading || isCollectionSetsLoading || isRacesLoading || isLeaderboardsLoading,
  };
}
