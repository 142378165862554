import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { Button, InUse } from '@components';

export default function List({
  response, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Max number returned',
        Cell: ({ row: { original: { maxNumberReturned } } }) => (
          +maxNumberReturned > 0 ? maxNumberReturned : 'No restrictions'
        ),
      },
      {
        Header: 'Max number of activities',
        Cell: ({ row: { original: { maxActivities } } }) => (
          +maxActivities > 0 ? maxActivities : 'No restrictions'
        ),
      },
      InUse.InUseColumn,
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, inUse, permissions,
            },
          },
        }) => {
          const isInUse = InUse.isInUse(inUse);

          return (
            <>
              {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
              {!isInUse && permissions.destroy
                && (
                <Button.DeleteConfirm
                  id={id}
                  routes={routes}
                  onDelete={onDelete}
                  recordName={`Events Type "${name}"`}
                />
                )}
            </>
          );
        },
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No types yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
