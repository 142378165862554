import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CancelIcon } from '../icons';

const StyledButton = styled((props) => <Button variant="secondary" as={Link} {...props} />)`
  margin-right: 4px;
`;

const CancelLink = ({ title = 'Cancel', ...props }) => (
  <StyledButton {...props}>
    <CancelIcon />
    {' '}
    {title}
  </StyledButton>
);

export default CancelLink;
