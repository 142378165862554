import React from 'react';
import { ListIcon } from '@components/icons';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    max-height: 50%;
`;

const StyledTitle = styled.p`
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
`;

const EmptyList = ({ title }) => (
  <StyledContainer>
    <ListIcon />
    <StyledTitle>{title}</StyledTitle>
  </StyledContainer>
);

export default EmptyList;
