import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Form, InUse } from '@components';
import { FloatingLabeledSelect, FloatingLabeledInput, Input } from '@components/resource';

import { useRouter } from '@tripledotstudios/react-core';
import { AccountsRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';
import { useEventsCollectionSets } from '@pages/events/collection_sets/queries';
import { toOptions } from '@/services/utils';

const NewCardForm = ({ occurrenceId, refetch }) => {
  const { query } = useRouter();
  const [shown, setShown] = useState(false);
  const canWrite = hasApplicationPermissions(['player', 'write']);
  const collectionSetsQuery = useEventsCollectionSets({ applicationId: query.applicationId });

  const requestParams = { applicationId: query.applicationId, userId: query.id };

  const handleNewClick = () => {
    setShown(true);
  };

  const isSomeLoading = [collectionSetsQuery].some(({ isLoading }) => isLoading);
  if (isSomeLoading) return null;

  const collectionSetsOptions = toOptions(collectionSetsQuery.data.items, {
    transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
      availabilityStateLive,
      availabilityState,
    }),
  });

  return (
    shown ? (
      <Form defaultValues={{ occurrenceId }} enableReinitialize>
        {({ setError }) => {
          const [cardsOptions, setCards] = useState([]);
          const responseHandler = buildResourceResponseHandler({
            actionName: 'create',
            setError,
            onSuccess: () => {
              setShown(false);
              refetch();
            },
          });
          const onSubmit = (values) => (
            AccountsRoutes.PlayerCards.createRequest({ ...requestParams, ...values }).then(responseHandler)
          );

          const handleSetChange = (value) => {
            const set = collectionSetsQuery.data.items.find(({ id }) => id === value);
            const newCards = set.cards.map(({ id, rarity, position }) => (
              { label: `Position: ${position}, Rarity: ${rarity}`, value: id }
            ));
            setCards(newCards);
          };

          return (
            <Form.Component onSubmit={onSubmit}>
              <Input type="hidden" name="occurrenceId" />
              <Row sm={4} xs={1}>
                <Col>
                  <FloatingLabeledSelect
                    onChange={handleSetChange}
                    label="Collection Set"
                    formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
                    options={collectionSetsOptions}
                    name="collectionSetId"
                  />
                </Col>
                <Col><FloatingLabeledSelect label="Card" options={cardsOptions} name="cardId" /></Col>
                <Col>
                  <FloatingLabeledInput label="Quantity" name="quantity" type="number" />
                </Col>
                <Col><Button.Submit className="mt-2" title="Create" /></Col>
              </Row>
            </Form.Component>
          );
        }}
      </Form>
    ) : (
      canWrite && <Button.Add title="Add Player Card" onClick={handleNewClick} />
    )
  );
};

export default NewCardForm;
