import React, { useState } from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import { useCrudlRequests } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { PageHeader, SplitPane, Button } from '@components';
import { ButtonsFooter } from '@components/collection';
import { hasApplicationPermissions } from '@services/permissions';

import { useEventTypes } from './queries';
import List from './List';
import Form from './Form';

const Routes = EventsRoutes.Types;

export default function Index() {
  useRequireAuth();

  const { query } = useRouter();
  const { data: response, refetch } = useEventTypes(query);
  const [resource, setResource] = useState(null);
  const canCreate = hasApplicationPermissions(['events', 'type', 'write']);

  const {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  return (
    <>
      <PageHeader title="Events Types">
        {canCreate && <Button.Add title="Add Events Type" onClick={handleNew} />}
      </PageHeader>
      <SplitPane paneName="events_objective_templates">
        <SplitPane.Top>
          <List
            response={response}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDelete={handleDelete}
            routes={Routes}
          />
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ onClick: handleOnCancel }}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
}
