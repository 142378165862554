import React, { forwardRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useFormGroup, useEvent, useMergedRefs } from '@tripledotstudios/react-core';
import { Label, Error } from '@components/resource';
import { useFormPermissions } from '@hooks';

const ToggleButtonField = forwardRef(({
  name,
  className,
  onChange,
  defaultValue,
  options,
  disabled = false,
  type,
  ...rest
}, outerRef) => {
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();
  const { setValue } = useFormContext();
  return (
    <Controller
      name={fullName}
      disabled={disabled || readOnly}
      defaultValue={defaultValue}
      render={({
        field: {
          onChange: onFieldChange, value: fieldValue, ref, ...restRegistered
        },
      }) => {
        const inputRef = useMergedRefs([outerRef, ref]);
        const handleChange = useEvent((result) => {
          if (onChange) {
            try {
              onChange(result);
            } catch (e) {
              console.error(e);
            }
          }

          onFieldChange(result);
        });

        useEffect(() => {
          setValue(fullName, fieldValue || defaultValue);
        }, []);

        return (
          <>
            <ToggleButtonGroup
              type={type}
              ref={inputRef}
              onChange={handleChange}
              className={className}
              value={fieldValue}
              {...rest}
              {...restRegistered}
            >
              {options.map(({ value, label: optionLabel }) => (
                <ToggleButton
                  key={value}
                  id={value}
                  value={value}
                  variant="outline-dark"
                  disabled={disabled}
                >
                  {optionLabel}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Error name={name} />
          </>
        );
      }}
    />
  );
});

const LabeledToggleButtonField = ({
  label, sizes, tooltipText, labelProps, ...rest
}) => (
  <Label label={label} sizes={sizes} tooltipText={tooltipText} {...labelProps}>
    <ToggleButtonField {...rest} />
  </Label>
);

export { ToggleButtonField, LabeledToggleButtonField };
