import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.p`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const FormTitle = ({ value }) => (
  <StyledTitle>
    {value}
  </StyledTitle>
);

export default FormTitle;
