import React from 'react';
import { Link } from 'react-router-dom';
import { ChainIcon } from '../icons';
import ActionButton from './ActionButton';

const ChainLinkButton = (props) => (
  <ActionButton.Transparent
    as={Link}
    target="_blank"
    className="btn"
    icon={<ChainIcon />}
    {...props}
  />
);

export default ChainLinkButton;
