import React from 'react';
import { Filters } from '@tripledotstudios/react-core/table';

import { useAppData, useQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { ApplyDefaultsButton, AvailabilityStateFilter, CreatedByMeFilter } from '@components/filters';

export default function Filter({ defaults }) {
  const { enumOptions } = useAppData();
  const statusOptions = enumOptions['Events::ConfigurationStatuses'];
  const { response: types } = useQuery(EventsRoutes.Types.indexRequest, { withoutPagination: true });
  const typesOptions = types ? types.items.map(({ id, name }) => ({ label: name, value: id })) : [];

  return (
    <Filters.Container>
      <Filters.Select label="Status" name="statusIn" options={statusOptions} isMulti />
      <AvailabilityStateFilter />
      <Filters.Select label="Event type" name="typeIdIn" options={typesOptions} isMulti />
      <CreatedByMeFilter />
      <Filters.DateTime label="Starts at" name="startAt" />
      <Filters.DateTime label="Ends at" name="endAt" />
      <ApplyDefaultsButton defaults={defaults} />
    </Filters.Container>
  );
}
