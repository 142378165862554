import React from 'react';
import { some } from 'lodash';
import styled from 'styled-components';

import { useRouter } from '@tripledotstudios/react-core';
import { AutoCollapse } from '../PartialCollapse';
import linkTemplates from './links';

export const InUseList = styled.div`
  a {
    display: inline-block;

    &:not(:last-child) {
      &:after {
        content: ',';
        margin-right: 3px;
        display: inline;
      }
    }
  }
`;

export const InUseCollapse = ({ inUse }) => (
  <AutoCollapse minHeight={105}>
    <div className="d-flex flex-column">
      <InUseLinks inUse={inUse} />
    </div>
  </AutoCollapse>
);

export const isInUse = (inUse) => some(inUse, (subsets) => Array.isArray(subsets) && subsets.length > 0);

export const InUseText = ({ inUse }) => (
  Object.keys(inUse).map((inUseKey) => inUse[inUseKey].map((entity) => entity.name).join(', ')).join(';')
);

export const InUseLinks = ({ inUse }) => {
  const { query } = useRouter();
  const templates = linkTemplates();

  return inUse ? (
    <>
      {Object.keys(inUse).map(
        (inUseKey) => {
          const linkTemplate = templates[inUseKey];

          if (!linkTemplate) {
            console.error(`InUse definition for ${inUseKey} was not found`);

            return '';
          }
          return inUse[inUseKey].map((entity) => linkTemplate({ ...query, ...entity }));
        },
      )}
    </>
  ) : '';
};
