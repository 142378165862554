import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray } from '@hooks';
import Button from '@components/buttons';

import ProductsTable, { useBaseColumns } from './ProductsTable';

export default function CatalogProducts({
  products, storeProducts, productLabels, tableEditable, productsDiff,
}) {
  const { getValues } = useFormContext();

  const productPath = 'catalogProductsAttributes';
  const { generateName } = useFormGroup();
  const formGroupName = generateName(productPath);
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const [defaultProductValues, baseColumns] = useBaseColumns(products, storeProducts, productLabels);

  const appendNewProduct = () => {
    const existingProducts = getValues(formGroupName).filter(({ _destroy }) => _destroy !== true);
    let newLineGroup = 1;
    if (existingProducts.length > 0) {
      const existingLineGroup = existingProducts[existingProducts.length - 1]?.lineGroup;
      newLineGroup = +existingLineGroup + 1;
    }

    append({ ...defaultProductValues, lineGroup: newLineGroup });
  };

  const columns = useMemo(() => {
    const cols = baseColumns;

    if (tableEditable) {
      cols.push({
        key: 'actions',
        Header: () => 'Actions',
        Cell: () => null,
        // eslint-disable-next-line react/no-unstable-nested-components
        EditableCell: ({ row, index }) => (
          <Button.Delete onClick={() => handleDelete(row, index)} title="Remove" />
        ),
      });
    }

    return cols;
  }, [tableEditable]);

  return fields && (
    <>
      <ProductsTable
        fields={fields}
        tableEditable={tableEditable}
        columns={columns}
        productPath={productPath}
        products={products}
        productLabels={productLabels}
        storeProducts={storeProducts}
        productsDiff={productsDiff}
      />
      {tableEditable && <Button.Add className="mt-2" onClick={appendNewProduct} />}
    </>
  );
}
