import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { SyncIcon } from '../icons';

const StyledButton = styled(Button)`
  background-color: transparent;
  color: #3b5fad;

  &:hover, &:focus {
    color: white;
    background-color: var(--bs-btn-bg);
  }
`;

const SynchronizeButton = ({ title = 'Synchronize', ...props }) => (
  <StyledButton {...props}>
    <SyncIcon />
    {' '}
    {title}
  </StyledButton>
);

export default SynchronizeButton;
