import { useQuery } from '@tanstack/react-query';
import { PaymentsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'payments/product_labels';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function productLabelsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await PaymentsRoutes.ProductLabels.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useProductLabelsOptions(query = {}) {
  return useQuery(productLabelsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items),
  }));
}

export function useProductLabels(query = {}) {
  return useQuery(productLabelsQuery({ params: { ...query, includeInUse: false, withoutPagination: true } }));
}

