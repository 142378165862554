import { matchPath } from 'react-router-dom';
import { get } from 'lodash';

import { useAppData } from '@hooks';

const useCurrentApplicationKey = () => {
  const match = matchPath('/admin/applications/:applicationId/*', window.location.pathname);

  return match ? `application${match.params.applicationId}` : '';
};

/**
  * @param {RawPermissionsPath | RawPermissionsArrayPath} path
  */
function hasPermissions(path, entityPermissionLevel = 1) {
  const { permissions } = useAppData();

  const userPermissionLevel = get(permissions, path);
  if (userPermissionLevel === undefined) console.warn(`Wrong permissions path: ${path}`);

  return userPermissionLevel >= entityPermissionLevel;
}

/**
  * @param {AppScopedPermissionsPath | AppScopedPermissionsArrayPath} path
  */
function hasApplicationPermissions(path, permissionLevel = 1) {
  const appKey = useCurrentApplicationKey();
  const permissionsPath = typeof path === 'string' ? [appKey, path].join('.') : [appKey, ...path];

  return hasPermissions(permissionsPath, permissionLevel);
}

export {
  hasPermissions,
  hasApplicationPermissions,
};
