import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Card,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  useRouter, FormGroup, updateQuery, Tooltip,
} from '@tripledotstudios/react-core';

import { useLocationQuery, useFormPermissions } from '@hooks';
import { sumBy } from 'lodash';
import { hasError } from '@components/resource';
import { Status } from '@components/collection';
import { Button, InUse } from '@components';

import { ABTestingRoutes } from '@pages/routes';

const Routes = ABTestingRoutes.Experiments;

const buildVariantUrl = ({
  applicationId,
  experimentId,
  variantId,
}) => `${Routes.editPath({ applicationId, id: experimentId })}?variantId=${variantId}`;

const TitleSpan = styled.span`
  &:not(:first-child) {
    padding-left: 1em;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--cui-accordion-button-active-color,#2d1cc5);
    padding-right: 1em;
  }
`;

const StyledStatus = styled(Status)`
  margin-bottom: -4px!important;
  margin-top: -3px!important;
`;

const CardHeaderStyled = styled(Card.Header)`
  color: #35569c!important;
  background-color: #ebeff7!important;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
`;

const SegmentsPopover = ({ segmentNames }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Segments</Popover.Header>
      <Popover.Body>
        {segmentNames.map((segmentName) => (
          <div key={segmentName}>{segmentName}</div>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover}>
      <span>
        {segmentNames.length}
        {' '}
        segments
      </span>
    </OverlayTrigger>
  );
};

export default function ExperimentVariantTabs({
  experiment, onExcludeFromExperiment, indexedVariants, entityId, entityType, renderView,
}) {
  const router = useRouter();
  const params = useLocationQuery();
  const { readOnly } = useFormPermissions();

  const { query: { applicationId } } = router;
  const [activeKey, setActiveKey] = useState(null);
  const buildVariantTabKey = ({ id }) => `variant_${id}`;

  const {
    id,
    name,
    startAt,
    endAt,
    statusText,
    availabilityStateLive,
    priority,
    variants,
    segmentConfiguration,
  } = experiment;

  const overallWeight = sumBy(variants, ({ weight }) => +weight);

  const handleSelectTab = (key) => {
    const activeVariant = variants.find((item) => item.id === +key.split('_')[1]);
    if (activeVariant) updateQuery(router, { experimentId: id, variantId: activeVariant.id });
  };

  useEffect(() => {
    const variantId = params.get('variantId');
    if (variantId) {
      const variant = variants.find((item) => item.id === +variantId);
      if (variant) {
        setActiveKey(buildVariantTabKey(variant));
        return;
      }
    }

    setActiveKey(buildVariantTabKey(variants[0]));
  }, [JSON.stringify(variants), params.get('experimentId'), params.get('variantId')]);

  return (
    <Card key={id} className="mb-2">
      <CardHeaderStyled>
        <div style={{ width: '100%' }} className="d-flex justify-content-between">
          <div>
            <TitleSpan>
              <b>ID:</b>
              {' '}
              {id}
            </TitleSpan>
            <TitleSpan>
              <b>Name:</b>
              {' '}
              {name}
              <InUse.InLiveBadge className="ms-1" inLive={availabilityStateLive} />
            </TitleSpan>
            <TitleSpan>
              <b>Status:</b>
              {' '}
              <StyledStatus value={statusText} />
            </TitleSpan>
            <TitleSpan>
              <b>Priority:</b>
              {' '}
              {priority}
            </TitleSpan>
            <TitleSpan>
              {segmentConfiguration.all
                ? <span>All Segments</span>
                : <SegmentsPopover segmentNames={segmentConfiguration.segmentNames} />}
            </TitleSpan>
            <TitleSpan>
              <b>Start At:</b>
              {' '}
              {startAt}
            </TitleSpan>
            <TitleSpan>
              <b>End At:</b>
              {' '}
              {endAt || '-'}
            </TitleSpan>
            {!readOnly && (
              <TitleSpan>
                <Tooltip text="Exclude from experiment" placement="top">
                  <Button.WarningConfirm
                    size="sm"
                    title=""
                    confirmTitle="Are you sure?"
                    successText="Enitity successfully excluded from the experiment"
                    confirmText="Enitity will be excluded from the experiment"
                    requestParams={{ id, entityType, entityId }}
                    onSuccess={() => onExcludeFromExperiment(experiment)}
                    request={Routes.removeEntityRequest}
                  />
                </Tooltip>
              </TitleSpan>
            )}
          </div>
        </div>
      </CardHeaderStyled>
      <Card.Body>
        <Tabs className="mb-3" activeKey={activeKey} onSelect={handleSelectTab}>
          {variants.map((variant) => {
            const variantAttributes = indexedVariants[variant.entityVariantId];
            const percentageWeight = parseFloat(((variant.weight * 100) / overallWeight).toFixed(2));
            const { originalIndex } = variantAttributes;

            return (
              <Tab
                key={`variant_${variant.id}`}
                eventKey={`variant_${variant.id}`}
                tabClassName={hasError(`variantsAttributes.${originalIndex}`) ? 'has-errors' : ''}
                title={`${variant.name} (${percentageWeight}%)`}
              >
                <dl className="row">
                  <dt className="col-2">Entity Variant ID:</dt>
                  <dd className="col-8">{variantAttributes.id}</dd>
                  <dd className="col-2">
                    <Link
                      to={buildVariantUrl({ applicationId, experimentId: id, variantId: variant.id })}
                      target="_blank"
                    >
                      Go to variant
                    </Link>
                  </dd>
                </dl>
                <FormGroup name={`variantsAttributes.${originalIndex}`}>
                  {renderView({ variantAttributes })}
                </FormGroup>
              </Tab>
            );
          })}
        </Tabs>
      </Card.Body>
    </Card>
  );
}
