import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function DragAndDroppable({
  id,
  tag = 'div',
  disabled = false,
  dragHandleRender,
  children,
  ...props
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: transform ? 'relative' : undefined,
    zIndex: isDragging ? 100 : 1,
    opacity: isDragging ? 0.5 : 1,
    backgroundColor: 'white',
  };

  const content = dragHandleRender ? (
    <>
      {dragHandleRender?.({ attributes, listeners })}
      {children}
    </>
  ) : (
    children
  );

  let options = { ref: setNodeRef, style, ...props };
  if (!dragHandleRender) options = { ...options, ...attributes, ...listeners };

  return React.createElement(
    tag,
    options,
    content,
  );
}
