import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  useFormGroup,
  useRouter,
  updateQuery,
  FormGroup,
} from '@tripledotstudios/react-core';
import { get, sortBy } from 'lodash';

import { hasError } from '@components/resource';
import { useFormPermissions, useLocationQuery } from '@hooks';
import ExperimentsDropdown from './ExperimentsDropdown';
import ExperimentVariantTabs from './ExperimentVariantTabs';

const BASE_VIEW_KEY = 'base';

export default function ExperimentTabs({
  experiments, entityType, entityId, reloadEntityRequest, renderView,
}) {
  const router = useRouter();
  const params = useLocationQuery();
  const { generateName } = useFormGroup();
  const {
    watch,
    setValue,
    getValues,
    clearErrors,
  } = useFormContext();
  const { readOnly } = useFormPermissions();

  const [activeKey, setActiveKey] = useState(null);

  const experimentsPath = generateName('experiments');
  const variantsPath = generateName('variantsAttributes');

  const currentExperiments = watch(experimentsPath);
  const currentVariants = watch(variantsPath);

  const getRootValues = () => {
    const rootPath = generateName('').slice(0, -1);

    return rootPath ? getValues(rootPath) : getValues();
  };

  const buildActiveKey = (key) => (key === BASE_VIEW_KEY ? key : `experiment_${key}`);
  const openBaseView = () => updateQuery(router, { variantId: null, experimentId: null, view: BASE_VIEW_KEY });
  const openExperimentView = (experimentId) => updateQuery(router, { variantId: null, experimentId, view: null });

  const handleSelectTab = (key) => {
    if (key === BASE_VIEW_KEY) {
      openBaseView();
    } else {
      const experiment = currentExperiments.find((item) => item.id === +key.split('_')[1]);
      openExperimentView(experiment?.id);
    }
  };

  useEffect(() => {
    const experimentId = params.get('experimentId');
    if (experimentId) {
      const experiment = currentExperiments.find((item) => item.id === +experimentId);
      if (experiment) {
        setActiveKey(buildActiveKey(experiment.id));
        return;
      }
    }

    setActiveKey(buildActiveKey(BASE_VIEW_KEY));
  }, [JSON.stringify(currentExperiments), params.get('view'), params.get('experimentId')]);

  const { defaultVariantId } = getRootValues();
  const indexedVariants = getValues(variantsPath).reduce((memo, obj, index) => (
    { ...memo, [obj.id]: { ...obj, originalIndex: index } }
  ), {});

  const onExcludeFromExperiment = (experiment) => {
    const removedVariantIds = experiment.variants.filter((v) => !v.default).map((v) => v.entityVariantId);

    setValue(experimentsPath, currentExperiments.filter((e) => e.id !== experiment.id));
    setValue(variantsPath, currentVariants.filter((v) => !removedVariantIds.includes(v.id)));

    clearErrors();
    openBaseView();
  };

  const onAddExperiment = () => {
    reloadEntityRequest().then((res) => {
      const currentExperimentIds = currentExperiments.map((e) => e.id);
      const currentVariantIds = currentVariants.map((e) => e.id);

      const newExperiments = get(res.data, experimentsPath).filter((e) => !currentExperimentIds.includes(e.id));
      const newVariants = get(res.data, variantsPath).filter((v) => !currentVariantIds.includes(v.id));

      setValue(variantsPath, [...currentVariants, ...newVariants]);
      setValue(experimentsPath, [...currentExperiments, ...newExperiments]);

      openExperimentView(newExperiments[0].id);
    });
  };

  const availableExperimentsToAdd = experiments.filter((e) => !currentExperiments.map(({ id }) => id).includes(e.id));
  const sortedExperiments = sortBy(currentExperiments, [(obj) => -obj.priority]);

  const experimentHasErrors = (experiment) => (
    experiment.variants.filter((ev) => !ev.default).some((ev) => (
      hasError(`variantsAttributes.${indexedVariants[ev.entityVariantId].originalIndex}`)
    ))
  );
  const baseHasErrors = () => (
    hasError(`variantsAttributes.${indexedVariants[defaultVariantId].originalIndex}`)
  );

  const defaultVariant = indexedVariants[defaultVariantId];

  return (
    <>
      {readOnly || (
        <div className="position-relative float-end">
          <ExperimentsDropdown
            entityType={entityType}
            entityId={entityId}
            experiments={availableExperimentsToAdd}
            onSuccess={onAddExperiment}
          />
        </div>
      )}

      <Tabs
        className="mb-1"
        transition={false}
        activeKey={activeKey}
        onSelect={handleSelectTab}
      >
        {sortedExperiments.map((experiment) => (
          <Tab
            key={`experiment_${experiment.id}`}
            title={experiment.name}
            eventKey={buildActiveKey(experiment.id)}
            tabClassName={experimentHasErrors(experiment) ? 'has-errors' : ''}
          >
            {buildActiveKey(experiment.id) === activeKey && (
              <ExperimentVariantTabs
                indexedVariants={indexedVariants}
                defaultVariantId={defaultVariantId}
                entityId={entityId}
                entityType={entityType}
                experiment={experiment}
                onExcludeFromExperiment={onExcludeFromExperiment}
                renderView={({ variantAttributes }) => (
                  renderView({
                    variantAttributes,
                    defaultVariant,
                    openBaseView,
                    isBaseConfigView: false,
                  })
                )}
              />
            )}
          </Tab>
        ))}
        <Tab
          key={BASE_VIEW_KEY}
          title="Base Config"
          eventKey={buildActiveKey(BASE_VIEW_KEY)}
          tabClassName={baseHasErrors() ? 'has-errors' : ''}
        >
          <div className="mt-3 mb-2">
            <strong className="me-2">Entity Variant ID:</strong>
            {defaultVariant.id}
          </div>
          {buildActiveKey(BASE_VIEW_KEY) === activeKey && (
            <FormGroup name={`variantsAttributes.${defaultVariant.originalIndex}`}>
              {renderView({ variantAttributes: defaultVariant, openBaseView, isBaseConfigView: true })}
            </FormGroup>
          )}
        </Tab>
      </Tabs>
    </>
  );
}
