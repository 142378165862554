import React from 'react';
import { Helmet } from 'react-helmet';
import { useAppData, useCurrentApplication } from '@hooks';

const PageTitle = ({ text, entity, action }) => {
  const { shortEnvironment } = useAppData();
  let fullText = text;

  if (!fullText && action && entity) fullText = `${action} ${entity}`;
  if (!fullText) {
    const currentApplication = useCurrentApplication();

    fullText = currentApplication ? `${currentApplication.name} LiveOps` : 'LiveOps';
  }
  let title = `[${shortEnvironment}]`;

  title += ` ${fullText}`;

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageTitle;
