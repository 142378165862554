import React, { memo } from 'react';
import styled from 'styled-components';
import { Badge } from 'react-bootstrap';
import { get } from 'lodash';

const ColorStatusMap = {
  ACTIVE: ['#28a745', '#28a7454d'],
  IN_PROGRESS: ['#28a745', '#28a7454d'],
  PRELOADED: ['#28a745', '#28a7454d'],
  INACTIVE: ['#535353', '#aeaeae'],
  DRAFT: ['#535353', '#aeaeae'],
  PENDING: ['#405361', '#a8d9ff'],
  ALLOCATING_PAUSED: ['#66621c', '#fef445'],
  EXPIRED: ['#6e5302', '#ffc107'],
  COMPLETED: ['#6e5302', '#ffc107'],
  PERMANENTLY_ARCHIVED: ['#535353', '#aeaeae'],
  ARCHIVED: ['#535353', '#aeaeae'],
};

const StyledStatus = styled(({ sizeSmall, ...props }) => <Badge {...props} />)`
  background-color: ${({ value }) => get(ColorStatusMap, [value, 1]) || 'black'}!important;
  font-weight: 400;
  text-transform: capitalize;
  color: ${({ value }) => get(ColorStatusMap, [value, 0]) || 'white'};
  ${({ sizeSmall }) => sizeSmall || 'font-size: 1rem'}
`;

const Status = ({
  value, sizeSmall = false, text = value, ...rest
}) => (
  <StyledStatus {...rest} sizeSmall={sizeSmall} value={value.split(' ').map((el) => el.toUpperCase()).join('_')}>
    {text}
  </StyledStatus>
);

export default memo(Status);

export { StyledStatus };
