import React from 'react';
import { PageSection, FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import Button from '@components/buttons';

import CreativeTrigger from './CreativeTrigger';

export default function CreativeTriggers({ triggers }) {
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();

  const triggersFormGroupName = generateName('creativeTriggersAttributes');
  const { fields, append, handleDelete } = useExtendedFieldArray({ name: triggersFormGroupName, keyName: 'uiKey' });

  return (
    <PageSection title="Triggers">
      {fields.filter(({ _destroy }) => !_destroy).length > 0
        && fields.map((trigger, index) => (
          trigger._destroy || (
            // eslint-disable-next-line react/no-array-index-key
            <FormGroup key={`${trigger.uiKey}.${index}`} name={`creativeTriggersAttributes.${index}`}>
              <div className="d-flex mb-1">
                <div className="flex-fill w-100 me-1">
                  <CreativeTrigger triggers={triggers} />
                </div>
                {readOnly || (
                  <div className="flex-fill mt-1">
                    <Button.Delete title="Remove" onClick={() => handleDelete(trigger, index)} />
                  </div>
                )}
              </div>
            </FormGroup>
          )
        ))}
      {!readOnly && <Button.Add title="Add trigger" onClick={() => append({})} />}
    </PageSection>
  );
}
