import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../Logo';

export default function SidebarBrand({ minimized }) {
  return (
    <Link to="/admin">
      <Logo className={`mt-2 ${minimized ? '' : 'px-3'}`} minimized={minimized} />
    </Link>
  );
}
