import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${(props) => props.size}rem;
  color: ${({ color }) => color};
`;

const UploadedPlusIcon = ({ size = 1.2, color = '#2eb85c' }) => (
  <StyledIcon color={color} size={size} className="bi bi-cloud-plus-fill" />
);

export default memo(UploadedPlusIcon);
