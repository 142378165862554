import React from 'react';
import {
  useRouter, updateQuery,
} from '@tripledotstudios/react-core';

import {
  useQuery, useExtendedFieldArray,
} from '@hooks';
import {
  SegmentationRoutes,
  PaymentsRoutes,
  CreativesRoutes,
  LocalisationsRoutes,
} from '@pages/routes';

import { SegmentsControl } from '@components/segmentation';
import ConfigurationVariants from './ConfigurationVariants';

export default function Segments() {
  const router = useRouter();
  const { query } = router;

  const { response: products } = useQuery(
    PaymentsRoutes.CashProducts.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: triggers } = useQuery(
    SegmentationRoutes.Triggers.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: piggyTemplates } = useQuery(
    CreativesRoutes.Templates.indexRequest,
    { ...query, withoutPagination: true, type: 'Creatives::Templates::Piggy' },
  );
  const { response: piggyIconTemplates } = useQuery(
    CreativesRoutes.Templates.indexRequest,
    { ...query, withoutPagination: true, type: 'Creatives::Templates::PiggyIcon' },
  );
  const { response: localisationKeys } = useQuery(
    LocalisationsRoutes.Keys.indexRequest,
    { ...query, deleted: false, withoutPagination: true },
  );

  const formGroupName = 'configurationSegmentsAttributes';
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const handleSegmentDelete = ({ segment, index }) => {
    handleDelete(segment, index);
  };
  const handleSegmentEdit = ({ segmentId }) => {
    updateQuery(router, { configurationSegmentId: segmentId, creativeId: null });
  };
  const handleSegmentAdd = ({ segmentId }) => {
    append({
      segmentId,
      experiments: [],
      variantsAttributes: [{ enabled: true, piggyMinBreakAmount: 0, piggyMaxAmount: 0 }],
    });
  };

  return products && triggers && piggyTemplates && piggyIconTemplates && localisationKeys && (
    <SegmentsControl
      fields={fields}
      formGroupName={formGroupName}
      renderView={() => (
        <ConfigurationVariants
          products={products.items}
          triggers={triggers.items}
          piggyTemplates={piggyTemplates}
          piggyIconTemplates={piggyIconTemplates}
          localisationKeys={localisationKeys.items}
        />
      )}
      onSegmentAdd={handleSegmentAdd}
      onSegmentEdit={handleSegmentEdit}
      onSegmentDelete={handleSegmentDelete}
    />
  );
}
