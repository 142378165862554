import { Tooltip } from '@tripledotstudios/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import BaseTransparentButton from '@components/buttons/BaseTransparentButton';

function MovePositionButton({
  icon,
  title,
  onClick,
  disabled,
}) {
  return (
    <Tooltip placement="bottom" text={title}>
      <BaseTransparentButton
        className="btn rounded"
        onClick={onClick}
        disabled={disabled}
        type="button"
      >
        <FontAwesomeIcon icon={icon} />
      </BaseTransparentButton>
    </Tooltip>
  );
}

export default function PositionControl({
  onUp,
  onDown,
  disableUp,
  disableDown,
}) {
  return (
    <div className="d-flex flex-column align-items-center justify-items-center">
      <MovePositionButton title="Move up" icon={faChevronUp} onClick={onUp} disabled={disableUp} />
      <MovePositionButton title="Move down" icon={faChevronDown} onClick={onDown} disabled={disableDown} />
    </div>
  );
}
