import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useQuery } from '@hooks';
import { useRouter } from '@tripledotstudios/react-core';
import { SegmentationRoutes } from '@pages/routes';
import { LabeledSelect, FloatingLabeledInput, FloatingLabeledSelect } from '@components/resource';

const EMPTY_SEGMENT_OPTION = { label: 'None', value: '' };

const SegmentSelect = ({ watch, floatingWithPriority = false, ...rest }) => {
  const { query } = useRouter();
  const { response: segments } = useQuery(
    SegmentationRoutes.Segments.indexRequest,
    { ...query, withoutPagination: true },
  );

  if (!segments) return '';

  const segmentId = watch && watch('segmentId');

  const segmentOptions = [
    EMPTY_SEGMENT_OPTION,
    ...segments.items.map((segment) => ({ label: segment.name, value: segment.id })),
  ];

  const segment = segments.items.find(({ id }) => +id === +segmentId);

  return (
    floatingWithPriority ? (
      <Row>
        <Col sm={6} xs={12}>
          <FloatingLabeledSelect
            label="Segment"
            name="segmentId"
            options={segmentOptions}
            {...rest}
          />
        </Col>
        {segment && (
          <Col sm={6} xs={12}>
            <FloatingLabeledInput
              label="Priority"
              name="priority"
              readOnly
              value={segment.priority}
              {...rest}
              disabled
            />
          </Col>
        )}
      </Row>
    ) : (
      <LabeledSelect
        label="Segment"
        name="segmentId"
        options={segmentOptions}
        {...rest}
      />
    )
  );
};

export default SegmentSelect;
