import React from 'react';
import { Table } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  PageSection, Tooltip, useRouter, FormGroup, useFormGroup,
} from '@tripledotstudios/react-core';

import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import { Input, Checkbox, LabeledSelect } from '@components/resource';
import { QuestionIcon } from '@components/icons';
import { AppLinksRoutes } from '@pages/routes';
import Button from '@components/buttons';

export default function ConfigurationParameters({ actions }) {
  const { generateName } = useFormGroup();
  const formGroupName = generateName('configurationParametersAttributes');
  const { readOnly } = useFormPermissions();
  const { clearErrors } = useFormContext();
  const { query } = useRouter();

  const actionsOptions = actions.items.map(({ name, id }) => ({ label: name, value: id }));

  const {
    fields, append, handleDelete, replace,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'uiKey',
  });

  const handleActionChange = async (val) => {
    AppLinksRoutes.Actions.editRequest({ ...query, id: val }).then((res) => {
      const parameters = [];

      fields.forEach((data) => data.id && parameters.push({ ...data, _destroy: true }));
      res.data.requiredParametersAttributes.forEach(({ key, value }) => parameters.push({
        key, value, required: true,
      }));

      replace(parameters);
      clearErrors();
    });
  };

  return (
    <>
      <LabeledSelect
        label="Action"
        name="actionId"
        options={actionsOptions}
        sizes={[4, 8]}
        onChange={handleActionChange}
      />
      <PageSection title="Parameters">
        <Table size="sm">
          <thead>
            <tr>
              <th>Key</th>
              <th>Value</th>
              <th width="200px">
                {/* eslint-disable-next-line no-template-curly-in-string */}
                <Tooltip text="Supported dynamic parameters: ${account_id}">
                  <span>
                    Support Dynamic Syntax
                    {' '}
                    <QuestionIcon size="sm" />
                  </span>
                </Tooltip>
              </th>
              {!readOnly && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {fields.map((parameter, index) => parameter._destroy || (
              // eslint-disable-next-line react/no-array-index-key
              <FormGroup key={`${parameter.uiKey}.${index}`} name={`configurationParametersAttributes.${index}`}>
                <tr>
                  <td>
                    <Input name="key" disabled={parameter.required} />
                  </td>
                  <td>
                    <Input name="value" />
                  </td>
                  <td>
                    <Checkbox name="supportDynamicSyntax" />
                  </td>
                  {!readOnly && !parameter.required && (
                    <td>
                      <Button.Delete onClick={() => handleDelete(parameter, index)} title="Remove" />
                    </td>
                  )}
                </tr>
              </FormGroup>
            ))}

            {!readOnly && (
              <tr>
                <td colSpan={4}>
                  <Button.Add size="sm" onClick={() => append({})} />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </PageSection>
    </>
  );
}
