import React from 'react';

import { Form, FormContainer } from '@components';
import { stringify } from '@tripledotstudios/react-core';
import { LabeledInput } from '@components/resource';

import RequiredParameters from './form/RequiredParameters';

export default function ActionsForm({ resource, ...rest }) {
  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch }) => {
        const path = watch('path');
        const appLinkSchema = watch('appLinkSchema');
        const requiredParameters = watch('requiredParametersAttributes');

        const renderPreview = () => {
          const schema = appLinkSchema ? `${appLinkSchema}://` : '';
          const queryParams = requiredParameters.length > 0
            ? `?${stringify(requiredParameters.reduce((memo, obj) => ({ ...memo, [obj.key]: obj.value }), {}))}`
            : '';

          return `${schema}${path || ''}${queryParams}`;
        };

        return (
          <FormContainer>
            <LabeledInput label="Name" name="name" />
            <LabeledInput label="App Link Schema" name="appLinkSchema" disabled />
            <LabeledInput label="Path" name="path" />
            <LabeledInput label="Min supported version" name="minSupportedVersion" />

            <RequiredParameters actionName={resource.actionName} />
            <div>
              <h4>Preview:</h4>
              <pre>
                {renderPreview()}
              </pre>
            </div>
          </FormContainer>
        );
      }}
    </Form.Resource>
  );
}
