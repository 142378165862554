import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { isEmpty } from 'lodash';
import { Form, ImageSquare } from '@components';
import { LabeledInput, Label, Input } from '@components/resource';

const maxFileSize = 1024 * 1024;
const maxFileSizeHuman = '1Mb';
const imageResolution = 1024;

export default function CreativesImagesForm(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const resourceData = props.resource.data;
  const imageUrl = resourceData?.fileUrl;
  const inUse = !isEmpty(resourceData?.inUse);

  const [fileValid, setFileValid] = useState(true);
  const [fileError, setFileError] = useState('');

  const beforeSubmit = (values, setError) => {
    const formData = new FormData();
    formData.append('name', values.name);
    const file = values.file && values.file[0];

    if (!fileValid) {
      setError('file', { message: fileError });
      return null;
    }
    if (file) formData.append('file', file);

    if (values.id) formData.append('id', values.id);
    return formData;
  };

  const handleNewFile = (event, setError, clearErrors) => {
    const handleFileValid = () => {
      setFileValid(true);
      clearErrors('file');
    };
    const handleFileError = (message) => {
      setError('file', { message });
      setFileError(message);
      setFileValid(false);
      /* eslint-disable no-param-reassign */
      event.target.value = null; // to be able to select file with same name twice
    };
    const file = event.target.files[0];

    if (!file) {
      handleFileValid();
    } else {
      setFileValid(false);

      if (file.size > maxFileSize) {
        handleFileError(`Maximum file size is ${maxFileSizeHuman}`);
      } else {
        const img = new Image();
        img.onload = () => {
          if (img.width === imageResolution && img.height === imageResolution) {
            handleFileValid();
          } else {
            handleFileError(`Resolution of the image should be exact ${imageResolution}x${imageResolution}px`);
          }
        };
        img.onerror = () => handleFileError('File does not look like an image');
        img.src = URL.createObjectURL(file);
      }
    }
  };

  return (
    <Row>
      <Col xs={12} md={8}>
        <Form.Resource {...props} beforeSubmit={beforeSubmit}>
          {({ setError, clearErrors }) => (
            <>
              <LabeledInput label="Name" name="name" />

              <Label label="Image" tooltipText={inUse && "Can't be changed while in-use"}>
                <Input
                  type="file"
                  name="file"
                  multiple={false}
                  accept="image/png"
                  disabled={inUse}
                  onChange={(e) => handleNewFile(e, setError, clearErrors)}
                />
              </Label>
            </>
          )}
        </Form.Resource>
      </Col>
      <Col xs={12} md={4}>
        {imageUrl && <ImageSquare size="25rem" src={imageUrl} />}
      </Col>
    </Row>
  );
}
