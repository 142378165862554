import React from 'react';

import { Button, ButtonGroup } from 'react-bootstrap';

import { useRouter, updateQuery } from '@tripledotstudios/react-core';
import { useAppData } from '@hooks';

export default function Filters() {
  const router = useRouter();
  const templateTypes = useAppData().enumOptions['Creatives::TemplateTypes'];
  const filterTypes = [
    { label: 'All', value: '' },
    ...templateTypes,
  ];

  const onFilterButtonClick = (filter) => updateQuery(router, filter);
  const isActiveFilter = (value) => (
    router.query.typeName ? router.query.typeName === value : !value
  );

  return (
    <ButtonGroup>
      {filterTypes.map(({ label, value }) => (
        <Button
          key={value}
          variant={isActiveFilter(value) ? 'secondary' : 'outline-secondary'}
          onClick={() => onFilterButtonClick({ typeName: value })}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
