import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { InUse, Button, ImageSquare } from '@components';

export default function List({
  response, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Image',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { fileUrl } } }) => <ImageSquare src={fileUrl} />,
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },

      InUse.InUseColumn,

      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, fileUrl, permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {permissions.destroy && (
            <Button.DeleteConfirm
              id={id}
              routes={routes}
              onDelete={onDelete}
              confirmBody={(
                <>
                  <div>
                    Product Image &quot;
                    {' '}
                    {name}
                    {' '}
                    &quot; will be deleted and not available for work. Are you sure?
                  </div>
                  <ImageSquare size="150px" src={fileUrl} />
                </>
                )}
            />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No product images yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
