import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@hooks';
import { Badge } from 'react-bootstrap';
import { sortBy, get, intersection } from 'lodash';

import { AssetBundlesRoutes } from '@pages/routes';
import { Input, Checkbox, Select } from '../resource';
import ConfigurationList from './ConfigurationList';

const List = ({ formGroupName, listOptions, ...rest }) => {
  const { watch } = useFormContext();
  const sourceList = listOptions || watch(`${formGroupName}.listOptions`);

  const selectOptions = sourceList?.map(({ key, label, ...restOptions }) => (
    { label: (label || key), value: key, ...restOptions }),
  ) || [];

  return <Select options={selectOptions} {...rest} />;
};

const AssetBundlesList = ({
  formGroupName, disabled: originalDisabled, allowedAssetBundlesTypes, ...props
}) => {
  const { formState: { defaultValues } } = useFormContext();
  const { response } = useQuery(AssetBundlesRoutes.indexRequest, { withoutPagination: true });
  const initialValue = get(defaultValues, `${formGroupName}.${props.name}`);

  if (!response?.items) {
    return null;
  }
  const filteredItems = response.items.filter(({ id, deleted, types }) => (
    (+initialValue === +id)
      || ((!deleted || +initialValue === +id)
      && (!allowedAssetBundlesTypes || intersection(types, allowedAssetBundlesTypes).length > 0))
  ));

  const listOptions = [
    { label: 'None', value: 0, info: '' },
    ...sortBy(filteredItems, 'deleted').map(({
      id, uuid, deleted, info,
    }) => ({
      key: id, label: uuid, deleted, isDisabled: deleted, info,
    })),
  ];

  const labelFormat = ({ label, deleted, info }) => (
    <>
      <div className="mt-n1">{label}</div>
      <div className="fss-3 mt-n05">
        {info.split(',').map((item) => (
          <Badge key={item} className="fss-3 fw-normal me-1 px-15 py-05" bg="info">{item}</Badge>
        ))}
        {deleted && <Badge className="fss-3 fw-normal px-15 py-05" bg="secondary">deleted</Badge>}
      </div>
    </>
  );

  const intiallyDisabledDeleted = response.items.find((item) => +item.id === +initialValue)?.deleted;
  const disabled = intiallyDisabledDeleted ? false : originalDisabled;

  return (
    <List
      defaultValue={0}
      {...props}
      disabled={disabled}
      formatOptionLabel={labelFormat}
      listOptions={listOptions}
    />
  );
};

const Inputs = {
  boolean: {
    input: ({
      formGroupName, listOptions, allowedAssetBundlesTypes, ...props
    }) => <Checkbox {...props} />,
    config: () => null,
  },
  string: {
    input: ({
      formGroupName, listOptions, allowedAssetBundlesTypes, ...props
    }) => <Input {...props} />,
    config: () => null,
  },
  integer: {
    input: ({
      formGroupName, listOptions, allowedAssetBundlesTypes, ...props
    }) => <Input type="number" {...props} />,
    config: () => null,
  },
  float: {
    input: ({
      formGroupName, listOptions, allowedAssetBundlesTypes, ...props
    }) => <Input type="number" step="0.01" {...props} />,
    config: () => null,
  },
  list: {
    input: ({ allowedAssetBundlesTypes, ...props }) => <List {...props} />,
    config: (props) => <ConfigurationList {...props} />,
  },
  list_multi: {
    input: ({ allowedAssetBundlesTypes, ...props }) => <List isMulti {...props} />,
    config: (props) => <ConfigurationList {...props} />,
  },
  asset_bundle: {
    input: (props) => <AssetBundlesList {...props} />,
    config: () => null,
    defaultValue: 0,
  },
};

const Types = Object.keys(Inputs);
const TypesWithConfig = Types.filter((type) => Inputs[type].config());

export default Inputs;
export { Types, TypesWithConfig };
