import React, { memo, useState } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { Table, Accordion } from 'react-bootstrap';
import styled from 'styled-components';
import { each } from 'lodash';
import { useFormContext } from 'react-hook-form';
import Button from '@components/buttons';
import { useFormPermissions } from '@hooks';

import EntitiesLinks from './EntitiesLinks';

const Container = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  .accordion-body {
    padding: 0;
  }
  .accordion-header {
    overflow-y: hidden;

    .accordion-button {
      padding: 0.5rem;
    }
  }
  .table {
    margin-bottom: 0;
  }
  .actions-header {
    margin-right: 2rem;
    transition-duration: 300ms;
    transform: translateY(-4rem);

    &.true {
      transform: translateY(0);
    }
  }
`;

const ExperimentEntities = ({ variants }) => {
  const { query: { applicationId } } = useRouter();
  const { setValue, watch } = useFormContext();
  const [opened, setOpened] = useState(false);
  const handleAccordionClick = () => setOpened(!opened);
  const { readOnly } = useFormPermissions();

  const removeEntity = (experimentEntity) => {
    each(variants, (variant, index) => {
      each(variant.experimentEntitiesAttributes, (entity, entityIndex) => {
        if (entity.entityType === experimentEntity.entityType && entity.entityId === experimentEntity.entityId) {
          const name = `variantsAttributes.${index}.experimentEntitiesAttributes.${entityIndex}._destroy`;
          setValue(name, true, { shouldDirty: true });
        }
      });
    });
  };

  if (!variants[0].experimentEntitiesAttributes || variants[0].experimentEntitiesAttributes.length === 0) {
    return null;
  }

  const firstVariant = watch('variantsAttributes.0');

  return (
    <Container>
      <Accordion activeKey={opened ? ['1'] : []}>
        <Accordion.Item eventKey="1">
          <Accordion.Header onClick={handleAccordionClick}>
            <div style={{ width: '100%' }} className="d-flex justify-content-between">
              <div>
                Entities in Use
              </div>
              <div className={`actions-header ${opened}`}>
                Actions
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Table className="table-bordered">
              <tbody>
                {firstVariant.experimentEntitiesAttributes.map((experimentEntity) => (
                  !experimentEntity._destroy && (
                    <tr key={experimentEntity.id}>
                      <td>{EntitiesLinks[experimentEntity.entityType](experimentEntity, null, applicationId)}</td>
                      <td width="105px">
                        {!readOnly && <Button.Delete title="Remove" onClick={() => removeEntity(experimentEntity)} />}
                      </td>
                    </tr>
                  )
                ))}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
};

export default memo(ExperimentEntities);
