import React, { useMemo } from 'react';
import { Table, Status, EmptyList } from '@components/collection';
import { InUse } from '@components';
import Button from '@components/buttons';
import { hasApplicationPermissions } from '@services/permissions';

export default function List({
  response, onEditButtonClick, onDelete, routes, onDuplicateButtonClick, selectedResourceId,
}) {
  const canCreate = hasApplicationPermissions(['payments', 'catalog', 'write']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Segment',
        accessor: 'segment.name',
      },
      {
        Header: 'Priority',
        accessor: 'segment.priority',
      },
      {
        Header: 'Status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { status } } }) => <Status value={status} />,
      },

      InUse.AvailabilityStateColumn,

      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, isDefault, permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {canCreate && <Button.Duplicate className="me-2" onClick={() => onDuplicateButtonClick({ id, name })} />}
            {permissions.destroy && !isDefault && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Catalog "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No catalogs yet." />;
  }

  return response && (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
