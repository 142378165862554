import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { DeleteIcon } from '../icons';

const StyledButton = styled(Button)`
  color: white;
  svg path {
    fill: white;
  }
  &:hover, &:focus {
    color: white;
  }
`;

const DeleteButtonText = ({
  title = 'Delete',
  icon = <DeleteIcon />,
  ...props
}) => (
  <StyledButton variant="danger" {...props}>
    {icon}
    {' '}
    {title}
  </StyledButton>
);

export default DeleteButtonText;
