import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@hooks';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import {
  Pagination,
  PageHeader,
  Button,
  Pane,
} from '@components';
import { Table } from '@components/collection';
import { PaymentsRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';

const Routes = PaymentsRoutes.StoreProducts;

export default function Index() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, query);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Store ID',
        accessor: 'storeId',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Deleted',
        Cell: ({ row: { original: { deleted } } }) => deleted && 'yes',
      },
      {
        Header: 'Synchronized at (UTC)',
        accessor: 'createdAt',
      },
    ],
    [],
  );

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeStoreProducts = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    response && (
      <>
        <PageHeader title="Store Products">
          <Button.Synchronize onClick={handleSynchronizeStoreProducts} />
        </PageHeader>
        <Pane>
          <Table columns={columns} data={response.items} />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </Pane>
      </>
    )
  );
}
