import {
  every, findIndex, isArray, isEqual, isNull, isObject, forEach, omitBy,
} from 'lodash';

const findDiffIndex = (array, element, key) => (element.Id ? findIndex(array, (e) => element.Id === e.Id) : key);
export const findDiffObject = (source, element, key) => {
  if (!source) return undefined;

  if (isArray(source) && element) {
    const arrayIndex = findDiffIndex(source, element, key);

    if (arrayIndex !== -1) {
      return source[arrayIndex];
    }

    return undefined;
  }

  return source[key];
};

const isPlainValue = (value) => !isObject(value) && !isArray(value);

const isValue = (value) => {
  if (isArray(value)) {
    return every(value, isPlainValue);
  }
  return isPlainValue(value);
};
const compareValues = (value1, value2) => !isEqual(value1, value2);

const clearValue = isNull;

const validArrayElement = (element) => {
  if (isObject(element)) return !element._destroy;
  return true;
};

const buildDiff = (current, base, options = {
  compareValues, clearValue, isValue, validArrayElement,
}) => {
  const clearBaseValue = isArray(base) ? base.filter((e) => validArrayElement(e)) : base;
  if (options.isValue(current) || options.isValue(clearBaseValue)) {
    return options.compareValues(current, clearBaseValue);
  }

  const diff = {};

  let realIndex = -1;
  forEach(current, (currentValue, key) => {
    if (isArray(current) && isObject(currentValue)) {
      if (validArrayElement(currentValue)) {
        realIndex += 1;
      } else {
        diff[key] = true;
        return;
      }
    } else {
      realIndex = key;
    }
    diff[key] = buildDiff(currentValue, findDiffObject(clearBaseValue, currentValue, realIndex), options);
  });

  forEach(clearBaseValue, (baseValue, key) => {
    if (diff[key] !== undefined) {
      return;
    }
    diff[key] = buildDiff(undefined, baseValue, options);
  });

  return omitBy(diff, options.clearValue);
};

export default buildDiff;
