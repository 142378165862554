import React from 'react';
import { isEmpty } from 'lodash';

import { Form, FormContainer, InUse } from '@components';
import { LabeledInput, LabeledSelect } from '@components/resource';
import { useLocalisationKeysOptions } from '@pages/localisations/keys/queries';
import { useCurrentApplication, useAppData } from '@hooks';

export default function PresetsForm(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const inUse = !isEmpty(props.resource.data?.inUse);
  const currentApplication = useCurrentApplication();
  const keysQuery = useLocalisationKeysOptions({ applicationId: currentApplication.id });
  const presetTypes = useAppData().enumOptions['Payments::PresetTypes'];
  const isSomeLoading = [keysQuery].some(({ isLoading }) => isLoading);

  if (isSomeLoading) return null;

  const disclaimerTextOptions = [{ label: 'None', value: 0 }, ...keysQuery.data];

  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />
        <LabeledSelect label="Type" name="type" disabled={inUse} options={presetTypes} />
        <LabeledSelect
          label="Disclaimer text"
          name="disclaimerTextId"
          options={disclaimerTextOptions}
          disabled={inUse}
          formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
        />
      </FormContainer>
    </Form.Resource>
  );
}
