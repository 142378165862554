import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { PaymentsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'payments/v2/store_products';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function storeProductsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await PaymentsRoutes.V2.StoreProducts.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useStoreProductsOptions(query = {}) {
  return useQuery(storeProductsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(sortBy(data.items, ({ price }) => parseFloat(price)), { labelKey: 'storeId' }),
  }));
}

export function useStoreProducts(query = {}) {
  return useQuery(storeProductsQuery({ params: { ...query, includeInUse: false, withoutPagination: true } }));
}

