import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

import { PageSection, useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { AccountsRoutes } from '@pages/routes';
import { Form, Button } from '@components';
import { InputWithAddon } from '@components/resource';

import InUse from '@components/in_use';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';

const Routes = AccountsRoutes.UserPurchases;

const PurchaseCountForm = ({ id, purchaseCount, refetch }) => {
  const { query } = useRouter();
  const canWrite = hasApplicationPermissions(['player', 'write']);

  const requestParams = {
    applicationId: query.applicationId,
    userId: query.id,
    id,
  };

  return (
    <Form defaultValues={{ purchaseCount }} enableReinitialize>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({
          actionName: 'update',
          setError,
          onSuccess: refetch,
        });
        const onSubmit = (values) => Routes.updateRequest({ ...requestParams, ...values }).then(responseHandler);

        return (
          <Form.Component onSubmit={onSubmit}>
            <InputWithAddon
              readOnly={!canWrite}
              name="purchaseCount"
              after={canWrite && <Button.Submit />}
              type="number"
            />
          </Form.Component>
        );
      }}
    </Form>
  );
};

export default function PaymentsView() {
  const { query } = useRouter();
  const requestParams = { userId: query.id, applicationId: query.applicationId };
  const { response, refetch } = useQuery(Routes.indexRequest, requestParams);
  const userPurchases = response?.items;

  return userPurchases ? (
    <>
      <PageSection title="Purchase limits" />

      <Row>
        <Col md={10} xs={12}>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>Catalog Product ID</th>
                <th>Purchase Limit</th>
                <th>Purchase Count</th>
                <th>Last Purchased At</th>
              </tr>
            </thead>
            <tbody>
              {userPurchases.map((purchase) => (
                <tr key={purchase.id}>
                  <td>
                    {purchase.catalogProductId}
                    {purchase.catalogId && (
                      <>
                      &nbsp;(
                        {InUse.Links().paymentsCatalogs({
                          id: purchase.catalogId,
                          name: purchase.catalogName,
                          applicationId: query.applicationId,
                        })}
                        )
                      </>
                    )}
                  </td>
                  <td>{purchase.purchaseLimit}</td>
                  <td><PurchaseCountForm {...purchase} refetch={refetch} /></td>
                  <td>{purchase.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  ) : '';
}
