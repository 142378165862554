import React from 'react';
import { useRouter, useConfirm } from '@tripledotstudios/react-core';
import { buildDeleteResourceResponseHandler } from '@services/response_handler';

import DeleteButton from './DeleteButton';

const DeleteButtonConfirm = ({
  title = 'Delete', recordName, confirmBody, id, routes, onDelete, ...props
}) => {
  const router = useRouter();
  const confirm = useConfirm();

  const responseHandler = buildDeleteResourceResponseHandler({ onSuccess: onDelete });

  const handleDeleteClick = () => {
    confirm.showConfirmation({
      title: 'Confirm to Delete',
      body: confirmBody || `${recordName} will be deleted and not available for work. Are you sure?`,
    }).then(() => routes.deleteRequest({ ...router.query, id }).then(responseHandler));
  };

  return (
    <DeleteButton title={title} onClick={handleDeleteClick} {...props} />
  );
};

export default DeleteButtonConfirm;
