import React from 'react';
import { Container, Col } from 'react-bootstrap';

import { useAppData } from '@hooks';
import { Form, InUse } from '@components';
import { FloatingLabeledInput, FloatingLabeledSelect } from '@components/resource';

import ProductsTabs from './form/ProductsTabs';
import SegmentSelect from './form/SegmentSelect';

export default function CatalogsForm({ resource, ...rest }) {
  const configurationStatuses = useAppData().enumOptions['Payments::CatalogStatuses'];

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ watch }) => (
        <Container fluid className="form-container">
          <Form.ControlsLayout>
            <FloatingLabeledInput label="Name" name="name" />
            <FloatingLabeledInput label="Description" name="description" />
            <FloatingLabeledSelect label="Status" name="status" options={configurationStatuses} />
            <InUse.AvailabilityStateFormGroup floating />
            <Col xxl={6} lg={8} sm={12} xs={12}>
              <SegmentSelect
                watch={watch}
                disabled={resource.actionName === 'update'}
                floatingWithPriority
              />
            </Col>
          </Form.ControlsLayout>
          <ProductsTabs defaultValues={resource.data} />
        </Container>
      )}
    </Form.Resource>
  );
}
