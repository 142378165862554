import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { useRouter } from '@tripledotstudios/react-core';
import { ApplicationsRoutes } from '@pages/routes';

const StyledSelectContainer = styled.div`
  select {
    background-color: ${({ theme }) => theme.components.sidebar.background};
    background-image: var(--cui-sidebar-nav-group-indicator, url("data:image/svg+xml,%3csvg
      xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.6%29'%3e%3cpath
      fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1
      .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"));

    border-color: ${({ theme }) => theme.components.sidebar.separator};
    color: ${({ theme }) => theme.components.sidebar.title};
    font-size: 1rem;

    &:focus {
      border-color: inherit;
      box-shadow: none;
    }
  }
`;

export default function ApplicationSelect({ applications, applicationId }) {
  const router = useRouter();

  const handleApplicationChange = ({ target }) => (
    router.push(ApplicationsRoutes.homePath({ applicationId: target.value }))
  );

  return (
    <StyledSelectContainer>
      <Form.Select
        size="sm"
        value={applicationId}
        onChange={handleApplicationChange}
      >
        {applicationId || <option value="">Select application</option>}
        {applications && applications.map((app) => (
          <option key={app.value} value={app.value}>{app.label}</option>
        ))}
      </Form.Select>
    </StyledSelectContainer>
  );
}
