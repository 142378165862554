import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useRouter, useFormGroup } from '@tripledotstudios/react-core';

import { useQuery } from '@hooks';
import { ExperimentTabs } from '@components/ab_testing';
import { ABTestingRoutes, PiggyBanksRoutes } from '@pages/routes';

import ConfigurationVariant from './ConfigurationVariant';

export default function ConfigurationVariants({ ...props }) {
  const { query } = useRouter();
  const { getValues } = useFormContext();
  const { generateName } = useFormGroup();

  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );

  const rootPath = generateName('').slice(0, -1);
  const configurationSegmentAttributes = getValues(rootPath);
  const { id: configurationId } = getValues();

  const reloadEntityRequest = () => PiggyBanksRoutes.Configurations.editRequest({ id: configurationId, ...query });

  return experiments?.items ? (
    <ExperimentTabs
      entityId={configurationSegmentAttributes.id}
      entityType="PiggyBanks::ConfigurationSegment"
      experiments={experiments.items}
      reloadEntityRequest={reloadEntityRequest}
      renderView={({ variantAttributes, defaultVariant }) => (
        <ConfigurationVariant
          {...props}
          defaultVariantAttributes={!variantAttributes.isDefaultVariant && defaultVariant}
          currentAttributes={variantAttributes}
        />
      )}
    />
  ) : null;
}
