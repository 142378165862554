import React from 'react';
import { Filters } from '@tripledotstudios/react-core/table';

import { useAppData } from '@hooks';

export default function AvailabilityStateFilter() {
  const { enumOptions } = useAppData();
  const availabilityStateOptions = enumOptions.AvailabilityStates;
  return <Filters.Select label="Availability" name="availabilityStateIn" options={availabilityStateOptions} isMulti />;
}
