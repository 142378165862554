import React from 'react';
import { Route } from 'react-router-dom';

import { ABTestingRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';

const Routes = ABTestingRoutes.Experiments;

export default function ExperimentsRoot() {
  return [Routes.indexRawPath, Routes.editRawPath, Routes.newRawPath].map((path) => (
    <Route
      exact
      key={path}
      path={path}
      element={(
        <>
          <PageTitle text="A/B Experiments" />
          <Index />
        </>
      )}
    />
  ));
}
