import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@tripledotstudios/react-core';

import { DeleteIcon } from '../icons';
import BaseTransparentButton from './BaseTransparentButton';

const StyledButton = styled(BaseTransparentButton)`
  ${({ disabled }) => !disabled && `
    &:hover, &:focus {
      svg path {
        fill: #dc3545;
      }
    }
  `}
`;

const DeleteButton = ({
  title = 'Delete',
  textPlacement = 'bottom',
  icon = <DeleteIcon />,
  onClick,
  ...props
}) => (
  <Tooltip placement={textPlacement} text={title}>
    <StyledButton onClick={onClick} {...props}>
      {icon}
    </StyledButton>
  </Tooltip>
);

DeleteButton.Color = '#dc3545';

export default DeleteButton;
