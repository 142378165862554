import React, { useMemo, memo } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormGroup } from '@tripledotstudios/react-core';

import { Form } from '@components';
import { FloatingLabeledSelect } from '@components/resource';

import { labelFormat } from './SegmentsSelect';

const SegmentTypeOptions = [{ label: 'All Players', value: 'true' }, { label: 'Specific segments', value: 'false' }];

const SegmentConfigurationSelect = ({ segments = [], tooltipText }) => {
  const { watch } = useFormContext();

  const options = useMemo(() => (segments.items.map(({
    name,
    id,
    info,
  }) => ({
    label: name,
    value: id,
    info,
  }))
  ), [segments]);

  const showSegments = !watch('segmentConfiguration.all') || watch('segmentConfiguration.all') === 'false';

  return (
    <FormGroup name="segmentConfiguration">
      <Form.ControlsLayout>
        <Col>
          <FloatingLabeledSelect
            label="Segment type"
            name="all"
            options={SegmentTypeOptions}
          />
        </Col>
        {showSegments && (
          <Col sm={12} md={12} lg={8} xl={8} xxl={9}>
            <FloatingLabeledSelect
              label="Segments"
              options={options}
              formatOptionLabel={labelFormat}
              tooltipText={tooltipText}
              name="ids"
              isMulti
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
            />
          </Col>
        )}
      </Form.ControlsLayout>
    </FormGroup>
  );
};

export default memo(SegmentConfigurationSelect);
