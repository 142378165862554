import React from 'react';
import { ButtonToolbar, useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { Badge } from 'react-bootstrap';

import { Pagination, PageHeader, Button } from '@components';
import { CreativesRoutes } from '@pages/routes';
import { hasApplicationPermissions } from '@services/permissions';

import TemplateCards from './TemplateCards';
import Filters from './Filters';

const Routes = CreativesRoutes.Templates;

export default function Index() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, query, [query]);
  const canCreate = hasApplicationPermissions(['creatives', 'template', 'write']);

  return (
    response && (
      <>
        <PageHeader title="Creative Templates">
          <ButtonToolbar>
            <Filters />
            {canCreate && <Button.AddLink title="Upload" to={Routes.newPath(query)} />}
          </ButtonToolbar>
        </PageHeader>
        <TemplateCards
          items={response.items}
          // eslint-disable-next-line react/no-unstable-nested-components
          itemFooter={(item) => (
            <div className="d-flex">
              <div className="me-auto">
                {item.permissions.edit && (
                  <Button.EditLink className="me-2" id={item.id} routes={Routes} title="Show" />
                )}
                {item.permissions.destroy && (
                  <Button.DeleteConfirm
                    id={item.id}
                    routes={Routes}
                    onDelete={refetch}
                    recordName={`Creative Template "${item.internalName}"`}
                  />
                )}
              </div>
              <Badge className="align-self-end" bg="info">{item.typeName}</Badge>
            </div>
          )}
        />
        <Pagination
          pageCount={response._meta.pages}
          refetch={refetch}
        />
      </>
    )
  );
}
