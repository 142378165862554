import { matchPath } from 'react-router-dom';
import {
  faUsers,
  faFlaskVial,
  faPiggyBank,
  faFilter,
  faCalendarDay,
  faShoppingCart,
  faTools,
  faPalette,
  faLink,
  faRocket,
  faShieldHalved,
  faHandshake,
  faBolt,
  faGlobe,
  faCalendar,
  faList,
  faMobile,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import styled, { useTheme } from 'styled-components';

import { useAppData, useCurrentApplication } from '@hooks';
import {
  ApplicationsRoutes,
  AccountsRoutes,
  SegmentationRoutes,
  PaymentsRoutes,
  CampaignsRoutes,
  PiggyBanksRoutes,
  ABTestingRoutes,
  CreativesRoutes,
  AppLinksRoutes,
  LocalisationsRoutes,
  EventsRoutes,
  AssetBundlesRoutes,
  ApplicationActivityLogRoutes,
  ActivityLogRoutes,
  DebugRoutes,
  TesterDevicesRoutes,
} from '@pages/routes';

import Sidebar from './sidebar/index';

const StyledBottomSection = styled(Sidebar.BottomSection)`
  margin-bottom: 2.5rem !important;
`;

const AppNavigation = ({ theme, currentApplication, minimized }) => {
  const applicationId = currentApplication.id;
  const productTypes = useAppData().enums.ApplicationProductsTypes;

  return (
    <>
      <Sidebar.AuthorizedSectionItem
        icon={faUsers}
        title="Players"
        color={theme.red}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Search"
          to={AccountsRoutes.Users.indexPath({ applicationId })}
          permissionsPath="player.read"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faFilter}
        title="Segmentation"
        color={theme.cornflowerBlue}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Segments"
          to={SegmentationRoutes.Segments.indexPath({ applicationId })}
          permissionsPath="segmentation.segment.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Triggers"
          to={SegmentationRoutes.Triggers.indexPath({ applicationId })}
          permissionsPath="segmentation.trigger.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faShoppingCart}
        title="Payments"
        color={theme.lightGreen}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Catalogs"
          to={PaymentsRoutes.Catalogs.indexPath({ applicationId })}
          permissionsPath="payments.catalog.list"
        />

        <Sidebar.AuthorizedLinkItem
          title="Game Products"
          showIf={currentApplication.productsType === productTypes.GAME_PRODUCTS}
          to={currentApplication.configurableProducts
            ? PaymentsRoutes.V2.GameProducts.indexPath({ applicationId })
            : PaymentsRoutes.GameProducts.indexPath({ applicationId })}
          permissionsPath="payments.game_product.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Store Products"
          showIf={currentApplication.productsType === productTypes.GAME_PRODUCTS}
          to={currentApplication.configurableProducts
            ? PaymentsRoutes.V2.StoreProducts.indexPath({ applicationId })
            : PaymentsRoutes.StoreProducts.indexPath({ applicationId })}
          permissionsPath="payments.store_product.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Cash Products"
          showIf={currentApplication.productsType === productTypes.CASH_PRODUCTS}
          to={PaymentsRoutes.CashProducts.indexPath({ applicationId })}
          permissionsPath="payments.cash_product.list"
        />

        <Sidebar.AuthorizedLinkItem
          title="Sections"
          to={PaymentsRoutes.Sections.indexPath({ applicationId })}
          permissionsPath="payments.section.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Presets"
          to={PaymentsRoutes.Presets.indexPath({ applicationId })}
          permissionsPath="payments.preset.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Product Labels"
          to={PaymentsRoutes.ProductLabels.indexPath({ applicationId })}
          permissionsPath="payments.product_label.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Product Images"
          to={PaymentsRoutes.ProductImages.indexPath({ applicationId })}
          permissionsPath="payments.product_image.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Game Items"
          to={PaymentsRoutes.GameItems.indexPath({ applicationId })}
          permissionsPath="payments.game_item.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faCalendarDay}
        title="Campaigns"
        color={theme.tulipTree}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Configurations"
          to={CampaignsRoutes.Configurations.indexPath({ applicationId })}
          permissionsPath="campaigns.configuration.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Sequential Offers"
          to={CampaignsRoutes.SequentialOffers.indexPath({ applicationId })}
          permissionsPath="campaigns.sequential_offer.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faFlaskVial}
        title="A/B Testing"
        color={theme.purple}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Experiments"
          to={ABTestingRoutes.Experiments.indexPath({ applicationId })}
          permissionsPath="ab_testing.experiment.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faPiggyBank}
        title="Piggy Banks"
        color={theme.pink}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Configurations"
          to={PiggyBanksRoutes.Configurations.indexPath({ applicationId })}
          permissionsPath="piggy_banks.configuration.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faPalette}
        title="Creative Library"
        color={theme.orange}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Templates"
          to={CreativesRoutes.Templates.indexPath({ applicationId })}
          permissionsPath="creatives.template.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Images"
          to={CreativesRoutes.Images.indexPath({ applicationId })}
          permissionsPath="creatives.image.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem icon={faLink} title="App links" color={theme.conifer} minimized={minimized}>
        <Sidebar.AuthorizedLinkItem
          title="Configurations"
          to={AppLinksRoutes.Configurations.indexPath({ applicationId })}
          permissionsPath="app_links.configuration.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Actions"
          to={AppLinksRoutes.Actions.indexPath({ applicationId })}
          permissionsPath="app_links.action.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedSectionItem
        icon={faGlobe}
        title="Localisations"
        color={theme.tulipTree}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Keys"
          to={LocalisationsRoutes.Keys.indexPath({ applicationId })}
          permissionsPath="localisations.key.list"
        />
      </Sidebar.AuthorizedSectionItem>

      <Sidebar.AuthorizedLinkItem
        title="Asset Bundles"
        icon={faImage}
        minimized={minimized}
        to={AssetBundlesRoutes.indexPath({ applicationId })}
        permissionsPath="asset_bundle.list"
      />

      <Sidebar.AuthorizedSectionItem
        icon={faCalendar}
        title="Events"
        color={theme.lightPurple}
        minimized={minimized}
      >
        <Sidebar.AuthorizedLinkItem
          title="Configurations"
          to={EventsRoutes.Configurations.indexPath({ applicationId })}
          permissionsPath="events.configuration.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Objectives"
          to={EventsRoutes.ObjectiveConfigurations.indexPath({ applicationId })}
          permissionsPath="events.objective_configuration.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Objective Templates"
          to={EventsRoutes.ObjectiveTemplates.indexPath({ applicationId })}
          permissionsPath="events.objective_template.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Types"
          to={EventsRoutes.Types.indexPath({ applicationId })}
          permissionsPath="events.type.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Activity Sets"
          to={EventsRoutes.ActivitySets.indexPath({ applicationId })}
          permissionsPath="events.activity_set.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Parameter Sets"
          to={EventsRoutes.ParameterSets.indexPath({ applicationId })}
          permissionsPath="events.parameter_set.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Collection Sets"
          to={EventsRoutes.CollectionSets.indexPath({ applicationId })}
          permissionsPath="events.collection_set.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Gacha Chests"
          to={EventsRoutes.GachaChests.indexPath({ applicationId })}
          permissionsPath="events.gacha_chest.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Drop Probabilities"
          to={EventsRoutes.DropProbabilities.indexPath({ applicationId })}
          permissionsPath="events.drop_probability.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Races"
          to={EventsRoutes.Races.indexPath({ applicationId })}
          permissionsPath="events.race.list"
        />
        <Sidebar.AuthorizedLinkItem
          title="Client Leaderboards"
          to={EventsRoutes.ClientLeaderboards.indexPath({ applicationId })}
          permissionsPath="events.client_leaderboard.list"
        />
      </Sidebar.AuthorizedSectionItem>
      <Sidebar.AuthorizedLinkItem
        title="Activity Log"
        icon={faList}
        to={ApplicationActivityLogRoutes.indexPath({ applicationId })}
        permissionsPath="activity_log.read"
        minimized={minimized}
      />
    </>
  );
};

export default function LiveOpsSidebar() {
  const theme = useTheme();

  const currentApplication = useCurrentApplication();
  const applicationId = currentApplication?.id || '';

  const { featureToggles, applications } = useAppData();
  const applicationsOptions = applications.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Sidebar variant="primary">
      {({ minimized }) => (
        <>
          <Sidebar.Brand minimized={minimized} />

          <Sidebar.Separator />
          <Sidebar.ApplicationSelect applications={applicationsOptions} applicationId={applicationId} />
          <Sidebar.Separator />

          <Sidebar.OverflowSection>
            {applicationId && (
              <AppNavigation
                currentApplication={currentApplication}
                featureToggles={featureToggles}
                theme={theme}
                minimized={minimized}
              />
            )}
          </Sidebar.OverflowSection>

          <StyledBottomSection>
            <Sidebar.AuthorizedSectionItem
              icon={faTools}
              title="Tools"
              minimized={minimized}
              autoCollapse
              matchPathProps={{ end: true }}
            >
              <Sidebar.AuthorizedLinkItem
                icon={faRocket}
                title="Applications"
                to={`${ApplicationsRoutes.indexPath()}?`} // ? is a workaround for the code that detects active sections
                applicationScoped={false}
                permissionsPath="application.read"
                isActive={(_, location) => (
                  [
                    ApplicationsRoutes.indexRawPath,
                    ApplicationsRoutes.newRawPath,
                    ApplicationsRoutes.editRawPath,
                  ].some((path) => matchPath(path, location.pathname))
                )}
              />
              <Sidebar.AuthorizedLinkItem
                title="Activity Log"
                icon={faList}
                to={ActivityLogRoutes.indexPath()}
                applicationScoped={false}
                permissionsPath="activity_log.read"
              />
              <Sidebar.AuthorizedLinkItem
                icon={faMobile}
                title="Tester Devices"
                to={TesterDevicesRoutes.indexPath()}
                applicationScoped={false}
                permissionsPath="tester_device.list"
              />

              <Sidebar.LinkItem icon={faShieldHalved} title="JWT Generator" to={DebugRoutes.generateJwtPath()} />
              <Sidebar.LinkItem icon={faHandshake} title="API Documentation" to="/admin/api_documentation" />
              <Sidebar.LinkItem icon={faBolt} title="UI Kit" to="/admin/ui-kit" />
            </Sidebar.AuthorizedSectionItem>

            <Sidebar.UserSectionItem minimized={minimized} />
          </StyledBottomSection>
        </>
      )}
    </Sidebar>
  );
}
