import React, {
  useCallback, useState, useRef,
} from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import {
  useQuery, useAppData, useCrudlRequests,
} from '@hooks';
import getMap from '@services/getMap';
import {
  DuplicationModal, PageHeader, SplitPane, Button,
} from '@components';
import { LabeledInput } from '@components/resource';
import { ABTestingRoutes } from '@pages/routes';
import { hasApplicationPermissions } from '@services/permissions';

import List from './List';
import Form from './form/Form';
import VariantModal from './form/VariantModal';
import ButtonsFooter from './ButtonsFooter';

const Routes = ABTestingRoutes.Experiments;

const Experiments = () => {
  const statuses = useAppData().enumOptions['AbTesting::ExperimentStatuses'];
  const audiences = useAppData().enumOptions['AbTesting::AudienceTypes'];

  useRequireAuth();

  const router = useRouter();
  const { query } = router;
  const [resource, setResource] = useState(false);
  const [duplicatedResource, setDuplicatedResource] = useState(null);
  const [variant, setVariant] = useState(null);
  const formRef = useRef(null);
  const canCreate = hasApplicationPermissions(['ab_testing', 'experiment', 'write']);

  const { response, refetch } = useQuery(Routes.indexRequest, { ...query, withoutPagination: true });

  const {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  const handleDuplicateButtonClick = useCallback(({ id, name, priority }) => {
    setDuplicatedResource({ id, name: `${name}_copy`, priority: priority + 1 });
  }, []);

  const handleAddVariant = useCallback(() => {
    setVariant({ name: '', weight: '' });
  }, []);

  return (
    <>
      <PageHeader title="A/B Experiments">
        {canCreate && <Button.Add title="Add Experiment" onClick={handleNew} />}
      </PageHeader>
      <SplitPane paneName="experiments">
        <SplitPane.Top>
          <List
            response={response}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDuplicateButtonClick={handleDuplicateButtonClick}
            onDelete={handleDelete}
            routes={Routes}
            audiences={getMap(audiences)}
            statuses={getMap(statuses)}
          />
          <DuplicationModal
            record={duplicatedResource}
            handleClose={() => setDuplicatedResource(null)}
            submitRequest={(values) => Routes.duplicateRequest({ ...query, ...values })}
            onSuccess={({ data }) => { refetch(); setDuplicatedResource(null); handleEdit(data.id); }}
          >
            <LabeledInput label="Name" name="name" />
            <LabeledInput label="Priority" name="priority" type="number" />
          </DuplicationModal>
          {formRef?.current && (
            <VariantModal
              variant={variant}
              handleClose={() => setVariant(null)}
              formRef={formRef}
            />
          )}
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && (
            <Form
              formRef={formRef}
              resource={resource}
              onSuccess={handleSave}
              audiences={audiences}
              statuses={statuses}
              onDiscard={handleDiscard}
              onCreate={handleEdit}
              onAddVariant={handleAddVariant}
            />
          )}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            defaultValues={resource.data}
            formRef={formRef}
            onCancel={handleOnCancel}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
};

export default Experiments;
