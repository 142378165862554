import React from 'react';
import styled from 'styled-components';
import { ButtonToolbar, useRouter } from '@tripledotstudios/react-core';
import Button from '../buttons';

const StyledContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.bootstrap.hr};
  background-color: ${({ theme }) => theme.bootstrap.background};

  padding-top: 0.5rem;
  margin-bottom: -0.4rem;
`;

const Cancel = ({
  hide, asLink, routes, ...props
}) => {
  if (hide) return null;

  if (asLink) {
    const { query } = useRouter();
    return <Button.CancelLink to={routes.indexPath(query)} {...props} />;
  }
  return <Button.Cancel {...props} />;
};

const Submit = ({ hide, ...props }) => !hide && <Button.Submit form="react-hook-form" {...props} />;

const ButtonsFooter = ({ children, submitOptions = {}, cancelOptions = {} }) => (
  <StyledContainer>
    <ButtonToolbar>
      {children || (
        <>
          <Submit {...submitOptions} />
          <Cancel {...cancelOptions} />
        </>
      )}
    </ButtonToolbar>
  </StyledContainer>
);

export default ButtonsFooter;
