import React from 'react';

import { InUseCollapse } from './InUse';

export default {
  id: 'in_use_header',
  Header: 'In use',
  customMaxWidth: 300,
  customMinWidth: 150,
  Cell: ({ row }) => <InUseCollapse key={row.original.id} inUse={row.original.inUse} />,
};
