import { useQuery } from '@tanstack/react-query';
import { CampaignsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';
import { hasApplicationPermissions } from '@services/permissions';

const LIST_QUERY_NAME_KEY = 'campaigns/sequential_offers';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function sequentialOffersQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await CampaignsRoutes.SequentialOffers.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useSequentialOffersOptions(query = {}) {
  if (!hasApplicationPermissions(['campaigns', 'sequential_offer', 'list'])) {
    return new Promise((resolve) => { resolve([]); });
  }
  return useQuery(sequentialOffersQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items, {
      transform: (option, { name, itemsCount }) => Object.assign(option, { label: `${name} - ${itemsCount} items` }),
    }),
  }));
}

export function useSequentialOffersQuery(query = {}) {
  return useQuery(sequentialOffersQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
