import React from 'react';
import { useRouter } from '@tripledotstudios/react-core';

import { useAppData, useQuery, useCurrentApplication } from '@hooks';
import { PaymentsRoutes, ABTestingRoutes } from '@pages/routes';

import CatalogPresetsTabs from './CatalogPresetsTabs';

export default function ProductsTabs({ defaultValues }) {
  const currentApplication = useCurrentApplication();
  const { query } = useRouter();
  const productTypes = useAppData().enums.ApplicationProductsTypes;
  const isGameProductsApplication = currentApplication.productsType === productTypes.GAME_PRODUCTS;

  const { response: products } = useQuery(
    PaymentsRoutes.Products.indexRequest,
    { ...query, withoutPagination: true },
  );
  let storeProducts = { items: null };

  if (isGameProductsApplication) {
    storeProducts = useQuery(
      PaymentsRoutes.StoreProducts.indexRequest,
      { ...query, deleted: false, withoutPagination: true },
    ).response;
  }

  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: productLabelsRaw } = useQuery(
    PaymentsRoutes.ProductLabels.indexRequest,
    { ...query, withoutPagination: true },
  );

  let productLabels = null;

  if (productLabelsRaw) {
    productLabels = productLabelsRaw.items.map(({ name, id }) => ({ label: name, value: id }));
  }

  return products && storeProducts && experiments && productLabels && (
    <CatalogPresetsTabs
      catalogPresetsAttributes={defaultValues.catalogPresetsAttributes}
      products={products.items}
      storeProducts={storeProducts?.items}
      productLabels={productLabels}
      experiments={experiments.items}
    />
  );
}
