import React from 'react';
import { Route } from 'react-router-dom';

import PageTitle from '@pages/PageTitle';
import { DebugRoutes } from '@pages/routes';

import Generator from './Generator';

export default function DebugRoot() {
  return [
    <Route
      exact
      key={DebugRoutes.generateJwtRawPath}
      path={DebugRoutes.generateJwtRawPath}
      element={(
        <>
          <PageTitle text="JWT Generator" />
          <Generator />
        </>
      )}
    />,
  ];
}
