import React from 'react';
import { Route } from 'react-router-dom';

import { PaymentsRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';

const Routes = PaymentsRoutes.GameProducts;

export default function GameProductsRoot() {
  return [Routes.indexRawPath, Routes.editRawPath].map((path) => (
    <Route
      exact
      key={path}
      path={path}
      element={(
        <>
          <PageTitle text="Game Products" />
          <Index />
        </>
      )}
    />
  ));
}
