import { useQuery } from '@tanstack/react-query';
import { PaymentsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'payments/game_products';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function gameProductsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await PaymentsRoutes.Products.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useGameProductsOptions(query = {}) {
  return useQuery(gameProductsQuery({
    params: {
      ...query,
      includeInUse: true,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items.filter(({ deleted }) => !deleted)),
  }));
}

export function useGameProducts(query = {}) {
  return useQuery(gameProductsQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}

