import { Route } from 'react-router-dom';

import { EventsRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';

const Routes = EventsRoutes.CollectionSets;

export default function CollectionSetsRoot() {
  return [Routes.indexRawPath, Routes.editRawPath, Routes.newRawPath].map((path) => (
    <Route
      exact
      key={path}
      path={path}
      element={(
        <>
          <PageTitle text="Events Collection Sets" />
          <Index />
        </>
      )}
    />
  ));
}
