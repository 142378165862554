import React, { useContext, createContext, useMemo } from 'react';
import { isEmpty } from 'lodash';

const FormPermissionsContext = createContext({});
export const useFormPermissions = () => useContext(FormPermissionsContext);

export const FormPermissionsProvider = ({ permissions, children }) => {
  const readOnly = isEmpty(permissions) ? false : !permissions.update;
  const value = useMemo(() => ({
    ...permissions,
    readOnly,
  }), [JSON.stringify(permissions)]);

  return (
    <FormPermissionsContext.Provider value={value}>
      {children}
    </FormPermissionsContext.Provider>
  );
};
