import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { OpenedIcon } from '@components/icons';

const CollapseContainer = styled.div`
  &:not(.show) {
    display: block !important;
    min-height: ${({ minHeight }) => minHeight}px;
    overflow: hidden;
  }
  &:not(.show):not(.collapsing) {
    height: 0 !important;
  }
`;

const CollapseWrapper = styled.span`
  margin-top: 5px;
  margin-left: 5px;
`;

const PartialCollapse = ({ children, minHeight, disableCollapse = false }) => {
  const [open, setOpen] = useState(false);
  const id = `collapse-${uuidv4()}`;
  if (disableCollapse) return children;

  return (
    <>
      <Collapse in={open}>
        <CollapseContainer id={id} minHeight={minHeight}>
          {children}
        </CollapseContainer>
      </Collapse>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() => setOpen(!open)}
        aria-controls={id}
        aria-expanded={open}
      >
        { open ? 'Collapse' : 'Expand' }
        <CollapseWrapper><OpenedIcon opened={open} rotate={180} icon={faChevronDown} size="sm" /></CollapseWrapper>
      </span>
    </>
  );
};

PartialCollapse.protoTypes = {
  disableCollapse: PropTypes.bool,
  minHeight: PropTypes.number.isRequired,
};

export default PartialCollapse;

export const AutoCollapse = (props) => {
  const { children, minHeight, disableCollapse } = props;
  const [collapseEnabled, setCollapseEnabled] = useState(false);
  const collapseContainer = useRef();
  useEffect(() => {
    if (get(collapseContainer.current, 'offsetHeight') > minHeight) { setCollapseEnabled(true); }
  }, [collapseContainer.current]);
  if (!collapseEnabled) return <div ref={collapseContainer}>{children}</div>;

  return (
    <PartialCollapse {...props} disableCollapse={!collapseEnabled || disableCollapse}>
      {children}
    </PartialCollapse>
  );
};
