import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useRouter } from '@tripledotstudios/react-core';

export default function useQuery(request, data = {}, memoizationKeys = []) {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchUuid, setFetchUuid] = useState(uuidv4());
  const router = useRouter();

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    request({ ...router.query, ...data }).then((resp) => {
      if (isMounted) setResponse(resp.data);
    }).finally(() => {
      if (isMounted) setIsLoading(false);
    });

    return () => {
      isMounted = false;
    };
  }, [fetchUuid, ...memoizationKeys]);

  const refetch = () => {
    setFetchUuid(uuidv4());
  };

  return {
    response,
    isLoading,
    setResponse,
    refetch,
  };
}
