import { useAppData } from '@hooks';
import CreativeLayout from './CreativeLayout';
import ChainedLayout from './ChainedLayout';

export default function ChainedLayoutSupport({
  availabilityCap,
  creativeLayoutsDataAttributes,
  sequentialOffersOptions,
  creativeImagesOptions,
  template,
  appLinkConfigurations,
  localisationKeys,
  contentType,
  replaceCreativeLayoutData,
}) {
  const { enums } = useAppData();
  const contentTypes = enums['Campaigns::CreativeContentTypes'];

  switch (contentType) {
    case contentTypes.CHAINED:
      return (
        <ChainedLayout
          availabilityCap={availabilityCap}
          creativeLayoutsDataAttributes={creativeLayoutsDataAttributes}
          layoutDataAttributeName="creativeLayoutsDataAttributes"
          template={template}
          sequentialOffersOptions={sequentialOffersOptions}
          creativeImagesOptions={creativeImagesOptions}
          appLinkConfigurations={appLinkConfigurations}
          localisationKeys={localisationKeys}
          replaceCreativeLayoutData={replaceCreativeLayoutData}
        />
      );
    case contentTypes.SEQUENTIAL:
    case contentTypes.POPUP:
    default:
      return (
        <CreativeLayout
          availabilityCap={availabilityCap}
          creativeLayoutsDataAttributes={creativeLayoutsDataAttributes}
          sequentialOffersOptions={sequentialOffersOptions}
          creativeImagesOptions={creativeImagesOptions}
          layoutDataAttributeName="creativeLayoutsDataAttributes"
          template={template}
          appLinkConfigurations={appLinkConfigurations}
          localisationKeys={localisationKeys}
        />
      );
  }
}
