import React from 'react';

import { useQuery } from '@hooks';
import { StyledPaneWrapper } from '@components/SplitPane';
import { useRequireAuth } from '@tripledotstudios/react-core';
import { PageHeader } from '@components';
import { ApplicationsRoutes as Routes } from '@pages/routes';

import Form from './Form';

export default function Edit() {
  useRequireAuth();

  const { response } = useQuery(Routes.editRequest);
  const submitRequest = (values) => Routes.updateRequest(values);

  return (
    <>
      <PageHeader title="Edit Application" />
      <StyledPaneWrapper>
        {response && (
          <Form
            actionName="update"
            submitRequest={submitRequest}
            defaultValues={response}
          />
        )}
      </StyledPaneWrapper>
    </>
  );
}
