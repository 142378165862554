import React from 'react';
import { Container } from 'react-bootstrap';

import { useAppData } from '@hooks';
import { Form, InUse } from '@components';
import { FloatingLabeledInput, FloatingLabeledSelect, FloatingLabeledDateTimeInput } from '@components/resource';
import Segments from './form/Segments';

export default function ConfigurationsForm(props) {
  const { enumOptions } = useAppData();
  const configurationStatuses = enumOptions['Campaigns::ConfigurationStatuses'];
  const schedulingTimeTypes = enumOptions.SchedulingTimeTypes;

  return (
    <Form.Resource {...props}>
      <Container fluid className="form-container">
        <Form.ControlsLayout>
          <FloatingLabeledInput label="Name" name="name" />
          <FloatingLabeledInput label="Description" name="description" />
          <FloatingLabeledInput label="Priority" name="priority" />
          <FloatingLabeledSelect label="Status" name="status" options={configurationStatuses} />
          <FloatingLabeledDateTimeInput label="Start At" name="validFrom" />
          <FloatingLabeledDateTimeInput label="End At" name="validTo" />
          <InUse.AvailabilityStateFormGroup floating />
          <FloatingLabeledSelect
            label="Scheduling Time Type"
            name="schedulingTimeType"
            options={schedulingTimeTypes}
          />
        </Form.ControlsLayout>

        <hr />

        <Segments />
      </Container>
    </Form.Resource>
  );
}
