import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { camelCase } from 'lodash';
import styled from 'styled-components';
import { useConfirm } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';
import { ButtonsFooter } from '@components/collection';
import { SaveIcon } from '@components/icons';
import Button from '@components/buttons';

const StyledButton = styled.button`
  border-radius: unset;
  text-decoration: none;

  &:hover {
    background-color: #e9ecef;
  }
`;

const toPascalCase = (str) => {
  const tempString = camelCase(str);

  return tempString[0].toUpperCase() + tempString.slice(1);
};

const SubmitDropdownItem = ({ text, onClick }) => (
  <StyledButton className="btn btn-link" onClick={onClick}>
    {text}
  </StyledButton>
);

export default function ExperimentsButtonsFooter({
  defaultValues, formRef, onCancel, submitOptions,
}) {
  const confirm = useConfirm();
  const submitRef = useRef(null);
  const transitionEvents = useAppData().enumOptions['AbTesting::ExperimentTransitionEvents'];

  const mayShowButton = (event) => defaultValues[`may${toPascalCase(event)}`];
  const handleTransition = (e, transitionEvent, transitionName) => {
    e.preventDefault();

    confirm.showConfirmation({
      title: 'Confirm',
      body: `Are you sure you want to save experiment and ${transitionName}?`,
    }).then(() => {
      formRef.current.setValue('transitionEvent', transitionEvent);
      submitRef.current.click();
    }).catch(() => {});
  };
  const handleRegularSubmit = () => {
    formRef.current.setValue('transitionEvent', null);
    submitRef.current.click();
  };

  const availableTransitions = transitionEvents.filter(({ value }) => mayShowButton(value));

  return (
    <ButtonsFooter>
      <Button.Submit form="react-hook-form" hidden ref={submitRef} />
      <Button.Submit type="button" onClick={handleRegularSubmit} {...submitOptions} />
      {availableTransitions.length > 0 && (
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" {...submitOptions}>
            <SaveIcon />
            {' '}
            Save & Change status
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <div className="d-flex flex-column">
              {availableTransitions.map(({ value, label }) => (
                <SubmitDropdownItem key={value} text={label} onClick={(e) => handleTransition(e, value, label)} />
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <Button.Cancel onClick={onCancel} />
    </ButtonsFooter>
  );
}
