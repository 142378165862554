import React, {
  memo, useCallback, useState, useRef,
} from 'react';
import styled from 'styled-components';
import { Tooltip, Overlay } from 'react-bootstrap';
import { CopyIcon } from '../icons';

const StyledButton = styled.button`
  border: none;
  background-color: white;
  border-radius: 4px;

  &:hover, &:focus {
    background-color: #ebebeb;
  }
`;

const CopyButton = ({ value }) => {
  const [show, setShow] = useState(false);

  const tooltip = useRef(null);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(value);
    setShow(true);
  }, [value]);

  return (
    <>
      <StyledButton onClick={handleCopyToClipboard} type="button" ref={tooltip} onMouseOut={() => setShow(false)}>
        <CopyIcon />
      </StyledButton>
      <Overlay target={tooltip.current} show={show} placement="top">
        {(props) => (
          <Tooltip {...props}>
            Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default memo(CopyButton);
