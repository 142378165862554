import React from 'react';
import { Alert } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { Button } from '@components';
import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';
import { Input, Select, Label } from '@components/resource';
import { useExtendedFieldArray, useFormPermissions } from '@hooks';

const BackupItemsInfo = ({ rewardAttributes, gameItemsOptions }) => {
  const backupItems = gameItemsOptions.find(({ value }) => value === rewardAttributes.gameItemId)?.backupGameItemsData;

  return (
    <Alert variant="info">
      <b>Backup Rewards: </b>
      {backupItems?.length > 0 ? (
        backupItems.map(({ gameItemName, quantity }, index) => (
          <span key={gameItemName}>
            {`${gameItemName} x `}
            <b>{quantity * rewardAttributes.quantity || 0}</b>
            {index !== backupItems.length - 1 ? <span>; </span> : null}
          </span>
        ))
      ) : <span>Not configured</span>}
    </Alert>
  );
};

export default function Rewards({
  gameItemsOptions,
  formGroupName,
  label = 'Rewards',
  sizes = [3, 9],
  withBackupItems = true,
}) {
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();
  const { watch } = useFormContext();
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: generateName(formGroupName),
    keyName: 'uiKey',
  });

  const appendGameItem = () => append({ quantity: 0 });

  return (
    <Label label={label} sizes={sizes}>
      {fields.map((item, index) => {
        const rewardPath = `${formGroupName}.${index}`;
        const rewardAttributes = watch(generateName(rewardPath));

        return item._destroy || (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${item.uiKey}.${formGroupName}.${index}`} name={rewardPath}>
            <div className="d-flex mb-1">
              <div className="flex-fill w-100 me-1">
                <Select name="gameItemId" options={gameItemsOptions} />
              </div>
              <div className="flex-fill w-100">
                <Input label="Quantity" name="quantity" type="number" />
              </div>
              {readOnly || (
                <Button.Delete
                  className="mt-1"
                  title="Remove"
                  onClick={() => handleDelete(item, index)}
                />
              )}
            </div>
            {withBackupItems && (
              <BackupItemsInfo rewardAttributes={rewardAttributes} gameItemsOptions={gameItemsOptions} />
            )}
          </FormGroup>
        );
      })}
      {readOnly || <Button.Add className="mt-2" onClick={appendGameItem} />}
    </Label>
  );
}
