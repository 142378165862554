import { isEmpty } from 'lodash';

import { useAppData } from '@hooks';
import { Form } from '@components';
import { FloatingLabeledInput } from '@components/resource';
import { TemplateParametersForm } from '@components/dynamic_parameters';

export default function TypesForm({ resource, ...rest }) {
  const inUse = !isEmpty(resource.data.inUse);
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_EVENT];

  return (
    <Form.Resource resource={resource} {...rest}>
      <Form.ControlsLayout>
        <FloatingLabeledInput label="Name" disabled={inUse} name="name" />
        <FloatingLabeledInput label="Key" disabled={inUse} name="key" />
        <FloatingLabeledInput label="Max number returned" name="maxNumberReturned" />
        <FloatingLabeledInput label="Max number of activities" name="maxActivities" type="number" disabled={inUse} />
      </Form.ControlsLayout>
      <TemplateParametersForm inUse={inUse} editableInUse allowedAssetBundlesTypes={assetBundlesTypes} />
    </Form.Resource>
  );
}
