import React from 'react';
import { Table } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import {
  useAppData, useExtendedFieldArray, useFormPermissions,
} from '@hooks';
import {
  LabeledSelect, Label, Input, Select,
  DiffWrapper,
} from '@components/resource';
import Button from '@components/buttons';

export default function CreativeTrigger({ triggers }) {
  const { generateName } = useFormGroup();
  const { watch } = useFormContext();
  const { readOnly } = useFormPermissions();
  const ruleOperationsOptions = useAppData().enumOptions.RuleOperations;
  const defaultRuleOperation = useAppData().enums.RuleOperations.EQUAL;
  const defaultRuleOptions = { operation: defaultRuleOperation, value: 0 };

  const triggersOptions = triggers.items.map(({ id, name }) => ({ label: name, value: id }));

  const rulesFormGroupName = generateName('rulesAttributes');
  const {
    fields, append, remove, handleDelete,
  } = useExtendedFieldArray({
    name: rulesFormGroupName,
    keyName: 'uiKey',
  });

  const triggerId = watch(generateName('triggerId'));
  let triggerArgumentsOptions = [];
  if (triggerId) {
    triggerArgumentsOptions = triggers.items.find(({ id }) => +id === +triggerId)
      .triggerArgumentsAttributes.map(({ id, key }) => ({ label: key, value: id }));
  }

  const handleTriggerChange = (newTriggerId) => {
    if (newTriggerId === triggerId) {
      return;
    }
    const persistedRules = fields.filter(({ id }) => id);
    remove();
    persistedRules.map((rule) => append({ ...rule, _destroy: true }));
  };

  return (
    <>
      <DiffWrapper for="triggerId">
        <LabeledSelect
          label="Trigger"
          name="triggerId"
          onChange={handleTriggerChange}
          options={triggersOptions}
          sizes={[4, 8]}
        />
      </DiffWrapper>
      <Label label="Trigger rules" sizes={[4, 8]}>
        {fields.filter(({ _destroy }) => !_destroy).length > 0 && (
          <Table size="sm">
            <thead>
              <tr>
                <th>Argument</th>
                <th width="20%">Operator</th>
                <th width="20%">Value</th>
                {!readOnly && <th width="10%">Action</th>}
              </tr>
            </thead>
            <tbody>
              {fields.map((rule, index) => (
                rule._destroy || (
                  // eslint-disable-next-line react/no-array-index-key
                  <FormGroup key={`${rule.uiKey}.${index}`} name={`rulesAttributes.${index}`}>
                    <tr>
                      <td>
                        <DiffWrapper for="argumentId">
                          <Select name="argumentId" options={triggerArgumentsOptions} />
                        </DiffWrapper>
                      </td>
                      <td>
                        <DiffWrapper for="operation">
                          <Select name="operation" options={ruleOperationsOptions} />
                        </DiffWrapper>
                      </td>
                      <td>
                        <DiffWrapper for="value">
                          <Input name="value" type="number" step="0.01" />
                        </DiffWrapper>
                      </td>
                      {!readOnly && (
                        <td><Button.Delete title="Remove" onClick={() => handleDelete(rule, index)} /></td>
                      )}
                    </tr>
                  </FormGroup>
                )
              ))}
            </tbody>
          </Table>
        )}
        {!readOnly && <Button.Add title="Add argument rule" onClick={() => append(defaultRuleOptions)} />}
      </Label>
    </>
  );
}
