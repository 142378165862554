import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${({ size }) => size}rem;
  color: ${({ color }) => color};
`;

const UploadSkipIcon = ({ size = 1.2, color = '#bebebe' }) => (
  <StyledIcon color={color} size={size} className="bi bi-cloud-fill" />
);

export default memo(UploadSkipIcon);
