import { Badge } from 'react-bootstrap';

export default function AvailabilityStateOptionLabelFormat({
  label,
  availabilityStateLive,
  availabilityState,
}) {
  return (
    <div>
      {label}
      <Badge className="ms-1" bg={availabilityStateLive ? 'success' : 'info'}>
        {availabilityState}
      </Badge>
    </div>
  );
}
