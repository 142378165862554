import React from 'react';
import {
  Table, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { PageSection, useRouter } from '@tripledotstudios/react-core';
import {
  useQuery, useAppData,
} from '@hooks';
import { Form, Button } from '@components';
import { Select } from '@components/resource';
import { Status } from '@components/collection';
import { AccountsRoutes, ABTestingRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import getMap from '@services/getMap';
import { hasApplicationPermissions } from '@services/permissions';

const Routes = AccountsRoutes.UserAbParticipations;

const buildVariantUrl = ({
  applicationId,
  experimentId,
  variantId,
}) => `${ABTestingRoutes.Experiments.editPath({ applicationId, id: experimentId })}?variantId=${variantId}`;

export default function ExperimentParticipationsView() {
  const { query } = useRouter();
  const { applicationId } = query;

  const requestParams = { userId: query.id, applicationId: query.applicationId };
  const { response, refetch } = useQuery(Routes.indexRequest, requestParams);

  const userParticipations = response?.participations;
  const experiments = response?.experiments;
  const statuses = getMap(useAppData().enumOptions['AbTesting::ExperimentStatuses']);

  const writePermissions = hasApplicationPermissions(['player', 'write']);

  if (!response) return null;

  const experimentFor = (experimentId) => (
    experiments.find(({ id }) => +id === +experimentId)
  );

  const variantOptionsFor = (experimentId) => (
    [...experimentFor(experimentId).variantsAttributes.map((variant) => ({ label: variant.name, value: variant.id }))]
  );

  return (
    <>
      <PageSection title="Experiments Participations" />

      <Row>
        <Col lg={6} xs={12}>
          <Form defaultValues={{ userParticipations, permissions: { update: writePermissions } }} enableReinitialize>
            {({ setError, watch }) => {
              const responseHandler = buildResourceResponseHandler({
                actionName: 'update',
                setError,
                onSuccess: refetch,
              });
              const onSubmit = (values) => Routes.updateRequest({ ...values, ...requestParams })
                .then(responseHandler);

              return (
                <Form.Component onSubmit={onSubmit}>
                  <Table className="table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Experiment</th>
                        <th width="100px">Status</th>
                        <th width="200px">Variant ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userParticipations.map(({ id, experimentId, variantId }, index) => (
                        <tr key={id}>
                          <td>
                            {experimentFor(experimentId)
                              ? (
                                <Link
                                  to={buildVariantUrl({
                                    applicationId,
                                    experimentId,
                                    variantId: watch(`userParticipations.${index}.variantId`),
                                  })}
                                  target="_blank"
                                >
                                  {experimentFor(experimentId).name}
                                </Link>
                              )
                              : experimentId}
                          </td>
                          <td>
                            <Status
                              value={experimentFor(experimentId)
                                ? statuses[experimentFor(experimentId).status]
                                : 'deleted'}
                            />
                          </td>
                          <td>
                            { experimentFor(experimentId)
                              ? (
                                <Select
                                  name={`userParticipations.${index}.variantId`}
                                  options={variantOptionsFor(experimentId)}
                                />
                              )
                              : variantId}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {writePermissions && <Button.Submit />}
                </Form.Component>
              );
            }}
          </Form>
        </Col>
      </Row>
    </>
  );
}
