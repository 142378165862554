import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { mapValues, isArray } from 'lodash';

import { IconTooltip } from '@tripledotstudios/react-core';

export default function Label({
  // sizes example could be: { xs: [4, 8], lg: [3, 9]}
  label, sizes = [4, 8], tooltipText = null, children, className, ...rest
}) {
  const sizeProps = isArray(sizes) ? { xs: sizes } : sizes;
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  const labelContent = <label className={classnames('fw-bold', 'col-form-label', className)} {...rest}>{label}</label>;

  return (
    <Row className="mb-2 form-group">
      <Col className="text-nowrap" {...mapValues(sizeProps, 0)}>
        {tooltipText ? (
          <IconTooltip.Help text={tooltipText}>
            {labelContent}
            {' '}
          </IconTooltip.Help>
        ) : labelContent}
      </Col>

      <Col {...mapValues(sizeProps, 1)}>
        {children}
      </Col>
    </Row>
  );
}
