import React, { useMemo } from 'react';
import { useQuery } from '@hooks';
import { useRequireAuth } from '@tripledotstudios/react-core';
import {
  Pagination,
  PageHeader,
  Button,
  Pane,
} from '@components';
import { Table } from '@components/collection';
import { ApplicationsRoutes as Routes } from '@pages/routes';
import { hasPermissions } from '@services/permissions';

export default function Index() {
  const { response, refetch } = useQuery(Routes.indexRequest);
  const canCreate = hasPermissions(['application', 'write']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'App ID',
        accessor: 'appId',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { id, name, permissions } } }) => (
          <>
            {permissions.edit && <Button.EditLink className="me-2" id={id} routes={Routes} />}
            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={Routes}
                onDelete={refetch}
                recordName={`Application "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [],
  );

  useRequireAuth();

  return (
    response && (
      <>
        <PageHeader title="Applications">
          {canCreate && <Button.AddLink title="Add Application" to={Routes.newPath()} />}
        </PageHeader>
        <Pane>
          <Table columns={columns} data={response.items} />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </Pane>
      </>
    )
  );
}
