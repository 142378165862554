import React from 'react';

import { Form, FormContainer } from '@components';
import { LabeledInput } from '@components/resource';
import { useCurrentApplication } from '@hooks';

export default function StoreProductForm({ resource, ...rest }) {
  const { storeProductKey } = useCurrentApplication();

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ setValue }) => {
        const handlePriceChange = ({ target: { value } }) => {
          const formattedValue = parseFloat(value) > 0 ? parseFloat(value).toFixed(2) : 0;
          setValue('storeId', `${formattedValue}_package_${storeProductKey}`);
        };

        return (
          <FormContainer>
            <LabeledInput
              disabled={resource.data.id}
              onChange={handlePriceChange}
              label="Price"
              name="price"
              type="number"
              step="0.01"
            />
            <LabeledInput disabled label="Store Id" name="storeId" />
          </FormContainer>
        );
      }}
    </Form.Resource>
  );
}
