import React from 'react';
import { Tab } from 'react-bootstrap';

import { useQuery } from '@hooks';
import { AnchorTabs, useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { AccountsRoutes } from '@pages/routes';

import UserView from './views/UserView';
import DevicesView from './views/DevicesView';
import PurchasesView from './views/PurchasesView';
import ExperimentParticipationsView from './views/ExperimentParticipationsView';
import ImpressionsStatsView from './views/ImpressionsStatsView';
import PiggyBanksView from './views/PiggyBanksView';
import SegmentsView from './views/SegmentsView';
import EventsView from './views/EventsView';

const Routes = AccountsRoutes.Users;

export default function Edit() {
  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.editRequest, query);

  useRequireAuth();

  return response ? (
    <>
      <UserView data={response} refetch={refetch} />
      <hr />
      <AnchorTabs
        defaultActiveKey="#devices"
        className="mb-3"
      >
        <Tab eventKey="#devices" title="Devices">
          <DevicesView devices={response.devices} />
        </Tab>
        <Tab eventKey="#purchases" title="Purchases">
          <PurchasesView />
        </Tab>
        <Tab eventKey="#segments" title="Segments">
          <SegmentsView />
        </Tab>
        <Tab eventKey="#ab_participations" title="Experiment Participations">
          <ExperimentParticipationsView />
        </Tab>
        <Tab eventKey="#impressions_stats" title="Impressions Stats">
          <ImpressionsStatsView />
        </Tab>
        <Tab eventKey="#piggy_banks" title="Piggy Banks">
          <PiggyBanksView />
        </Tab>
        <Tab eventKey="#events" title="Events">
          <EventsView />
        </Tab>
      </AnchorTabs>
    </>
  ) : '';
}
