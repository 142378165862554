import React from 'react';
import {
  Accordion, Row, Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { LabeledInput } from '@components/resource';
import { EmptyList } from '@components/collection';
import { StyledStatus } from '@components/collection/Status';
import { Form, Button } from '@components';
import { useQuery } from '@hooks';
import { PageSection, useRouter } from '@tripledotstudios/react-core';
import { AccountsRoutes, PiggyBanksRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';

const Routes = AccountsRoutes.UserPiggyBanksInstances;

const Status = ({ status, instance: { purchasedCount }, ...rest }) => {
  let statusValue = '';
  let statusText = '';

  if (status === 'active') {
    statusValue = 'ACTIVE';
    statusText = 'Currently Active';
  } else if (status === 'previous') {
    statusValue = 'INACTIVE';
    statusText = `Previously ${purchasedCount > 0 ? 'purchased' : 'accessed'}`;
  } else if (status === 'new') {
    statusValue = 'EXPIRED';
    statusText = 'Available to opt in';
  }

  return <StyledStatus value={statusValue} {...rest}>{statusText}</StyledStatus>;
};

const defaultOpenedItems = ({ items }) => {
  const itemsToOpen = [];

  items.forEach(({ id, status }) => {
    if (status === 'active' || status === 'new') {
      itemsToOpen.push(id);
    }
  });

  return itemsToOpen;
};

const PiggyInstanceForm = ({
  id, instance, permissions, refetch,
}) => {
  const { query } = useRouter();

  const requestParams = {
    applicationId: query.applicationId,
    userId: query.id,
    id,
  };

  return (
    <Form defaultValues={{ ...instance, permissions }} enableReinitialize>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({
          actionName: 'update',
          setError,
          onSuccess: refetch,
        });
        const onSubmit = (values) => Routes.updateRequest({ ...requestParams, ...values })
          .then(responseHandler);
        if (!instance.optedIn) {
          return 'Opt in required';
        }
        return (
          <Form.Component onSubmit={onSubmit}>
            <Row>
              <Col xs={12}>
                <LabeledInput label="Accumulated Amount" name="amount" sizes={[3, 9]} />
              </Col>
              <Col xs={12}>
                <LabeledInput label="Purchased count" name="purchasedCount" sizes={[3, 9]} />
              </Col>
            </Row>
            {permissions.update && <Button.Submit />}
          </Form.Component>
        );
      }}
    </Form>
  );
};

export default function PiggyBanksView() {
  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { userId: query.id });
  const writePermissions = hasApplicationPermissions(['player', 'write']);

  const buildConfigUrl = ({ id, configurationSegmentId }, abVariantId) => (
    PiggyBanksRoutes.Configurations.editPath(
      {
        applicationId: query.applicationId,
        id,
      },
      {
        configurationSegmentId,
        variantId: abVariantId,
      },
    )
  );

  return response && (
    <>
      <PageSection title="Piggy Bank Instances" />

      {response.items.length > 0
        ? (
          <Row>
            <Col xs={12}>
              <Accordion defaultActiveKey={defaultOpenedItems(response)} alwaysOpen>
                {response.items.map(({
                  instance, configuration, status, abVariantId,
                }) => (
                  <Accordion.Item eventKey={instance.id} key={instance.id}>
                    <Accordion.Header>
                      {`Configuration "${configuration.name}" - Instance #${instance.id || 'new'}`}
                      <Status status={status} instance={instance} className="ms-2" />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-2">
                        <Link
                          to={buildConfigUrl(configuration, abVariantId)}
                          key={`configuration_${instance.id}`}
                          target="_blank"
                        >
                          Go to configuration
                        </Link>
                      </div>
                      <Row>
                        <Col>
                          <PiggyInstanceForm
                            permissions={{ update: writePermissions }}
                            instance={instance}
                            refetch={refetch}
                          />
                        </Col>
                        <Col>
                          <dl className="row">
                            <dt className="col-6">Configuration</dt>
                            <dd className="col-6">{configuration.name}</dd>

                            <dt className="col-6">Status</dt>
                            <dd className="col-6">{configuration.status}</dd>

                            <dt className="col-6">Piggy Enabled</dt>
                            <dd className="col-6">{configuration.enabled ? 'Yes' : 'No'}</dd>

                            <dt className="col-6">Piggy Max Amount</dt>
                            <dd className="col-6">{configuration.piggyMaxAmount}</dd>

                            <dt className="col-6">Piggy Min Break Amount</dt>
                            <dd className="col-6">{configuration.piggyMinBreakAmount}</dd>

                            <dt className="col-6">Price</dt>
                            <dd className="col-6">{configuration.price}</dd>

                            <dt className="col-6">Start At</dt>
                            <dd className="col-6">{configuration.startAt}</dd>

                            <dt className="col-6">End At</dt>
                            <dd className="col-6">{configuration.endAt || 'No time limit'}</dd>
                          </dl>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        )
        : <EmptyList title="No piggy instances to display" />}
    </>
  );
}
