import React, { useState } from 'react';

import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { PageHeader } from '@components';

import { CreativesRoutes } from '@pages/routes';
import Form from './Form';

const Routes = CreativesRoutes.Templates;

export default function New() {
  useRequireAuth();
  const [formValues, setFormValues] = useState({});

  const { query } = useRouter();
  const submitRequest = (values) => Routes.createRequest({ ...values, ...query });
  const uploadRequest = (formData) => Routes.uploadRequest(formData, query);

  return (
    <>
      <PageHeader title="Upload Template" />
      <Form
        actionName="create"
        formValues={formValues}
        setFormValues={setFormValues}
        uploadRequest={uploadRequest}
        submitRequest={submitRequest}
        displayUploadForm
      />
    </>
  );
}
