import React from 'react';
import { IconButton, PageHeader as TDSPageHeader } from '@tripledotstudios/react-core';

export const HEADER_HEIGHT_REM = 7;

export default function PageHeader({
  filter = null,
  children,
  ...rest
}) {
  return (
    <TDSPageHeader
      trailingAccessories={children}
      sticky={false}
      collapsibleToggle={IconButton.Filter}
      {...rest}
    >
      {filter}
    </TDSPageHeader>
  );
}
