import React, { useState } from 'react';
import { useQuery, useCrudlRequests } from '@hooks';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { PiggyBanksRoutes } from '@pages/routes';
import { PageHeader, SplitPane } from '@components';
import { ButtonsFooter } from '@components/collection';
import Button from '@components/buttons';
import { hasApplicationPermissions } from '@services/permissions';

import List from './List';
import Form from './Form';

const Routes = PiggyBanksRoutes.Configurations;

export default function Index() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { ...query, withoutPagination: true });
  const [resource, setResource] = useState(null);
  const canCreate = hasApplicationPermissions(['piggy_banks', 'configuration', 'write']);

  const {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  return (
    <>
      <PageHeader title="Piggy Banks">
        {canCreate && <Button.Add title="Add Configuration" onClick={handleNew} />}
      </PageHeader>
      <SplitPane paneName="piggy_configs">
        <SplitPane.Top>
          <List
            response={response}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDelete={handleDelete}
            routes={Routes}
          />
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ onClick: handleOnCancel }}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
}
