import React, { useState } from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useCrudlRequests } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';
import { PageHeader, SplitPane, Button } from '@components';
import { ButtonsFooter } from '@components/collection';
import { hasApplicationPermissions } from '@services/permissions';

import List from './List';
import Form from './Form';

const Routes = PaymentsRoutes.V2.GameProducts;

export default function GameProductsIndex() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(
    Routes.indexRequest,
    { ...query, withoutPagination: true, includeInUse: true },
  );
  const [resource, setResource] = useState(null);
  const canCreate = hasApplicationPermissions(['payments', 'game_product', 'write']);

  const {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  return (
    <>
      <PageHeader title="Game Products">
        {canCreate && <Button.Add title="Add Game Product" onClick={handleNew} />}
      </PageHeader>
      <SplitPane paneName="game_products">
        <SplitPane.Top>
          <List
            response={response}
            refetch={refetch}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDelete={handleDelete}
            routes={Routes}
          />
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ title: resource?.data.permissions.update ? 'Cancel' : 'Back', onClick: handleOnCancel }}
            submitOptions={{ disabled: !canCreate }}
          />
        )}
      </SplitPane>
    </>
  );
}
