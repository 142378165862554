import React from 'react';

import { Form, FormContainer } from '@components';
import { LabeledInput } from '@components/resource';

export default function CashProductsForm(props) {
  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Price" name="price" type="number" step="0.01" />
      </FormContainer>
    </Form.Resource>
  );
}
