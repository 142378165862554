export default function DragHandle({ attributes, listeners, children }) {
  return (
    <div
      {...attributes}
      {...listeners}
      style={{
        cursor: 'grab',
        display: 'inline-flex',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}
