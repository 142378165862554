import React from 'react';
import { Table } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { Button } from '@components';
import { FormGroup, PageSection } from '@tripledotstudios/react-core';
import { Input, Select } from '@components/resource';
import { useExtendedFieldArray, useFormPermissions, useQuery } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';

const GameItemsRoutes = PaymentsRoutes.GameItems;

export default function BackupItems() {
  const { readOnly } = useFormPermissions();
  const { formState: { defaultValues } } = useFormContext();

  const gameItemsPath = 'backupGameItemsAttributes';
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: gameItemsPath,
    keyName: 'uiKey',
  });

  const appendNewProduct = () => append({ quantity: 0 });

  const { response: gameItems } = useQuery(GameItemsRoutes.indexRequest, { deleted: false, withoutPagination: true });

  if (!gameItems?.items) {
    return null;
  }

  const gameItemOptions = gameItems.items
    .filter((obj) => obj.id !== defaultValues.id)
    .map(({ id, name }) => ({ label: name, value: id }));

  return (
    <PageSection title="Backup Rewards">
      <Table size="sm">
        <thead>
          <tr>
            <th>Game Item</th>
            <th>Quantity</th>
            {!readOnly && <th width="10%">Action</th>}
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            item._destroy || (
              // eslint-disable-next-line react/no-array-index-key
              <FormGroup key={`${gameItemsPath}.${item.uiKey}.${index}`} name={`${gameItemsPath}.${index}`}>
                <tr>
                  <td>
                    <Select name="gameItemId" options={gameItemOptions} />
                    {' '}
                  </td>
                  <td><Input label="Quantity" name="quantity" /></td>
                  <td>
                    {!readOnly && (
                      <Button.Delete
                        className="delete me-1"
                        title="Remove"
                        onClick={() => handleDelete(item, index)}
                      />
                    )}
                  </td>
                </tr>
              </FormGroup>
            )
          ))}
        </tbody>
      </Table>
      {!readOnly && <Button.Add className="mt-2" onClick={appendNewProduct} />}
    </PageSection>
  );
}
