import React, { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useFormPermissions } from '@hooks';

import Label from './Label';
import Error from './Error';

const Checkbox = ({
  name, label, value, ...rest
}) => {
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();

  return (
    <>
      <Form.Check
        type="checkbox"
        className="mt-2"
        {...register(fullName)}
        disabled={readOnly}
        checked={value}
        {...rest}
      />
      <Error name={name} />
    </>
  );
};

const LabeledCheckbox = ({
  label, sizes, inlineLabel, tooltipText, ...rest
}) => {
  const inputId = useId();

  return (
    <Label
      htmlFor={inputId}
      label={label}
      sizes={sizes}
      tooltipText={tooltipText}
      className="cursor-pointer user-select-none"
    >
      <Checkbox id={inputId} {...rest} />
    </Label>
  );
};

export { Checkbox, LabeledCheckbox };
