import React, { useState, useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { PageSection, useRouter, useFormGroup } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';
import {
  LabeledInput, LabeledSelect, LabeledSwitch, DiffWrapper, DiffProvider,
} from '@components/resource';
import { CreativesRoutes } from '@pages/routes';
import CreativeTemplateSelect from '@components/creatives/CreativeTemplateSelect';

import ContributionConfigurations from './ContributionConfigurations';
import CreativeLayout from './CreativeLayout';

export default function ConfigurationVariant({
  products,
  triggers,
  piggyTemplates,
  piggyIconTemplates,
  currentAttributes,
  defaultVariantAttributes,
  localisationKeys,
}) {
  const { query } = useRouter();
  const [piggyTemplate, setPiggyTemplate] = useState();
  const [piggyIconTemplate, setPiggyIconTemplate] = useState();
  const { watch } = useFormContext();
  const layoutTypes = useAppData().enums['Creatives::LayoutTypes'];

  const piggyTemplatesOptions = piggyTemplates.items.map((
    {
      id, internalName, availabilityState, availabilityStateLive, previewImageUrl,
    },
  ) => ({
    label: internalName, value: id, availabilityState, availabilityStateLive, previewImageUrl,
  }));

  const piggyIconTemplatesOptions = piggyIconTemplates.items.map((
    {
      id, internalName, availabilityState, availabilityStateLive, previewImageUrl,
    },
  ) => ({
    label: internalName, value: id, availabilityState, availabilityStateLive, previewImageUrl,
  }));

  const productsOptions = products.map(({ id, price }) => (
    { label: price, value: id }
  ));

  const { generateName } = useFormGroup();

  const piggyTemplateId = watch(generateName('piggyTemplateId'));
  const piggyIconTemplateId = watch(generateName('piggyIconTemplateId'));
  const piggyEnabled = watch(generateName('enabled'));

  // Piggy template layout data stuff
  const { fields: templateLayoutsDataAttributes, replace: replaceTemplateLayoutData } = useFieldArray({
    name: generateName('templateLayoutsDataAttributes'),
    keyName: 'key',
  });

  useEffect(() => {
    const templateId = piggyTemplates.items.find((config) => +config.id === +piggyTemplateId)?.id;
    if (!templateId) return;

    CreativesRoutes.Templates.editRequest({ ...query, id: templateId }).then((res) => {
      setPiggyTemplate(res.data);
    });
  }, [piggyTemplateId]);

  const resetTemplateLayoutForm = (templateData) => {
    const layoutData = [];

    templateLayoutsDataAttributes.forEach((data) => data.id && layoutData.push({ ...data, _destroy: true }));
    templateData.layout.forEach(({ type, internalName }) => {
      if (type === layoutTypes.TEXT_LOCALISED) {
        layoutData.push({
          internalName, layoutType: type, entityId: null,
        });
      }
    });

    replaceTemplateLayoutData(layoutData);
  };

  const handleTemplateLayoutChange = (val) => {
    setPiggyTemplate(null);

    CreativesRoutes.Templates.editRequest({ ...query, id: val }).then((res) => {
      resetTemplateLayoutForm(res.data);
      setPiggyTemplate(res.data);
    });
  };

  // Piggy icon template layout data stuff
  const { fields: iconTemplateLayoutsDataAttributes, replace: replaceIconTemplateLayoutData } = useFieldArray({
    name: generateName('iconTemplateLayoutsDataAttributes'),
    keyName: 'key',
  });

  useEffect(() => {
    const templateId = piggyIconTemplates.items.find((config) => +config.id === +piggyIconTemplateId)?.id;
    if (!templateId) return;

    CreativesRoutes.Templates.editRequest({ ...query, id: templateId }).then((res) => {
      setPiggyIconTemplate(res.data);
    });
  }, [piggyIconTemplateId]);

  const resetIconTemplateLayoutForm = (templateData) => {
    const layoutData = [];

    iconTemplateLayoutsDataAttributes.forEach((data) => data.id && layoutData.push({ ...data, _destroy: true }));
    templateData.layout.forEach(({ type, internalName }) => {
      if (type === layoutTypes.TEXT_LOCALISED) {
        layoutData.push({
          internalName, layoutType: type, entityId: null,
        });
      }
    });

    replaceIconTemplateLayoutData(layoutData);
  };

  const handleIconTemplateLayoutChange = (val) => {
    setPiggyIconTemplate(null);

    CreativesRoutes.Templates.editRequest({ ...query, id: val }).then((res) => {
      resetIconTemplateLayoutForm(res.data);
      setPiggyIconTemplate(res.data);
    });
  };

  return (
    <DiffProvider baseAttributes={defaultVariantAttributes} currentAttributes={currentAttributes}>
      <DiffWrapper for="enabled">
        <LabeledSwitch
          name="enabled"
          label="Serve the configuration to players"
          sizes={{
            xxl: [4, 2], xl: [5, 2], lg: [7, 2], md: [10, 2], sm: [10, 2], xs: [10, 2],
          }}
        />
      </DiffWrapper>
      {piggyEnabled && (
        <>
          <PageSection title="Size and Price">
            <DiffWrapper for="piggyMaxAmount">
              <LabeledInput label="Piggy Max Amount" name="piggyMaxAmount" type="number" step="0.01" />
            </DiffWrapper>
            <DiffWrapper for="piggyMinBreakAmount">
              <LabeledInput
                label="Piggy Min Break Amount"
                name="piggyMinBreakAmount"
                type="number"
                step="0.01"
              />
            </DiffWrapper>
            <DiffWrapper for="productId">
              <LabeledSelect
                label="Price"
                name="productId"
                options={productsOptions}
                menuPlacement="top"
              />
            </DiffWrapper>
          </PageSection>

          <ContributionConfigurations triggers={triggers} />

          <PageSection title="Content">
            <DiffWrapper for="piggyTemplateId">
              <CreativeTemplateSelect
                label="Piggy Template"
                name="piggyTemplateId"
                options={piggyTemplatesOptions}
                template={piggyTemplate}
                menuPlacement="top"
                onChange={handleTemplateLayoutChange}
              />
            </DiffWrapper>
            {piggyTemplate && (
              <CreativeLayout
                layoutDataAttributeName="templateLayoutsDataAttributes"
                layoutDataAttributes={templateLayoutsDataAttributes}
                template={piggyTemplate}
                localisationKeys={localisationKeys}
              />
            )}
            <DiffWrapper for="piggyIconTemplateId">
              <CreativeTemplateSelect
                label="Piggy Icon Template"
                name="piggyIconTemplateId"
                options={piggyIconTemplatesOptions}
                template={piggyIconTemplate}
                menuPlacement="top"
                onChange={handleIconTemplateLayoutChange}
              />
            </DiffWrapper>
            {piggyIconTemplate && (
              <CreativeLayout
                layoutDataAttributeName="iconTemplateLayoutsDataAttributes"
                layoutDataAttributes={iconTemplateLayoutsDataAttributes}
                template={piggyIconTemplate}
                localisationKeys={localisationKeys}
              />
            )}
          </PageSection>
        </>
      )}
    </DiffProvider>
  );
}
