import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

const ImageSquare = styled(({
  // eslint-disable-next-line no-unused-vars
  size = '128px',
  width,
  height,
  ...rest
}) => <Image {...rest} />)`
  height: ${({ size, height }) => height || size};
  width: ${({ size, width }) => width || size};
  object-fit: contain;
`;

export default ImageSquare;
