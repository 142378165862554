import React, { useId } from 'react';
import classnames from 'classnames';

import { useFormPermissions } from '@hooks';

const SwitchSimple = ({
  label, className, ...rest
}) => {
  const { readOnly } = useFormPermissions();
  const inputId = useId();

  return (
    <div className={classnames('form-check', 'form-switch', className)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={inputId} className="form-check-label user-select-none cursor-pointer">
        <input
          type="checkbox"
          id={inputId}
          className="form-check-input cursor-pointer"
          {...rest}
          disabled={readOnly}
        />
        {label}
      </label>
    </div>
  );
};

export default SwitchSimple;
