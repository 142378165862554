import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@tripledotstudios/react-core';

import { DuplicateIcon } from '../icons';
import BaseTransparentButton from './BaseTransparentButton';

const StyledButton = styled(BaseTransparentButton)`
  &:hover, &:focus {
    svg path {
      fill: #68d4ff;
    }
  }
`;

const DuplicateButton = ({ title = 'Duplicate', ...props }) => (
  <Tooltip placement="bottom" text={title}>
    <StyledButton {...props}>
      <DuplicateIcon />
    </StyledButton>
  </Tooltip>
);

export default DuplicateButton;
