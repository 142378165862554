const findTemplateLayoutItem = (template, internalName) => (
  template.layout.find((item) => item.internalName === internalName)
);

const CreativesTooltipForLayout = (template, internalName) => {
  const layoutItem = findTemplateLayoutItem(template, internalName);
  if (!layoutItem) return null;

  return `${layoutItem.description} (${layoutItem.type})`;
};

export default CreativesTooltipForLayout;
