import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  useForm,
  FormProvider,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';
import { isFunction, isEqual, isEmpty } from 'lodash';
import { FormPermissionsProvider } from '@hooks';
import RouteLeavingGuard from './RouteLeavingGuard';
import ResourceForm from './ResourceForm';

const Form = React.memo(({ defaultValues, enableReinitialize = false, children }) => {
  const methods = useForm({ defaultValues });
  const permissions = defaultValues?.permissions || {};

  useEffect(() => {
    if (!enableReinitialize) return;

    methods.reset(defaultValues);
  }, [JSON.stringify(defaultValues)]);

  return (
    <FormProvider {...methods}>
      <FormPermissionsProvider permissions={permissions}>
        {isFunction(children) ? children({ ...methods, permissions }) : children}
      </FormPermissionsProvider>
    </FormProvider>
  );
}, (prev, next) => (
  isEqual(prev.defaultValues, next.defaultValues) && prev.enableReinitialize === next.enableReinitialize
));

const Component = ({
  onSubmit, onDiscard, children, forceDirty = false, disableDirtyChecker = false,
}) => {
  const { handleSubmit, formState: { isSubmitting, dirtyFields } } = useFormContext();
  // RouteLeavingGuard need to see response from the handleSubmit, so we need to return Promise with response.
  // eslint-disable-next-line no-promise-executor-return
  const internalSubmit = (e) => {
    if (isSubmitting) {
      e.preventDefault();
      return Promise.resolve();
    }
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => handleSubmit((values) => onSubmit(values).then(resolve))(e));
  };

  return (
    <form id="react-hook-form" onSubmit={internalSubmit}>
      {children}
      <RouteLeavingGuard
        onDiscard={onDiscard}
        when={(forceDirty || !isEmpty(dirtyFields)) && !disableDirtyChecker && !isSubmitting}
        onSave={internalSubmit}
      />
    </form>
  );
};

function FormWhen({
  name, fullName, value, children,
}) {
  const { generateName } = useFormGroup();
  const finalName = fullName || generateName(name);
  const formValue = useWatch({ name: finalName });
  const shouldShow = typeof value === 'function' ? value(formValue) : value === formValue;

  if (!shouldShow) return null;

  return children;
}

Form.Component = Component;
Form.Resource = ResourceForm;
Form.When = FormWhen;

Form.ControlsLayout = ({ col, children, ...rest }) => (
  <Row
    xxl={4}
    xl={3}
    lg={3}
    md={2}
    sm={2}
    xs={1}
    {...rest}
  >
    {React.Children.toArray(children).map((c, index) => {
      if (c.type === Col || c.type === FormWhen) {
        return c;
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Col key={index} {...col}>
          {c}
        </Col>
      );
    })}
  </Row>
);

export default Form;
