import React from 'react';
import { Route } from 'react-router-dom';

import { CreativesRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';
import Show from './Show';
import New from './New';

const Routes = CreativesRoutes.Templates;

export default function TemplatesRoot() {
  return [
    <Route
      exact
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <>
          <PageTitle text="Creative Templates" />
          <Index />
        </>
      )}
    />,
    <Route
      exact
      key={Routes.newRawPath}
      path={Routes.newRawPath}
      element={(
        <>
          <PageTitle entity="Creative Templates: Upload" action="New" />
          <New />
        </>
      )}
    />,
    <Route
      exact
      key={Routes.editRawPath}
      path={Routes.editRawPath}
      element={(
        <>
          <PageTitle entity="Creative Templates: Show Creative" action="Show" />
          <Show />
        </>
      )}
    />,
  ];
}
