import React from 'react';
import {
  ToggleButtonGroup, ToggleButton,
} from 'react-bootstrap';

const ToggleAction = ({
  value,
  onChange,
  checkedLabel = 'Yes',
  uncheckedLabel = 'No',
  checkedValue = true,
  uncheckedValue = false,
  fieldName = 'toggle-action',
}) => (
  <ToggleButtonGroup type="radio" name={`${fieldName}-options`} value={value} onChange={onChange}>
    <ToggleButton
      id={`${fieldName}-options-1`}
      value={checkedValue}
      checked={value}
      variant={value ? 'primary' : 'outline-primary'}
    >
      {checkedLabel}
    </ToggleButton>
    <ToggleButton
      id={`${fieldName}-options-2`}
      value={uncheckedValue}
      checked={!value}
      variant={!value ? 'primary' : 'outline-primary'}
    >
      {uncheckedLabel}
    </ToggleButton>
  </ToggleButtonGroup>
);

export default ToggleAction;
