import { useCallback, useMemo } from 'react';
import { useRouter } from '@tripledotstudios/react-core';
import { LocalisationsRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';
import {
  Pagination,
  PageHeader,
  Button,
  InUse,
  Pane,
} from '@/components';
import { Table } from '@/components/collection';
import { useAppData, useQuery } from '@/hooks';

const Routes = LocalisationsRoutes.Keys;

export default function Index() {
  const { featureToggles: { localisations_server_enabled: localisationsServerEnabled } } = useAppData();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { ...query, includeInUse: true });
  const canSynchronize = hasApplicationPermissions(['localisations', 'key', 'synchronize']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      (localisationsServerEnabled ? InUse.AvailabilityStateColumn : null),
      {
        Header: 'Deleted',
        Cell: ({ row: { original: { deleted } } }) => deleted && 'yes',
      },

      InUse.InUseColumn,

      {
        Header: 'Synchronized at (UTC)',
        accessor: 'updatedAt',
      },
    ].filter(Boolean),
    [],
  );

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeLocalisationKeys = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    response && (
      <>
        <PageHeader title="Localisation Keys">
          {canSynchronize && <Button.Synchronize onClick={handleSynchronizeLocalisationKeys} />}
        </PageHeader>
        <Pane>
          <Table columns={columns} data={response.items} />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </Pane>
      </>
    )
  );
}
