import { useQuery } from '@tanstack/react-query';
import { CreativesRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';
import { hasApplicationPermissions } from '@services/permissions';

const LIST_QUERY_NAME_KEY = 'creatives/images';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function creativesImagesQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await CreativesRoutes.Images.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useImagesOptions(query = {}) {
  if (!hasApplicationPermissions(['creatives', 'image', 'list'])) {
    return new Promise((resolve) => { resolve([]); });
  }
  return useQuery(creativesImagesQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items, {
      transform: (option, { fileUrl }) => Object.assign(option, { fileUrl }),
    }),
  }));
}

export function useImagesQuery(query = {}) {
  return useQuery(creativesImagesQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
