import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { Button } from '@components';
import { ShowIcon } from '@components/icons';

export default function GameProductsList({
  response, onEditButtonClick, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Deleted',
        Cell: ({ row: { original: { deleted } } }) => deleted && 'yes',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },

      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { id, permissions } } }) => {
          if (!permissions.edit) {
            return null;
          }
          const options = !permissions.update ? { title: 'Show', icon: <ShowIcon /> } : {};

          return (
            <Button.Edit {...options} className="me-2" onClick={() => onEditButtonClick(id)} />
          );
        },
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No game products yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
