import React from 'react';
import { Container } from 'react-bootstrap';
import { isEmpty } from 'lodash';

import { Form } from '@components';
import { FloatingLabeledInput } from '@components/resource';
import SequentialOfferItems from './form/SequentialOfferItems';

export default function SequentialOffersForm(props) {
  // eslint-disable-next-line react/destructuring-assignment
  const inUse = !isEmpty(props.resource.data.inUse);

  return (
    <Form.Resource {...props}>
      <Container fluid className="form-container">
        <Form.ControlsLayout>
          <FloatingLabeledInput label="Name" name="name" />
        </Form.ControlsLayout>

        <hr />

        <SequentialOfferItems inUse={inUse} />
      </Container>
    </Form.Resource>
  );
}
