import { v4 as uuidv4 } from 'uuid';
import { sortBy } from 'lodash';

const MAX_ITERATIONS_COUNT = 10;

const recalculateRealIndexes = (replaceCreativeLayoutData, creativeLayoutsDataAttributes) => {
  const layoutData = [];
  let realIndex = 0;
  const iterations = [...new Set(creativeLayoutsDataAttributes
    .filter(({ _destroy, layoutType }) => !_destroy && layoutType === 'offer_container')
    .map(({ iteration }) => iteration)
    .sort())];

  creativeLayoutsDataAttributes.forEach(({ realIndex: _previousRealIndex, ...data }) => {
    layoutData.push({ ...data, realIndex, iteration: iterations.indexOf(data.iteration) });
    realIndex += 1;
  });

  replaceCreativeLayoutData(layoutData);
};

const fillFirstIteration = (creativeLayoutsDataAttributes, replaceCreativeLayoutData) => {
  // check if there is only one iteration
  if (!creativeLayoutsDataAttributes.find(({ iteration, _destroy }) => !_destroy && +iteration > 0)) {
    const layoutData = [];
    let realIndex = 0;
    creativeLayoutsDataAttributes.forEach(({
      id, creativePersonalOfferAttributes, _destroy, ...data
    }) => {
      layoutData.push({
        ...data, realIndex, id, _destroy, creativePersonalOfferAttributes,
      });
      realIndex += 1;
      if (!_destroy && data.layoutType === 'offer_container') {
        let personalOffer = {};
        if (creativePersonalOfferAttributes) {
          personalOffer = { ...creativePersonalOfferAttributes, id: null, key: uuidv4() };
        }
        layoutData.push({
          ...data,
          realIndex,
          key: uuidv4(),
          iteration: 1,
          creativePersonalOfferAttributes: personalOffer,
        });
        realIndex += 1;
      }
    });

    replaceCreativeLayoutData(layoutData);
  } else {
    recalculateRealIndexes(replaceCreativeLayoutData, creativeLayoutsDataAttributes);
  }
};

const removeExtraIterations = (creativeLayoutsDataAttributes, replaceCreativeLayoutData) => {
  const layoutData = [];
  creativeLayoutsDataAttributes.forEach((data) => {
    if (+data.iteration > 0) {
      if (data.id) layoutData.push({ ...data, _destroy: true });
    } else {
      layoutData.push(data);
    }
  });

  replaceCreativeLayoutData(layoutData);
};

const addIteration = (creativeLayoutsDataAttributes, replaceCreativeLayoutData) => {
  const latest = sortBy(creativeLayoutsDataAttributes,
    ({ iteration }) => -(+iteration)).find(({ _destroy }) => !_destroy,
  );
  const lastIteration = +latest.iteration;
  const newIteration = lastIteration + 1;
  if (newIteration >= MAX_ITERATIONS_COUNT) {
    return lastIteration;
  }
  const layoutData = [];
  let realIndex = 0;
  creativeLayoutsDataAttributes.forEach(({ realIndex: _previousRealIndex, ...data }) => {
    layoutData.push({ ...data, realIndex });
    realIndex += 1;

    if (+data.iteration === lastIteration && !data._destroy && data.layoutType === 'offer_container') {
      let personalOffer = {};
      if (data.creativePersonalOfferAttributes) {
        personalOffer = { ...data.creativePersonalOfferAttributes, id: null, key: uuidv4() };
      }
      layoutData.push({
        ...data,
        realIndex,
        id: null,
        key: uuidv4(),
        iteration: newIteration,
        creativePersonalOfferAttributes: personalOffer,
      });

      realIndex += 1;
    }
  });

  replaceCreativeLayoutData(layoutData);
  return newIteration;
};

const removeIteration = ({
  creativeLayoutsDataAttributes,
  replaceCreativeLayoutData,
  iterationToRemove,
}) => {
  const layoutData = [];
  creativeLayoutsDataAttributes.forEach((data) => {
    if (+data.iteration === +iterationToRemove && data.layoutType === 'offer_container') {
      if (data.id) layoutData.push({ ...data, _destroy: true });
    } else {
      layoutData.push(data);
    }
  });
  recalculateRealIndexes(replaceCreativeLayoutData, layoutData);
};

const resetIterations = (enabled, creativeLayoutsDataAttributes, replaceCreativeLayoutData) => {
  if (enabled) {
    fillFirstIteration(creativeLayoutsDataAttributes, replaceCreativeLayoutData);
  } else {
    removeExtraIterations(creativeLayoutsDataAttributes, replaceCreativeLayoutData);
  }
};

export {
  fillFirstIteration,
  removeExtraIterations,
  addIteration,
  removeIteration,
  resetIterations,
  MAX_ITERATIONS_COUNT,
};
