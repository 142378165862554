import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

import { PageSection, useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { AccountsRoutes } from '@pages/routes';

const Routes = AccountsRoutes.UserSegments;

export default function SegmentsView() {
  const { query } = useRouter();
  const requestParams = { userId: query.id, applicationId: query.applicationId };
  const { response } = useQuery(Routes.indexRequest, requestParams);
  const userSegments = response?.items;

  return userSegments ? (
    <>
      <PageSection title="Player Segments" />
      <Row>
        <Col lg={6} xs={12}>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Priority</th>
              </tr>
            </thead>
            <tbody>
              {userSegments.map((segment) => (
                <tr key={segment.id}>
                  <td>{segment.id}</td>
                  <td>{segment.name}</td>
                  <td>{segment.priority}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  ) : '';
}
