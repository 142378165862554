import React, { useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { useFormGroup } from '@tripledotstudios/react-core';
import { Label, SelectWithAddon } from '@components/resource';
import { Button } from '@components';
import { ShowIcon } from '@components/icons';
import { labelFormat as creativeImageLabelFormat } from '@pages/creatives/images/utils';

const PreviewImage = styled(Image)`
  max-height: calc(100vh - 20rem);
`;

const PreviewModal = ({ showModal, selectedImage, handleClose }) => {
  if (!selectedImage) return null;

  return (
    <Modal show={!!showModal} onHide={handleClose} dialogClassName="modal-80w">
      <Modal.Header closeButton>
        <Modal.Title>
          Preview
          {' '}
          {selectedImage.label}
          {' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PreviewImage className="img-fluid d-flex m-auto" src={selectedImage.fileUrl} />
      </Modal.Body>
    </Modal>
  );
};

export default function CreativePictureSelect({
  label: labelText, sizes, creativeImagesOptions, tooltipText,
}) {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { watch } = useFormContext();
  const { generateName } = useFormGroup();
  const selectedPictureId = watch(generateName('entityId'));
  const selectedImage = creativeImagesOptions.find(({ value }) => value === selectedPictureId);

  const handlePreview = () => selectedImage && setShowPreviewModal(true);
  return (
    <>
      <Label label={labelText} sizes={sizes} tooltipText={tooltipText}>
        <SelectWithAddon
          name="entityId"
          options={creativeImagesOptions}
          formatOptionLabel={creativeImageLabelFormat}
          after={(
            <Button.Action.Transparent
              onClick={handlePreview}
              title="Preview"
              icon={<ShowIcon color="var(--bs-body-color)" />}
            />
          )}
        />
      </Label>
      <PreviewModal
        showModal={showPreviewModal}
        handleClose={() => setShowPreviewModal(false)}
        selectedImage={selectedImage}
      />
    </>
  );
}
