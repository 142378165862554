import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Form, ImageSquare } from '@components';
import { useRouter } from '@tripledotstudios/react-core';
import { LabeledInput, Label, Input } from '@components/resource';
import { buildResourceResponseHandler } from '@services/response_handler';

export default function ProductImagesForm({
  defaultValues, request, actionName, onDiscard, onSave,
}) {
  const { query } = useRouter();

  return (
    <Form defaultValues={defaultValues} enableReinitialize>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({ actionName, setError, onSuccess: onSave });
        const onSubmit = (values) => {
          const formData = new FormData();
          formData.append('name', values.name);
          if (values.file[0]) formData.append('file', values.file[0]);
          if (values.id) formData.append('id', values.id);

          return request(formData, query).then(responseHandler);
        };

        return (
          <Row>
            <Col xs={12} md={8}>
              <Form.Component onDiscard={onDiscard} onSubmit={onSubmit}>
                <LabeledInput label="Name" name="name" />

                <Label label="File">
                  <Input type="file" name="file" multiple={false} accept="image/*" />
                </Label>
              </Form.Component>
            </Col>
            <Col xs={12} md={4}>
              {defaultValues.fileUrl && <ImageSquare size="25rem" src={defaultValues.fileUrl} />}
            </Col>
          </Row>
        );
      }}
    </Form>
  );
}
