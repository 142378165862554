import React from 'react';
import {
  Table, Accordion, Row, Col,
} from 'react-bootstrap';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import {
  PageSection, useRouter, useFlashMessages, useConfirm,
} from '@tripledotstudios/react-core';

import { Input, DateTimeInput } from '@components/resource';
import { EmptyList } from '@components/collection';
import { Form, Button } from '@components';
import { useQuery } from '@hooks';
import { AccountsRoutes, CampaignsRoutes } from '@pages/routes';
import { buildResourceResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';

const Routes = AccountsRoutes.UserCreatives;

const CreativeStatsForm = ({ creative, refetch }) => {
  const { query } = useRouter();
  const { success } = useFlashMessages();
  const confirm = useConfirm();
  const { stats } = creative;
  const writePermissions = hasApplicationPermissions(['player', 'write']);

  const requestParams = {
    applicationId: query.applicationId,
    userId: query.id,
    id: creative.id,
    variantId: creative.variantId,
    campaignId: creative.campaign.id,
  };
  const handleClearButtonClick = () => {
    confirm.showConfirmation({ body: 'Creative stats will be cleaned up.', title: 'Are you sure?' })
      .then(() => Routes.clearRequest({ ...requestParams }).then(() => {
        refetch();
        success('Operation have been finished successfully');
      }));
  };
  const prepareStats = (objects) => objects.map((obj) => (
    ['last_shown_at', 'duration_start_at'].includes(obj.key)
      ? { ...obj, value: Date.parse(`${obj.value}Z`) / 1000 }
      : obj
  ));

  const renderStatInput = (key, index) => {
    switch (key) {
      case 'last_shown_at':
      case 'duration_start_at':
        return <DateTimeInput name={`stats.${index}.value`} step="1" />;
      default:
        return <Input name={`stats.${index}.value`} />;
    }
  };

  return (
    <Form defaultValues={{ stats, permissions: { update: writePermissions } }} enableReinitialize>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({
          actionName: 'update',
          setError,
          onSuccess: refetch,
        });
        const onSubmit = (values) => Routes.updateRequest({ ...requestParams, stats: prepareStats(values.stats) })
          .then(responseHandler);

        return (
          <Form.Component onSubmit={onSubmit}>
            <Table className="table-bordered table-striped">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th>Raw Value</th>
                </tr>
              </thead>
              <tbody>
                {stats.map(({ key, title, rawValue }, index) => (
                  <tr key={key}>
                    <td>{title || startCase(key)}</td>
                    <td>
                      {renderStatInput(key, index)}
                    </td>
                    <td>{rawValue}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {writePermissions && (
              <>
                <Button.Submit />
                <Button.DeleteText onClick={handleClearButtonClick} title="Clear" />
              </>
            )}
          </Form.Component>
        );
      }}
    </Form>
  );
};

export default function ImpressionsStatsView() {
  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { userId: query.id });

  const buildCreativeUrl = (creative) => (
    CampaignsRoutes.Configurations.editPath(
      {
        applicationId: query.applicationId,
        id: creative.campaign.id,
      },
      {
        configurationSegmentId: creative.campaign.configurationSegmentId,
        creativeId: creative.id,
        variantId: creative.abVariantId,
      },
    )
  );

  return response && (
    <>
      <PageSection title="Creatives" />

      {response.items.length > 0
        ? (
          <Row>
            <Col xs={12}>
              <Accordion defaultActiveKey={response.items[0]?.id}>
                {response.items.map((creative) => (
                  <Accordion.Item eventKey={creative.id} key={creative.id}>
                    <Accordion.Header>
                      {`${creative.campaign.name} - Creative #${creative.id}`}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-2">
                        <Link
                          to={buildCreativeUrl(creative)}
                          key={`creative_${creative.id}`}
                          target="_blank"
                        >
                          Go to creative
                        </Link>
                      </div>
                      <Row>
                        <Col>
                          <CreativeStatsForm
                            creative={creative}
                            refetch={refetch}
                          />
                        </Col>
                        <Col>
                          <dl className="row">
                            <dt className="col-6">Triggers</dt>
                            <dd className="col-6">{creative.triggerNames}</dd>

                            <dt className="col-6">Impressions Cap</dt>
                            <dd className="col-6">{creative.impressionsCap}</dd>

                            <dt className="col-6">Impressions Cap per duration</dt>
                            <dd className="col-6">{creative.impressionsCapPerDuration}</dd>

                            <dt className="col-6">Trigger Cooldown</dt>
                            <dd className="col-6">{creative.triggerCooldown}</dd>

                            <dt className="col-6">Duration</dt>
                            <dd className="col-6">{creative.duration}</dd>

                            <dt className="col-6">Duration Cooldown</dt>
                            <dd className="col-6">{creative.durationCooldown}</dd>

                            <dt className="col-6">Availability Cap</dt>
                            <dd className="col-6">{creative.availabilityCap}</dd>

                            <dt className="col-6">Availability Cap per duration</dt>
                            <dd className="col-6">{creative.availabilityCapPerDuration}</dd>

                            <dt className="col-6">Next Show At</dt>
                            <dd className="col-6">{creative.nextShowAt}</dd>

                            <dt className="col-6">Duration End At</dt>
                            <dd className="col-6">{creative.durationEndAt}</dd>
                          </dl>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        )
        : <EmptyList title="No creatives to display" />}
    </>
  );
}
