import React from 'react';

import { useAppData } from '@hooks';

import { Select } from '../resource';

export default function AvailabilityStateSelect({ disabled }) {
  const availabilityStateOptions = useAppData().enumOptions.AvailabilityStates;

  return (
    <Select
      name="availabilityState"
      options={availabilityStateOptions}
      isDisabled={disabled}
    />
  );
}
