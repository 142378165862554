import React, { useEffect, useState } from 'react';
import { PageSection, FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { Card, InputGroup } from 'react-bootstrap';
import {
  useExtendedFieldArray, useFormPermissions, useCurrentApplication, useAppData,
} from '@hooks';
import {
  Input, Label, SelectWithAddon, Select, RegisteredError,
} from '@components/resource';
import { InUse, Button } from '@/components';

export default function PlayerCards({ eventsConfigurationsOptions }) {
  const { playerCardsEnabled, itemRarities } = useCurrentApplication();
  const { generateName } = useFormGroup();
  const [itemRaritiesOptions, setItemRaritiesOptions] = useState({});
  const [localEventsConfigOptions, setLocalEventsConfigOptions] = useState({});
  const { readOnly } = useFormPermissions();
  const { enumOptions, i18n: { tooltips } } = useAppData();
  const playerCardsOperatorOptions = enumOptions['Campaigns::PlayerCardsOperators'];
  const playerCardsFromOptions = enumOptions['Campaigns::PlayerCardsFrom'];
  const playerCardsAsOptions = enumOptions['Campaigns::PlayerCardsAs'];

  const playerCardsFormGroupName = generateName('playerCardsAttributes');
  const { fields, append, handleDelete } = useExtendedFieldArray({ name: playerCardsFormGroupName, keyName: 'uiKey' });
  const allItemRaritiesOptions = [
    { label: 'Any', value: 0 },
    ...Array.from({ length: itemRarities }, (_, i) => ({ label: i + 1, value: i + 1 })),
  ];

  const handleRaritiesChange = (index, values) => {
    const newOptions = itemRaritiesOptions;
    if (values.indexOf(0) > -1) {
      newOptions[index] = allItemRaritiesOptions.slice(0, 1);
    } else if (values.length > 0) {
      newOptions[index] = allItemRaritiesOptions.slice(1, allItemRaritiesOptions.length);
    } else {
      newOptions[index] = allItemRaritiesOptions;
    }
    setItemRaritiesOptions(newOptions);
  };

  const handleEventsOptions = () => {
    const newOptions = {};
    fields.forEach((playerCard, index) => {
      newOptions[index] = eventsConfigurationsOptions.filter(
        (e) => e.availableNow || e.value === playerCard.eventsConfigurationId,
      );
    });
    setLocalEventsConfigOptions(newOptions);
  };

  useEffect(() => {
    fields.map((playerCard, index) => handleRaritiesChange(index, playerCard.rarities || []));
    handleEventsOptions(fields);
  }, [fields.length]);

  return playerCardsEnabled && (
    <PageSection title="Player Cards">
      <div className="flex-fill w-25 mb-2">
        <Select name="playerCardsOperator" options={playerCardsOperatorOptions} />
      </div>
      {fields.filter(({ _destroy }) => !_destroy).length > 0
        && fields.map((playerCard, index) => playerCard._destroy || (
        // eslint-disable-next-line react/no-array-index-key
        <FormGroup key={`${playerCard.uiKey}.${index}`} name={`playerCardsAttributes.${index}`}>
          <div className="d-flex mb-1">
            <div className="flex-fill w-100 me-1">
              <Card className="p-3 mb-2">
                <Label
                  tooltipText={tooltips.campaigns.configurations.player_cards.event_hint}
                  sizes={[3, 9]}
                  label="Player Cards"
                >
                  <SelectWithAddon
                    before="from"
                    name="eventsConfigurationId"
                    options={localEventsConfigOptions[index] || []}
                    formatOptionLabel={InUse.AvailabilityStateStatusOptionLabelFormat}
                  />
                </Label>
                <Label sizes={[3, 9]} label="Send campaign to the players" />
                <InputGroup>
                  <InputGroup.Text>who have</InputGroup.Text>
                  <Select name="valueFrom" options={playerCardsFromOptions} />
                  <Input name="value" />
                  <Select name="valueAs" options={playerCardsAsOptions} />
                  <InputGroup.Text>of cards with rarity</InputGroup.Text>
                  <Select
                    name="rarities"
                    options={itemRaritiesOptions[index] || []}
                    onChange={(val) => handleRaritiesChange(index, val)}
                    isMulti
                  />
                </InputGroup>
                <RegisteredError name="base" />
              </Card>
            </div>
            {readOnly || (
            <div className="flex-fill mt-1">
              <Button.Delete title="Remove" onClick={() => handleDelete(playerCard, index)} />
            </div>
            )}
          </div>
        </FormGroup>
        ))}
      {!readOnly && <Button.Add title="Add rule" onClick={() => append({})} />}
    </PageSection>
  );
}
