import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useFormPermissions } from '@hooks';
import Label from './Label';
import Error from './Error';

const Textarea = ({
  name, style, ...rest
}) => {
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const { readOnly } = useFormPermissions();

  return (
    <>
      <Form.Control
        as="textarea"
        style={style}
        disabled={readOnly}
        {...register(fullName)}
        {...rest}
      />
      <Error name={name} />
    </>
  );
};

const LabeledTextarea = ({
  label, sizes, tooltipText, ...rest
}) => (
  <Label label={label} sizes={sizes} tooltipText={tooltipText}>
    <Textarea {...rest} />
  </Label>
);

export { Textarea, LabeledTextarea };
