import { hasApplicationPermissions } from '@services/permissions';
import { useQuery } from '@tanstack/react-query';

import { EventsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'events/activity_sets';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function activitySetsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await EventsRoutes.ActivitySets.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventsActivitySetsOptions(typeId, query = {}) {
  if (!hasApplicationPermissions(['events', 'activity_set', 'list'])) {
    return new Promise((resolve) => { resolve([]); });
  }

  return useQuery(activitySetsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
      filter: { typeIdEq: typeId },
    },
    transform: (data) => toOptions(data.items),
    enabled: !!typeId,
  }));
}

export function useActivitySets(query = {}) {
  return useQuery(activitySetsQuery({ params: { ...query, includeInUse: true, withoutPagination: true } }));
}
