import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import { useLocationQuery } from '@hooks';

import CreativesList from './CreativesList';
import PresetConfig from './PresetConfig';

export default function ConfigurationTabs({
  presets,
  triggers,
  eventsConfigurationsOptions,
  sequentialOffersOptions,
  creativeImagesOptions,
  creativeTemplates,
  segmentId,
}) {
  const [activeTab, setActiveTab] = useState('campaings_creatives');
  const params = useLocationQuery();

  useEffect(() => {
    if (params.get('creativeId')) {
      setActiveTab('campaings_creatives');
    }
  }, [params.get('creativeId')]);

  return (
    <Tabs
      transition={false}
      activeKey={activeTab}
      onSelect={setActiveTab}
      className="mb-3"
    >
      <Tab eventKey="campaings_creatives" title="Campaign Creatives">
        <CreativesList
          triggers={triggers}
          eventsConfigurationsOptions={eventsConfigurationsOptions}
          sequentialOffersOptions={sequentialOffersOptions}
          creativeImagesOptions={creativeImagesOptions}
          creativeTemplates={creativeTemplates}
          segmentId={segmentId}
        />
      </Tab>
      <Tab eventKey="shop" title="Shop">
        <PresetConfig presets={presets} />
      </Tab>
    </Tabs>
  );
}
