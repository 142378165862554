import React from 'react';
import { Dropdown, DropdownButton, Badge } from 'react-bootstrap';

import { useRouter } from '@tripledotstudios/react-core';
import { ABTestingRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';

const Routes = ABTestingRoutes.Experiments;

export default function ExperimentsDropdown({
  entityType, entityId, experiments, onSuccess,
}) {
  const { query } = useRouter();

  if (!entityId) return null;

  const responseHandler = buildSimpleResponseHandler({ onSuccess });

  const handleAddExperiment = ({ id }) => {
    Routes.addEntityRequest({
      id, entityType, entityId, applicationId: query.applicationId,
    }).then(responseHandler);
  };

  return experiments && (
    <DropdownButton title="Add to experiment">
      {experiments.map((experiment) => (
        <Dropdown.Item key={experiment.id} className="d-flex" onClick={() => handleAddExperiment(experiment)}>
          <div className="flex-grow-1">
            {experiment.name}
          </div>
          <Badge className="float-end ms-1" bg={experiment.availabilityStateLive ? 'success' : 'info'}>
            {experiment.availabilityStateHumanize}
          </Badge>
        </Dropdown.Item>
      ))}
      {!experiments.length && (
        <Dropdown.Item key={0} onClick={(e) => e.preventDefault()}>No available options</Dropdown.Item>
      )}
    </DropdownButton>
  );
}
