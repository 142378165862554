import { matchPath } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';
import { ApplicationsRoutes } from '@pages/routes';

import { useAppData } from './use_app_data';

const useCurrentApplication = () => {
  const { applications } = useAppData();
  const { pathname } = useRouter();

  const isApplicationPage = [
    ApplicationsRoutes.indexRawPath,
    ApplicationsRoutes.newRawPath,
    ApplicationsRoutes.editRawPath,
  ].some((path) => matchPath(pathname, path));

  if (isApplicationPage) return null;

  const match = matchPath('/admin/applications/:applicationId/*', pathname);

  return match && (applications || []).find((a) => a.id === parseInt(match.params.applicationId, 10));
};

export default useCurrentApplication;
