import React, { useEffect, useState } from 'react';
import { isFunction, every } from 'lodash';
import { useRouter, updateQuery } from '@tripledotstudios/react-core';

const withDefaultQuery = (Component, defaultQuery) => (props) => {
  const router = useRouter();
  const defaults = isFunction(defaultQuery) ? defaultQuery() : defaultQuery;
  const [queryUpdateStarted, setQueryUpdateStarted] = useState(false);
  const [queryResolved, setQueryResolved] = useState(every(defaults, (_v, k) => Object.hasOwn(router.query, k)));
  useEffect(() => {
    if (queryResolved) return;

    updateQuery(router, defaults);
    setQueryUpdateStarted(true);
  }, []);
  useEffect(() => {
    if (queryUpdateStarted) setQueryResolved(true);
  }, [router.query]);

  return queryResolved && <Component {...props} defaultQuery={defaults} />;
};

export default withDefaultQuery;
