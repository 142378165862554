import React from 'react';
import { Route } from 'react-router-dom';

import { AssetBundlesRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';

const Routes = AssetBundlesRoutes;

export default function AssetBundlesRoot() {
  return [
    <Route
      exact
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <>
          <PageTitle text="Asset Bundles" />
          <Index />
        </>
      )}
    />,
  ];
}
