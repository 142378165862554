import React from 'react';

import { useAppData } from '@hooks';

import Label from '../resource/Label';
import InLiveBadge from './InLiveBadge';

export default function InLiveFormGroup({ inLive, sizes }) {
  const availabilityStateTooltip = useAppData().i18n.tooltips.availability_state;

  return (
    <Label label="Availability" tooltipText={availabilityStateTooltip} sizes={sizes}>
      <InLiveBadge inLive={inLive} />
    </Label>
  );
}
