import React, { createContext, useContext, useMemo } from 'react';
import { useFormGroup } from '@tripledotstudios/react-core';
import buildDiff from '@services/build_diff';

const DiffProviderContext = createContext();

export function useDiff() {
  return useContext(DiffProviderContext);
}

export default function DiffProvider({ children, baseAttributes, currentAttributes }) {
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const { generateName } = useFormGroup();
  const value = useMemo(
    () => ({
      diff: buildDiff(currentAttributes, baseAttributes), fieldNameBase: generateName(''),
    }),
    [JSON.stringify(currentAttributes), JSON.stringify(baseAttributes)],
  );

  return (
    <DiffProviderContext.Provider value={value}>
      {children}
    </DiffProviderContext.Provider>
  );
}
