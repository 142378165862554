import { Sidebar } from '@tripledotstudios/react-core';

import Brand from './Brand';
import AuthorizedLinkItem from './AuthorizedLinkItem';
import AuthorizedSectionItem from './AuthorizedSectionItem';
import ApplicationSelect from './ApplicationSelect';
import UserSectionItem from './UserSectionItem';

const ModifiedSidebar = {
  Brand,
  AuthorizedLinkItem,
  AuthorizedSectionItem,
  ApplicationSelect,
  UserSectionItem,
};

export default Object.assign(Sidebar, ModifiedSidebar);
