import {
  PageSection,
  useFormGroup,
  FormGroup,
} from '@tripledotstudios/react-core';
import { useWatch } from 'react-hook-form';

import { useExtendedFieldArray, useMoveArrayFieldPosition, useFormPermissions } from '@hooks';
import { Button } from '@components';
import {
  Label,
  Input,
  PositionControl,
  RegisteredError,
} from '@components/resource';
import Rewards from '@components/events/Rewards';

function RankTitle({ itemPath }) {
  const position = useWatch({ name: `${itemPath}.position` });

  return `Rank ${position}`;
}

export default function Ranks({ gameItemsOptions }) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const formGroupName = generateName('ranksAttributes');

  const {
    fields,
    append,
    swap,
    handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const {
    sortedFields,
    increasePosition,
    decreasePosition,
    move,
  } = useMoveArrayFieldPosition({ fields, swap, formGroupName });

  const appendRank = () => {
    append({
      position: increasePosition(),
      rewardsAttributes: [],
    });
  };

  const deleteRank = (field, index) => {
    decreasePosition(field);

    handleDelete(field, index);
  };

  return (
    <>
      <Label label={<span className="h4">Ranks</span>} className="mt-3" />
      <RegisteredError name="base" />
      {sortedFields.map((field, index) => {
        if (field._destroy) {
          return null;
        }

        const itemPath = `${formGroupName}.${index}`;

        return (
          <FormGroup key={[field.key, field.position].join('_')} name={itemPath}>
            <PageSection
              title={<RankTitle itemPath={itemPath} />}
              actions={!readOnly && (
                <div className="grid align-items-center gap-0" style={{ '--bs-columns': 2 }}>
                  <PositionControl
                    onUp={() => move('up', field.key)}
                    onDown={() => move('down', field.key)}
                    disableUp={field.position === 1}
                    disableDown={field.position === fields.length}
                  />
                  <Button.Delete
                    className="d-block align-self-center"
                    onClick={() => deleteRank(field, index)}
                  />
                </div>
              )}
            >
              <RegisteredError name="base" />
              <Input hidden name="position" readOnly disabled type="number" />
              <Rewards
                label="Rewards"
                formGroupName="rewardsAttributes"
                gameItemsOptions={gameItemsOptions}
              />
            </PageSection>
          </FormGroup>
        );
      })}
      {!readOnly ? <Button.Add title="Add Rank" className="mt-2" onClick={appendRank} /> : null}
    </>
  );
}
