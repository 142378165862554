import React from 'react';
import { some } from 'lodash';
import { Sidebar } from '@tripledotstudios/react-core';

import { hasApplicationPermissions, hasPermissions } from '@services/permissions';

export default function AuthorizedSectionItem({ children, ...rest }) {
  const hasAnyPermissions = some(React.Children.toArray(children), (nav) => {
    if (!nav?.props?.permissionsPath) return true;

    const permissionScopeMethod = nav.props.applicationScoped === false ? hasPermissions : hasApplicationPermissions;

    return permissionScopeMethod(nav.props.permissionsPath);
  });

  return hasAnyPermissions
    ? (
      <Sidebar.SectionItem {...rest}>
        {children}
      </Sidebar.SectionItem>
    ) : '';
}
