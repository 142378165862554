import React, { useEffect } from 'react';

import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useRouter, useFlashMessages } from '@tripledotstudios/react-core';
import { isFunction, isEmpty } from 'lodash';
import { FormPermissionsProvider } from '@hooks';
import { buildFormResponseHandler } from '@services/response_handler';
import RouteLeavingGuard from './RouteLeavingGuard';

const FormComponent = ({
  onSuccess, onDiscard, request, beforeSubmit, children, forceDirty = false, disableDirtyChecker = false,
}) => {
  const formMethods = useFormContext();
  const { handleSubmit: handleFormSubmit, setError, formState: { isSubmitting, dirtyFields } } = formMethods;
  const flashMessages = useFlashMessages();
  const { query } = useRouter();

  const onSubmit = (values) => {
    const responseHandler = buildFormResponseHandler({ flashMessages, setError, onSuccess });
    if (isFunction(beforeSubmit)) {
      // eslint-disable-next-line no-param-reassign
      values = beforeSubmit(values, setError);
      if (values instanceof FormData) {
        return request(values, query).then(responseHandler);
      }
      if (!values) return Promise.resolve();
    }
    return request({ ...query, ...values }).then(responseHandler);
  };

  // RouteLeavingGuard need to see response from the handleSubmit, so we need to return Promise with response.
  const handleSubmit = (e) => {
    if (isSubmitting) {
      e.preventDefault();
      return Promise.resolve();
    }
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => handleFormSubmit((values) => onSubmit(values).then(resolve))(e));
  };

  return (
    <form id="react-hook-form" onSubmit={handleSubmit}>
      {isFunction(children) ? children({ ...formMethods }) : children}
      <RouteLeavingGuard
        onDiscard={onDiscard}
        when={(forceDirty || !isEmpty(dirtyFields)) && !disableDirtyChecker && !isSubmitting}
        onSave={handleSubmit}
      />
    </form>
  );
};

// TODO: Need to use <Form
// <Form>
//   <FormComponent request={request} onDiscard={onDiscard} onSuccess={onSuccess}>
//     {children}
//   </FormComponent>
// </Form>
const ResourceForm = ({
  resource: { data: defaultValues, request }, beforeSubmit, onSuccess, onDiscard, enableReinitialize = true, children,
}) => {
  const methods = useForm({ defaultValues });
  const permissions = defaultValues?.permissions || {};

  useEffect(() => {
    if (!enableReinitialize) return;

    methods.reset(defaultValues);
  }, [JSON.stringify(defaultValues), enableReinitialize]);

  return (
    <FormProvider {...methods}>
      <FormPermissionsProvider permissions={permissions}>
        <FormComponent request={request} onDiscard={onDiscard} beforeSubmit={beforeSubmit} onSuccess={onSuccess}>
          {isFunction(children) ? children({ ...methods, permissions }) : children}
        </FormComponent>
      </FormPermissionsProvider>
    </FormProvider>
  );
};

export default ResourceForm;
