import React from 'react';
import { faBullhorn, faLock } from '@fortawesome/free-solid-svg-icons';
import { VerticalTabs, useRequireAuth } from '@tripledotstudios/react-core';

import { PageHeader } from '@components';

import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const COLLECTION = [
  {
    name: 'Public API',
    icon: faBullhorn,
    content: <SwaggerUI url="/api-docs/public.yml" />,
  },
  {
    name: 'Internal API',
    icon: faLock,
    content: <SwaggerUI url="/api-docs/internal.yml" />,
  },
];

export default function ApiDocumentation() {
  useRequireAuth();

  return (
    <>
      <PageHeader title="API Documentation" />
      <VerticalTabs collection={COLLECTION} pillsWidth={2} />
    </>
  );
}
