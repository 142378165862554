import { each, merge } from 'lodash';

const buildVariantDiff = (currentAttributes, defaultVariantAttributes) => {
  const diff = {};
  if (!defaultVariantAttributes) return diff;

  const markAsChanged = (sectionIdx, index, key) => merge(diff, { [sectionIdx]: { [index]: { [key]: true } } });

  each(currentAttributes, ({ catalogProductsAttributes: prodAttributes }, sectionIdx) => {
    const defaultProducts = defaultVariantAttributes?.[sectionIdx]?.catalogProductsAttributes;

    if (defaultProducts) {
      if (prodAttributes?.length !== defaultProducts?.length && !diff[sectionIdx]) {
        diff[sectionIdx] = {};
      }

      each(prodAttributes, (product, index) => {
        const defaultProduct = defaultProducts[index];

        if (defaultProduct) {
          each(product, (value, key) => {
            if (key !== 'id' && !((isNaN(value) && isNaN(defaultProduct[key])) || value === defaultProduct[key])) {
              markAsChanged(sectionIdx, index, key);
            }
          });
        } else {
          merge(diff, { [sectionIdx]: { [index]: { isNew: true } } });
        }
      });
    }
  });

  return diff;
};

export default buildVariantDiff;
