import React from 'react';

import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { useQuery } from '@hooks';
import { PageHeader } from '@components';

import { CreativesRoutes } from '@pages/routes';
import Form from './Form';

const Routes = CreativesRoutes.Templates;

export default function Show() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.editRequest, query);

  return (
    <>
      <PageHeader title="Show Template" />
      {response && (
        <Form
          defaultValues={response}
          actionName="update"
          refetch={refetch}
        />
      )}
    </>
  );
}
