import React from 'react';

import { Form, FormContainer } from '@components';
import { LabeledInput } from '@components/resource';

export default function SectionsForm(props) {
  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />
        <LabeledInput label="Key" name="key" />
        <LabeledInput label="Min number of products per platform" name="minProducts" />
        <LabeledInput label="Max number of products per platform" name="maxProducts" />
      </FormContainer>
    </Form.Resource>
  );
}
