import { useWatch } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';

import { Button, InUse } from '@/components';
import { Label, LabeledSelect, Select } from '@/components/resource';
import { useAppData } from '@/hooks';

export default function ObjectiveConfigurationSelect({
  objectiveConfigurationsOptions,
  objectiveConfigurationTypesOptions,
  handleDelete,
}) {
  const { i18n: { tooltips } } = useAppData();
  const { generateName } = useFormGroup();
  const configurationType = useWatch({ name: generateName('objectiveConfigurationType') });

  return (
    <>
      <Label label="Activity type" sizes={[4, 8]}>
        <div className="d-flex">
          <div className="flex-fill">
            <Select
              name="objectiveConfigurationType"
              options={objectiveConfigurationTypesOptions}
            />
          </div>
          {handleDelete && <Button.Delete title="Remove Segment" className="mt-1" onClick={handleDelete} />}
        </div>
      </Label>
      {configurationType && (
        <LabeledSelect
          label="Activity Configuration"
          sizes={[4, 8]}
          tooltipText={tooltips.events.configurations.configuration_objective.hint}
          name="objectiveConfigurationId"
          formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
          options={objectiveConfigurationsOptions[configurationType] || []}
        />
      )}
    </>
  );
}
