import React from 'react';
import styled from 'styled-components';

import { useAppData } from '@hooks';
import UtcClock from './UtcClock';

const StyledEnvironmentInfo = styled.div`
  text-align: center;
  width: 100%;
  height: 1.5rem;
  text-transform: uppercase;
  color: #ffffff !important;

  &.development {
    background: #303c54 !important;
  }
  &.feature {
    background: #f79032 !important;
  }
  &.staging {
    background: #2ebba1 !important;
  }
  &.production {
    background: #f73434 !important;
  }
`;

const StyledClock = styled.span`
  float: right;
  margin-right: 1em;
`;

export default function Topbar() {
  const { environment } = useAppData();

  return (
    <StyledEnvironmentInfo className={environment}>
      {environment}
      &nbsp;environment
      <StyledClock>
        UTC TIME:&nbsp;
        <UtcClock />
      </StyledClock>
    </StyledEnvironmentInfo>
  );
}
