import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PlusIcon } from '../icons';

const StyledButton = styled((props) => <Button variant="success" as={Link} {...props} />)`
  color: white;
  &:hover, &:focus {
    color: white;
  }
`;

const AddLink = ({ title = 'Add', ...props }) => (
  <StyledButton {...props}>
    <PlusIcon />
    {' '}
    {title}
  </StyledButton>
);

export default AddLink;
