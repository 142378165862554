import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${({ size }) => size}rem;
  color: #dc3545;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: ${({ size }) => size - 1}rem;
    color: white;
    position: absolute;
  }
`;

const UploadedErrorIcon = ({ size = 1.2 }) => (
  <StyledIcon size={size} className="bi bi-cloud-fill">
    <i className="bi bi-x" />
  </StyledIcon>
);

export default memo(UploadedErrorIcon);
