import React from 'react';

import { Button, ButtonGroup } from 'react-bootstrap';

import { useRouter, updateQuery } from '@tripledotstudios/react-core';
import { useAppData } from '@hooks';

export default function Filters() {
  const router = useRouter();
  const assetBundleTypes = useAppData().enumOptions.AssetBundleTypes;
  const filterTypes = [
    { label: 'All', value: '' },
    ...assetBundleTypes,
  ];
  const deletedTypes = [{ label: 'All', value: '' }, { label: 'Available', value: 'false' }];

  const onFilterButtonClick = (filter) => updateQuery(router, filter);
  const isActiveFilter = (filterName, value) => (
    router.query[filterName] ? router.query[filterName] === value : !value
  );

  return (
    <>
      <ButtonGroup className="me-1">
        {filterTypes.map(({ label, value }) => (
          <Button
            key={value}
            variant={isActiveFilter('type', value) ? 'secondary' : 'outline-secondary'}
            onClick={() => onFilterButtonClick({ type: value })}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup className="me-1">
        {deletedTypes.map(({ label, value }) => (
          <Button
            key={value}
            variant={isActiveFilter('deleted', value) ? 'secondary' : 'outline-secondary'}
            onClick={() => onFilterButtonClick({ deleted: value })}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
}
