const renderPlainAlert = ({ message, timeout = 3000 }) => {
  const alert = document.createElement('div');

  alert.innerHTML = `
    <div class="alert-fixed" id="plain-alert">
      <div class="alert alert-danger alert-dismissible fade show shadow-lg" role="alert">
        ${message}
        <button type="button" class="btn-close" onclick="document.getElementById('plain-alert').remove()"
aria-label="Close"></button>
      </div>
    </div>`;

  document.body.appendChild(alert);

  setTimeout(() => alert.remove(), timeout);
};

export default renderPlainAlert;
