import React from 'react';

import { Form, FormContainer } from '@components';
import { useRouter } from '@tripledotstudios/react-core';
import { LabeledInput } from '@components/resource';

import { AppLinksRoutes } from '@pages/routes';

import { useQuery } from '@hooks';

import ConfigurationParameters from './form/ConfigurationParameters';

export default function ConfigurationsForm(props) {
  const { query } = useRouter();

  const { response: actions } = useQuery(
    AppLinksRoutes.Actions.indexRequest,
    { ...query, withoutPagination: true },
  );

  if (!actions) {
    return null;
  }

  return (
    <Form.Resource {...props}>
      <FormContainer>
        <LabeledInput label="Name" name="name" />

        <ConfigurationParameters actions={actions} />
      </FormContainer>
    </Form.Resource>
  );
}
