import React, { useMemo } from 'react';
import { Table, Status, EmptyList } from '@components/collection';
import { Button, InUse } from '@components';
import { hasApplicationPermissions } from '@services/permissions';

const ExperimentsList = ({
  response, onEditButtonClick, onDuplicateButtonClick, routes, onDelete, audiences, statuses, selectedResourceId,
}) => {
  const canCreate = hasApplicationPermissions(['ab_testing', 'experiment', 'write']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Priority',
        accessor: 'priority',
      },
      {
        Header: 'Status',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { status } } }) => <Status value={statuses[status]} />,
      },

      InUse.AvailabilityStateColumn,

      {
        Header: 'Start at (UTC)',
        accessor: 'startAt',
      },
      {
        Header: 'End at (UTC)',
        accessor: 'endAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { endAt } } }) => (
          <span>
            {endAt || 'Not specified'}
          </span>
        ),
      },
      {
        Header: 'Audience',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { audienceType } } }) => <span>{audiences[audienceType]}</span>,
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, priority, draft, permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {canCreate && (
              <Button.Duplicate
                className="me-2"
                onClick={() => onDuplicateButtonClick({ id, name, priority })}
              />
            )}
            {permissions.destroy && draft && (
              <Button.DeleteConfirm id={id} routes={routes} onDelete={onDelete} recordName={`Experiment "${name}"`} />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No experiments yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
};

export default ExperimentsList;
