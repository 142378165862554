import { classic } from '@tripledotstudios/react-core';

export default Object.freeze({
  ...classic,
  variants: {
    primary: '#3b5fad',
  },
  components: {
    sidebar: {
      background: '#303c54',
      title: classic.variants.light,
      separator: `${classic.variants.light}30`,
      colorfulHover: false,
      minimizeButtonTop: '2.6em',
      activeSectionOpacity: 0.2,
    },
  },
});
