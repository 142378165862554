import React from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';

import EditButton from './EditButton';

const EditLink = ({ routes, id, ...props }) => {
  const router = useRouter();

  return (
    <EditButton as={Link} to={routes.editPath({ id, ...router.query })} {...props} />
  );
};

export default EditLink;
