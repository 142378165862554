import React from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';

const CreativeCard = styled(Card).attrs({
  cardWidth: '20rem',
  cardPadding: '1rem',
  cardMargin: '0.5rem',
})`
  margin: ${({ cardMargin }) => cardMargin};
  width: ${({ cardWidth }) => cardWidth};
  min-width: ${({ cardWidth }) => cardWidth};

  .card-body {
    padding: ${({ cardPadding }) => cardPadding};
  }
`;

const TemplateCards = ({
  items, itemFooter, ...rest
}) => (
  items && (
    <div className="d-flex flex-wrap align-items-start">
      {items.map((item) => (
        <CreativeCard key={item.id} {...rest}>
          <Card.Img variant="top" src={item.previewImageUrl} />
          <Card.Body>
            <Card.Title>{item.internalName}</Card.Title>
            <Card.Text>
              {itemFooter(item)}
            </Card.Text>
          </Card.Body>
        </CreativeCard>
      ))}
    </div>
  )
);

export { CreativeCard };
export default TemplateCards;
