import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRequireAuth } from '@tripledotstudios/react-core';

import { DebugRoutes } from '@pages/routes';
import { PageHeader, Form, Button } from '@components';
import { LabeledTextarea, LabeledSelect } from '@components/resource';
import { useAppData } from '@hooks';
import { buildResourceResponseHandler } from '@services/response_handler';

const FormComponent = ({ applications, setError }) => {
  const [jwt, setJwt] = useState('');

  const responseHandler = buildResourceResponseHandler({
    actionName: 'generate',
    setError,
    onSuccess: (response) => setJwt(response.data.jwt),
  });

  const onSubmit = (values) => DebugRoutes.generateJwtRequest(values).then(responseHandler);

  return (
    <Form.Component disableDirtyChecker onSubmit={onSubmit}>
      <LabeledSelect label="Application" name="applicationId" options={applications} />
      <LabeledTextarea label="Payload" name="payload" rows={10} />

      {jwt && (
        <Row>
          <Col xs={3}>
            <b>JWT</b>
          </Col>
          <Col xs={9} className="mb-1">
            <code>
              {jwt}
              <Button.Copy value={jwt} />
            </code>
          </Col>
        </Row>
      )}

      <Button.Submit title="Generate" />
    </Form.Component>
  );
};

export default function Generator() {
  const { applications } = useAppData();
  const applicationsOptions = applications.map(({ id, name }) => ({ label: name, value: id }));

  useRequireAuth();

  return applications ? (
    <>
      <PageHeader title="Attributes" />
      <Form defaultValues={{}} enableReinitialize>
        {({ setError }) => <FormComponent applications={applicationsOptions} setError={setError} />}
      </Form>
    </>
  ) : null;
}
