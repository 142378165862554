import AddButton from './AddButton';
import AddLink from './AddLink';
import DeleteButton from './DeleteButton';
import DeleteButtonConfirm from './DeleteButtonConfirm';
import DeleteButtonText from './DeleteButtonText';
import EditButton from './EditButton';
import EditLink from './EditLink';
import DuplicateButton from './DuplicateButton';
import SynchronizeButton from './SynchronizeButton';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import CancelLink from './CancelLink';
import CopyButton from './CopyButton';
import ConfirmButton from './ConfirmButton';
import WarningConfirmButton from './WarningConfirmButton';
import ActionButton from './ActionButton';
import ChainLinkButton from './ChainLinkButton';

const Button = {
  Add: AddButton,
  AddLink,
  Action: ActionButton,
  ActionChainLink: ChainLinkButton,
  Delete: DeleteButton,
  DeleteConfirm: DeleteButtonConfirm,
  DeleteText: DeleteButtonText,
  Edit: EditButton,
  EditLink,
  Duplicate: DuplicateButton,
  Synchronize: SynchronizeButton,
  Submit: SubmitButton,
  Cancel: CancelButton,
  CancelLink,
  Copy: CopyButton,
  Confirm: ConfirmButton,
  WarningConfirm: WarningConfirmButton,
};

export default Button;
