import { Badge } from 'react-bootstrap';
import { Status } from '@components/collection';

export default function AvailabilityStateStatusOptionLabelFormat({
  label,
  status,
  availabilityStateLive,
  availabilityState,
}) {
  return (
    <div>
      {label}
      <Badge className="ms-1" bg={availabilityStateLive ? 'success' : 'info'}>
        {availabilityState}
      </Badge>
      <Status value={status} className="ms-1" sizeSmall />
    </div>
  );
}
