import { isPlainObject, isArray } from 'lodash';

const flattenErrors = (obj, root = null) => (
  Object.keys(obj).reduce((memo, k) => {
    const prefix = root ? `${root}.` : '';

    if (isPlainObject(obj[k]) || (isArray(obj[k]) && !obj[k].every((i) => (typeof i === 'string')))) {
      Object.assign(memo, flattenErrors(obj[k], prefix + k));
    } else {
      /* eslint-disable no-param-reassign */
      memo[prefix + k] = obj[k];
    }

    return memo;
  }, {})
);

export default flattenErrors;
