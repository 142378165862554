import React from 'react';
import JSONPretty from 'react-json-pretty';

const jsonPrettyTheme = {
  main: 'line-height:1.3;color:#212529;overflow:auto;',
  error: 'line-height:1.3;color:#212529;overflow:auto;',
  key: 'color:#0027ff;',
  string: 'color:#fd971f;',
  value: 'color:#d36004;',
  boolean: 'color:#ac81fe;',
};

export default function PrettyJsonDisplay({ data, id }) {
  return (
    <JSONPretty theme={jsonPrettyTheme} id={id || 'preview'} data={data} />
  );
}
