import React, { useState, useCallback } from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useCrudlRequests } from '@hooks';
import { PaymentsRoutes } from '@pages/routes';
import { PageHeader, SplitPane, Button } from '@components';
import { ButtonsFooter } from '@components/collection';
import { buildSimpleResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';

import List from './List';
import Form from './Form';

const Routes = PaymentsRoutes.GameItems;

export default function GameItemsIndex() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { withoutPagination: true });
  const [resource, setResource] = useState(null);
  const canWrite = hasApplicationPermissions(['payments', 'game_item', 'write']);
  const canSynchronize = hasApplicationPermissions(['payments', 'game_item', 'synchronize']);

  const {
    handleEdit, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch);

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeItems = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    <>
      <PageHeader title="Game Items">
        {canSynchronize && <Button.Synchronize onClick={handleSynchronizeItems} />}
      </PageHeader>
      <SplitPane paneName="game_items">
        <SplitPane.Top>
          <List
            response={response}
            refetch={refetch}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
          />
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ title: canWrite ? 'Cancel' : 'Back', onClick: handleOnCancel }}
            submitOptions={{ disabled: !canWrite }}
          />
        )}
      </SplitPane>
    </>
  );
}
