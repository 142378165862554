import React from 'react';
import { Table } from 'react-bootstrap';
import { FormGroup, useFormGroup, PageSection } from '@tripledotstudios/react-core';

import { useExtendedFieldArray } from '@hooks';
import { Input } from '@components/resource';
import Button from '@components/buttons';

export default function RequiredParameters({ actionName }) {
  const { generateName } = useFormGroup();
  const formGroupName = generateName('requiredParametersAttributes');
  const readOnly = actionName === 'update';

  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'uiKey',
  });

  return (
    <PageSection title="Required Parameters">
      <Table size="sm">
        <thead>
          <tr>
            <th>Key</th>
            <th>Predefined Value</th>
            {!readOnly && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {fields.map((parameter, index) => parameter._destroy || (
            // eslint-disable-next-line react/no-array-index-key
            <FormGroup key={`${parameter.uiKey}.${index}`} name={`requiredParametersAttributes.${index}`}>
              <tr>
                <td>
                  <Input name="key" disabled={readOnly} />
                </td>
                <td>
                  <Input name="value" disabled={readOnly} />
                </td>
                {!readOnly && (
                  <td>
                    <Button.Delete onClick={() => handleDelete(parameter, index)} title="Remove" />
                  </td>
                )}
              </tr>
            </FormGroup>
          ))}

          {!readOnly && (
            <tr>
              <td colSpan={4}>
                <Button.Add size="sm" onClick={() => append({})} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </PageSection>
  );
}
