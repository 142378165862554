import { isEmpty, isNil } from 'lodash';
import {
  PageSection,
  FormGroup,
  useFormGroup,
  useRouter,
} from '@tripledotstudios/react-core';
import { useQuery, useExtendedFieldArray, useFormPermissions } from '@hooks';

import { Button, Form, Weights } from '@components';
import {
  Label,
  FloatingLabeledInput,
  Input,
  Checkbox,
  FloatingLabeledSelect,
  RegisteredError,
  Select,
} from '@components/resource';
import { EventsRoutes } from '@pages/routes';
import { useWatch } from 'react-hook-form';
import { toOptions } from '@services/utils';

const DropProbabilitiesRoutes = EventsRoutes.DropProbabilities;

function RetryProbabilityInput({ name, toggleName }) {
  const enabled = useWatch({ name: toggleName });

  return <div><Input type="number" name={name} disabled={!enabled} /></div>;
}

function Items({ dropProbabilities }) {
  const { readOnly } = useFormPermissions();
  const dropProbabilityId = useWatch({ name: 'dropProbabilityId' });
  const dropProbability = dropProbabilities.find(({ id }) => id === dropProbabilityId);
  const { generateName } = useFormGroup();
  const formGroupName = generateName('itemsAttributes');
  const { fields, append, handleDelete } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });

  if (isNil(dropProbability)) {
    return <div className="text-center">Select Probability drop</div>;
  }

  const weightsData = dropProbability.itemsAttributes;
  const rarityOptions = dropProbability.itemsAttributes.map(({ rarity }) => ({ label: rarity, value: rarity }));
  const appendNewItem = () => append({ minRarity: null, retryEnabled: true, retryProbability: 0 });

  return (
    <>
      <Weights
        defaultData={weightsData}
        name="dropProbabilityItems"
        activeKey={weightsData[0].id}
        transformData={({ rarity, weight }, index) => ({
          key: weightsData[index].id,
          index,
          weight,
          title: rarity === 1 ? 'Control' : `Item Rarity ${rarity}`,
        })}
      />
      <div className="grid pb-3" style={{ '--bs-columns': 4 }}>
        <Label label="Min rarity" />
        <Label label="Retry on/off" />
        <Label label="Probability of Retry (%)" />
        <div />
        {fields.map((field, index) => {
          const itemPath = `${formGroupName}.${index}`;

          return field._destroy || (
            <FormGroup key={field.key} name={itemPath}>
              <div>
                <Select name="minRarity" options={rarityOptions} />
              </div>
              <div>
                <Checkbox name="retryEnabled" />
              </div>
              <RetryProbabilityInput name="retryProbability" toggleName={`${itemPath}.retryEnabled`} />
              <div>
                {!readOnly && (
                  <Button.Delete
                    className="delete me-1"
                    title="Remove"
                    onClick={() => handleDelete(field, index)}
                  />
                )}
              </div>
            </FormGroup>
          );
        })}
      </div>
      {!readOnly && <Button.Add className="mt-2" onClick={appendNewItem} />}
    </>
  );
}

export default function DropProbabilitiesForm({ resource, ...rest }) {
  const { query } = useRouter();

  const { response: dropProbabilities } = useQuery(DropProbabilitiesRoutes.indexRequest, {
    ...query, deleted: false, withoutPagination: true,
  });

  if (!dropProbabilities?.items) {
    return null;
  }

  const inUse = !isEmpty(resource.data.inUse);
  const { data: { tierOptions } } = resource;
  const dropProbabilitiesOptions = toOptions(dropProbabilities.items);

  return (
    <Form.Resource resource={resource} {...rest}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" disabled={inUse} name="name" />
        <FloatingLabeledSelect label="Tier" name="tier" disabled={inUse} options={tierOptions} />
        <FloatingLabeledSelect label="Probability drop" name="dropProbabilityId" options={dropProbabilitiesOptions} />
      </Form.ControlsLayout>

      <PageSection title="Gacha Items">
        <RegisteredError name="base" />
        <Items dropProbabilities={dropProbabilities.items} />
      </PageSection>
    </Form.Resource>
  );
}
