import {
  useCallback,
  useEffect,
  useState,
  memo,
} from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Tabs, Tab, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { get, range } from 'lodash';
import {
  PageSection,
  useRouter,
  FormGroup,
  updateQuery,
} from '@tripledotstudios/react-core';

import { FormContainer, Button, Weights } from '@components';
import { useLocationQuery, useFormPermissions } from '@hooks';
import { Input, LabeledInput } from '@components/resource';
import ExperimentEntities from './ExperimentEntities';
import ExperimentEntitiesList from './ExperimentEntitiesList';

const Container = styled.div`
  min-height: 200px;
`;

const TabTitle = ({ index, variantsSize }) => {
  const title = useWatch({ name: `variantsAttributes.${index}.name` });
  const weightNames = range(variantsSize).map((i) => `variantsAttributes.${i}.weight`);
  const weights = useWatch({ name: weightNames });
  const overall = Weights.calculateOverallWeight(weights);
  const percentageWeight = Weights.calculatePercentage(weights[index], overall);

  return `${title || 'Variant'} (${percentageWeight}%)`;
};

const Variants = ({
  actionName,
  onAddVariant,
}) => {
  const router = useRouter();
  const { formState: { errors }, control } = useFormContext();
  const params = useLocationQuery();
  const { readOnly } = useFormPermissions();

  const [activeKey, setActiveKey] = useState(false);

  const { fields: variants, append, remove } = useFieldArray({
    control,
    name: 'variantsAttributes',
    keyName: 'key',
  });

  const removeVariant = useCallback((index) => {
    remove(index);
  }, []);

  const addVariant = useCallback(() => {
    if (actionName === 'update') {
      onAddVariant();
    } else if (actionName === 'create') {
      append({
        name: `Variant ${variants.length}`, weight: 0, default: false,
      });
    }
  }, [variants.length, actionName]);

  useEffect(() => {
    if (!variants || variants.length < 1) {
      return;
    }
    const variantId = params.get('variantId');
    if (variantId) {
      const variant = variants.find(({ id }) => id === +variantId);
      if (variant) {
        setActiveKey(variant.key);
        return;
      }
    }

    setActiveKey(variants[variants.length - 1].key);
  }, [variants, params]);

  useEffect(() => {
    const activeVariant = variants.find(({ key }) => key === activeKey);
    if (activeVariant) updateQuery(router, { variantId: activeVariant.id });
  }, [activeKey]);

  const variantHasError = (index) => get(errors, `variantsAttributes.${index}`);

  return (
    <Container>
      <FormContainer>
        <ExperimentEntitiesList variants={variants} />
      </FormContainer>

      <PageSection
        title="Variants"
        actions={!readOnly && <Button.Add className="ms-3" title="Add Variant" onClick={addVariant} size="sm" />}
      >
        <Weights
          name="variantsAttributes"
          activeKey={activeKey}
          transformData={({ weight, name }, index) => ({
            key: variants[index].key,
            index,
            weight,
            title: name,
          })}
        />
        <Tabs
          transition={false}
          className="mb-3"
          activeKey={activeKey}
          onSelect={(key) => setActiveKey(key)}
        >
          {variants.map((field, index) => {
            const error = variantHasError(index);

            return (
              <Tab
                key={field.key}
                title={<TabTitle index={index} variantsSize={variants.length} />}
                eventKey={field.key}
                tabClassName={error && 'has-errors'}
              >
                <FormContainer>
                  <LabeledInput
                    label="Name"
                    name={`variantsAttributes.${index}.name`}
                    disabled={field.default || field.default === 'true'}

                  />
                  <LabeledInput
                    label="Weight"
                    name={`variantsAttributes.${index}.weight`}
                    type="number"
                  />
                  <Col>
                    {!field.default && !field.id
                    && <Button.Delete title="Remove variant" onClick={() => removeVariant(index)} />}
                    <Input type="hidden" name={`variantsAttributes.${index}.default`} />
                  </Col>
                  <FormGroup name={`variantsAttributes.${index}.experimentEntitiesAttributes`}>
                    <ExperimentEntities variantId={field.id} data={field.experimentEntitiesAttributes} />
                  </FormGroup>
                </FormContainer>
              </Tab>
            );
          })}
        </Tabs>
      </PageSection>
    </Container>
  );
};

export default memo(Variants);
