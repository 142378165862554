import React from 'react';
import { Table } from 'react-bootstrap';

import { Button } from '@components';
import { FormGroup, PageSection } from '@tripledotstudios/react-core';
import { Input, Select } from '@components/resource';
import { useExtendedFieldArray } from '@hooks';

export default function GameProductsGameItems({ gameItemOptions, permissions }) {
  const gameItemsPath = 'productGameItemsAttributes';
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: gameItemsPath,
    keyName: 'uiKey',
  });

  const appendNewProduct = () => append({ quantity: 0 });

  return (
    <PageSection title="Game Items">
      <Table size="sm">
        <thead>
          <tr>
            <th>Game Item</th>
            <th>Quantity</th>
            {permissions.destroy && <th width="10%">Action</th>}
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => (
            item._destroy || (
              // eslint-disable-next-line react/no-array-index-key
              <FormGroup key={`${gameItemsPath}.${item.uiKey}.${index}`} name={`${gameItemsPath}.${index}`}>
                <tr>
                  <td>
                    <Select name="gameItemId" options={gameItemOptions} />
                    {' '}
                  </td>
                  <td><Input label="Quantity" name="quantity" /></td>
                  <td>
                    {permissions.destroy && (
                      <Button.Delete
                        className="delete me-1"
                        title="Remove"
                        onClick={() => handleDelete(item, index)}
                      />
                    )}
                  </td>
                </tr>
              </FormGroup>
            )
          ))}
        </tbody>
      </Table>
      {permissions.update && <Button.Add className="mt-2" onClick={appendNewProduct} />}
    </PageSection>
  );
}
