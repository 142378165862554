import { useQuery } from '@tanstack/react-query';
import { EventsRoutes } from '@pages/routes';
import { toOptions } from '@services/utils';

const LIST_QUERY_NAME_KEY = 'events/collectionSets';
export const DEFAULT_LOADER_STALE_TIME = 1000;

function collectionSetsQuery({
  params = {},
  transform,
  staleTime = DEFAULT_LOADER_STALE_TIME,
  ...opts
}) {
  return {
    queryKey: [LIST_QUERY_NAME_KEY, params],
    queryFn: async ({ queryKey }) => {
      const { data } = await EventsRoutes.CollectionSets.indexRequest(queryKey[1]);

      if (transform) {
        return transform(data);
      }

      return data;
    },
    staleTime,
    ...opts,
  };
}

export function useEventsCollectionSetOptions(query = {}) {
  return useQuery(collectionSetsQuery({
    params: {
      ...query,
      includeInUse: false,
      withoutPagination: true,
    },
    transform: (data) => toOptions(data.items, {
      transform: (option, { availabilityStateLive, availabilityState }) => Object.assign(option, {
        availabilityStateLive,
        availabilityState,
      }),
    }),
  }));
}

export function useEventsCollectionSets(query = {}) {
  return useQuery(collectionSetsQuery({ params: { ...query, includeInUse: false, withoutPagination: true } }));
}
