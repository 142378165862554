import React from 'react';
import { Col } from 'react-bootstrap';

const FormContainer = ({ children }) => (
  <Col className="col-xxxl-6 col-xxl-8 col-xl-10 col-lg-12">
    {children}
  </Col>
);

export default FormContainer;
