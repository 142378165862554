import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';

import { useQuery, useCrudlRequests, useAppData } from '@hooks';
import { withDefaultQuery } from '@hocs';
import { EventsRoutes } from '@pages/routes';
import {
  Button,
  DuplicationModal, PageHeader, SplitPane,
} from '@components';
import { LabeledInput } from '@components/resource';
import { ButtonsFooter } from '@components/collection';
import { hasApplicationPermissions } from '@services/permissions';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import List from './List';
import Form from './Form';
import Filter from './Filter';

const Routes = EventsRoutes.Configurations;

function Index({ defaultQuery }) {
  useRequireAuth();
  const { query } = useRouter();

  const { response, refetch } = useQuery(Routes.indexRequest, { ...query, withoutPagination: true }, [query.filter]);
  const [resource, setResource] = useState(null);
  const [duplicatedResource, setDuplicatedResource] = useState(null);
  const canCreate = hasApplicationPermissions(['events', 'configuration', 'write']);

  const {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  } = useCrudlRequests(Routes, setResource, refetch, {}, [query.recurring]);

  const handleDuplicate = useCallback(({ id, name, priority }) => {
    setDuplicatedResource({ id, name: `${name}_copy`, priority: priority + 1 });
  }, []);
  const {
    featureToggles: {
      recurring_events_enabled: recurringEventsEnabled,
    },
  } = useAppData();
  return (
    <>
      <PageHeader title="Events Configurations">
        {canCreate && (
          recurringEventsEnabled ? (
            <DropdownButton
              variant="success"
              title={(
                <>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Configuration
                </>
              )}
            >
              <Dropdown.Item onClick={(e) => handleNew(e, { recurring: false })}>
                One time event
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleNew(e, { recurring: true })}>
                Recurring Event
              </Dropdown.Item>
            </DropdownButton>
          ) : <Button.Add title="Add Configuration" onClick={handleNew} />
        )}
      </PageHeader>
      <Filter defaults={defaultQuery.filter} />
      <SplitPane paneName="events_configurations" withFilter>
        <SplitPane.Top>
          <List
            response={response}
            selectedResourceId={resource && resource.data.id}
            onEditButtonClick={handleEdit}
            onDelete={handleDelete}
            routes={Routes}
            onDuplicateButtonClick={handleDuplicate}
            query={query}
          />
          <DuplicationModal
            record={duplicatedResource}
            handleClose={() => setDuplicatedResource(null)}
            submitRequest={(values) => Routes.duplicateRequest({ ...query, ...values })}
            onSuccess={({ data }) => { refetch(); setDuplicatedResource(null); handleEdit(data.id); }}
          >
            <LabeledInput label="Name" name="name" />
            <LabeledInput label="Priority" name="priority" type="number" />
          </DuplicationModal>
        </SplitPane.Top>
        <SplitPane.Divider />
        <SplitPane.Bottom>
          {resource && <Form resource={resource} onDiscard={handleDiscard} onSuccess={handleSave} />}
        </SplitPane.Bottom>
        {resource && (
          <ButtonsFooter
            cancelOptions={{ onClick: handleOnCancel }}
            submitOptions={{ disabled: !resource.data.permissions.update }}
          />
        )}
      </SplitPane>
    </>
  );
}
export default withDefaultQuery(Index, () => {
  const { enums } = useAppData();
  const configurationStatuses = enums['Events::ConfigurationStatuses'];
  return { filter: { statusIn: [configurationStatuses.ACTIVE, configurationStatuses.INACTIVE] } };
});
