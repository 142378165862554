import React from 'react';
import { Route } from 'react-router-dom';

import { ApplicationsRoutes as Routes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';
import Edit from './Edit';
import New from './New';
import Home from './Home';

export default function ApplicationsRoot() {
  return [
    <Route
      exact
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <>
          <PageTitle text="Applications" />
          <Index />
        </>
      )}
    />,
    <Route
      exact
      key={Routes.newRawPath}
      path={Routes.newRawPath}
      element={(
        <>
          <PageTitle entity="Application" action="New" />
          <New />
        </>
      )}
    />,
    <Route
      exact
      key={Routes.editRawPath}
      path={Routes.editRawPath}
      element={(
        <>
          <PageTitle entity="Application" action="Edit" />
          <Edit />
        </>
      )}
    />,
    <Route
      exact
      key={Routes.homeRawPath}
      path={Routes.homeRawPath}
      element={(
        <>
          <PageTitle />
          <Home />
        </>
      )}
    />,
  ];
}
