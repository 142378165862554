import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { Button } from '@components';

export default function GameProductsList({
  response, onEditButtonClick, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'UUID',
        accessor: 'uuid',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Item Type',
        accessor: 'itemType',
      },
      {
        Header: 'Deleted',
        Cell: ({ row: { original: { deleted } } }) => deleted && 'yes',
      },
      {
        Header: 'Synchronized at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { id, permissions } } }) => (
          permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No game products yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
