import React from 'react';
import { FormGroup, PageSection, useFormGroup } from '@tripledotstudios/react-core';

import { LabeledInput, RegisteredError } from '@components/resource';
import { Button } from '@components';
import { useExtendedFieldArray, useFormPermissions, useCurrentApplication } from '@hooks';
import Rewards from '@components/events/Rewards';
import DynamicParametersWithTemplate from '@components/events/DynamicParametersWithTemplate';
import LocalisationKeys from './LocalisationKeys';

export default function ObjectiveGoals({
  gameItemsOptions,
  objectiveTemplates,
  localisationKeysOptions,
}) {
  const { generateName } = useFormGroup();
  const formGroupName = 'objectiveConfigurationGoalsAttributes';
  const { readOnly } = useFormPermissions();

  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: generateName(formGroupName),
    keyName: 'key',
  });
  const { objectiveGoalParamsEnabled } = useCurrentApplication();
  const appendGoal = () => append({ goal: 0 });
  let goalIndex = 0;

  return (
    <>
      {fields.map((item, index) => {
        if (item._destroy) return null;

        goalIndex += 1;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${formGroupName}.${item.key}.${index}`} name={`${formGroupName}.${index}`}>
            <PageSection
              title={`Objective Goal ${goalIndex}`}
              actions={readOnly || (
                <Button.Delete
                  className="delete me-1"
                  title="Remove"
                  onClick={() => handleDelete(item, index)}
                />
              )}
            >
              <LabeledInput
                label="Target"
                sizes={[3, 9]}
                name="target"
                type="number"
              />
              {objectiveGoalParamsEnabled && (
                <DynamicParametersWithTemplate
                  objectiveTemplates={objectiveTemplates}
                  objectiveTemplateLabel="Override objective template"
                  emptyTemplateOptionLabel="None"
                />
              )}
              <Rewards
                formGroupName="rewardsAttributes"
                gameItemsOptions={gameItemsOptions}
              />
              <LocalisationKeys
                localisationKeysOptions={localisationKeysOptions}
              />
              <RegisteredError name="base" />
            </PageSection>
          </FormGroup>
        );
      })}
      {readOnly || <Button.Add title="Add Goal" className="mt-2" onClick={appendGoal} />}
    </>
  );
}
