import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import { Form, FormContainer, PrettyJsonDisplay } from '@components';
import { LabeledInput, Label } from '@components/resource';

import BackupItems from './BackupItems';

const JsonWrapper = styled.pre`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: #eaecef;
  margin: 0;
  padding: 1.645rem 0.6rem 0.6rem;
`;

export default function GameProductForm({ resource, ...rest }) {
  return (
    <Form.Resource resource={resource} {...rest}>
      <Row>
        <FormContainer>
          <Form.Component>
            <LabeledInput disabled label="Id" name="id" />
            <LabeledInput disabled label="UUID" name="uuid" />
            <LabeledInput disabled label="Name" name="name" />
            <LabeledInput disabled label="Item Type" name="itemType" />
            <LabeledInput disabled label="Deleted" name="deleted" />
            <LabeledInput disabled label="Created At (UTC)" name="createdAt" />
            <LabeledInput disabled label="Synchronized At (UTC)" name="updatedAt" />
            <Label label="Payload">
              <JsonWrapper>
                <PrettyJsonDisplay data={resource.data.payload} />
              </JsonWrapper>
            </Label>

            <BackupItems />
          </Form.Component>
        </FormContainer>
      </Row>
    </Form.Resource>
  );
}
