import React from 'react';
import { Table } from 'react-bootstrap';
import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray, useFormPermissions } from '@hooks';
import { RegisteredError } from '@components/resource';
import Button from '@components/buttons';

import ContributionConfiguration from './ContributionConfiguration';

export default function ContributionConfigurations({ triggers }) {
  const { generateName } = useFormGroup();
  const { readOnly } = useFormPermissions();

  const triggersOptions = triggers.map(({ id, name }) => ({ label: name, value: id }));

  const rulesFormGroupName = generateName('contributionConfigurationsAttributes');
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: rulesFormGroupName,
    keyName: 'key',
  });

  return (
    <PageSection title="Contribution">
      <RegisteredError name="id" />
      {fields.filter(({ _destroy }) => !_destroy).length > 0 && (
        <Table size="sm">
          <thead>
            <tr>
              <th>Top-up trigger</th>
              <th>Contribution parameter</th>
              <th>Contribution %</th>
              {!readOnly && <th width="10%">Action</th>}
            </tr>
          </thead>
          <tbody>
            {fields.map((item, index) => (
              item._destroy || (
                // eslint-disable-next-line react/no-array-index-key
                <FormGroup key={`item.${item.key}.${index}`} name={`contributionConfigurationsAttributes.${index}`}>
                  <ContributionConfiguration
                    triggers={triggers}
                    index={index}
                    item={item}
                    readOnly={readOnly}
                    handleDelete={handleDelete}
                    triggersOptions={triggersOptions}
                  />
                </FormGroup>
              )
            ))}
          </tbody>
        </Table>
      )}
      {!readOnly && <Button.Add title="Add" onClick={() => append({})} />}
    </PageSection>
  );
}
