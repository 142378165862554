import { useRef } from 'react';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';
import { sortBy, sumBy } from 'lodash';
import { Tooltip } from '@tripledotstudios/react-core';
import { useWatch } from 'react-hook-form';

const ProgressBarWrapper = styled(ProgressBar)`
  height: 2.4rem;
  margin-bottom: 1rem;
  background: transparent;
`;

const ProgressItem = styled(ProgressBar)`
  transition: 250ms ease-in-out;
  transform: translateY(0);
  margin-top: 0.5rem;
  height: 1.9rem;
  background-color: ${(props) => props.color}!important;
  color: ${(props) => props.textcolor}!important;

  &.active {
    transform: translateY(-0.5rem);
  }
`;

function ProgressLabel({ text }) {
  const spanRef = useRef(null);
  const progressText = (
    <span className="d-block text-truncate overflow-x-hidden user-select-none px-1" ref={spanRef}>{text}</span>
  );

  // It's scrollWidth fills only when display block/inline-block
  const fits = () => (spanRef.current ? (spanRef.current.scrollWidth) <= spanRef.current.offsetWidth : false);

  if (fits()) {
    return progressText;
  }
  return <Tooltip text={text} placement="top">{progressText}</Tooltip>;
}

const COLOR_LIST = ['#c4e2fc', '#9ae0c8', '#fffab3', '#ffa7a7', '#ffd5c0'];
const TEXT_COLOR_LIST = ['#1f5b91', '#008e5c', '#f18e1b', '#e22221', '#ea611f'];

function getIndexColors(index) {
  let colorIndex = index;

  while (colorIndex >= COLOR_LIST.length) {
    colorIndex -= COLOR_LIST.length;
  }

  return { bg: COLOR_LIST[colorIndex], text: TEXT_COLOR_LIST[colorIndex] };
}

function calculatePercentage(weight, overallWeight) {
  return parseFloat(((weight * 100) / overallWeight).toFixed(2)) || 0;
}

function calculateOverallWeight(data) {
  return sumBy(data, (item) => {
    const weight = typeof item === 'number' ? item : item.weight;

    return +weight || 0;
  });
}

export default function Weights({
  name,
  defaultData,
  activeKey,
  transformData = (item, index) => ({ ...item, index }),
}) {
  const data = useWatch({ name }) || defaultData;

  const overallWeight = calculateOverallWeight(data);

  const weightsData = data.map((item, index) => {
    const transformed = transformData(item, index);

    transformed.percentageWeight = calculatePercentage(transformed.weight, overallWeight);

    return transformed;
  });

  const sortedWeights = sortBy(weightsData, 'index');

  return (
    <ProgressBarWrapper>
      {sortedWeights.map(({
        key,
        percentageWeight,
        title,
        index,
      }) => {
        const { bg, text } = getIndexColors(index);

        return (
          <ProgressItem
            key={key}
            className={activeKey === key ? 'active' : ''}
            now={percentageWeight}
            textcolor={text}
            color={bg}
            label={<ProgressLabel text={`${title} ${percentageWeight}%`} />}
          />
        );
      })}
    </ProgressBarWrapper>
  );
}

Weights.getIndexColors = getIndexColors;
Weights.calculateOverallWeight = calculateOverallWeight;
Weights.calculatePercentage = calculatePercentage;
