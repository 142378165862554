import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@components';

import { SegmentsSelect } from '@components/segmentation';

export default function ObjectiveSegmentModal({
  showModal,
  segments,
  selectedSegments,
  handleAddSegment,
  handleClose,
}) {
  const segmentSelectRef = React.createRef();
  const handleAdd = () => {
    let segmentId = segmentSelectRef.current.getValue()[0];
    segmentId = segmentId?.value ? Number(segmentId.value) : '';

    handleAddSegment(segmentId);
  };

  return (
    <Modal show={!!showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Add Objective Segment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SegmentsSelect
          segments={segments}
          segmentSelectRef={segmentSelectRef}
          selected={selectedSegments}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button.Add title="Add" onClick={handleAdd} />
        <Button.Cancel title="Cancel" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}
