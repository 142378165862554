import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { PlusIcon } from '../icons';

const StyledButton = styled(Button)`
  color: white;
  &:hover, &:focus {
    color: white;
  }
`;

const AddButton = ({
  title = 'Add',
  icon = <PlusIcon />,
  ...props
}) => (
  <StyledButton variant="success" {...props}>
    {icon}
    {' '}
    {title}
  </StyledButton>
);

export default AddButton;
