import React from 'react';
import { Modal } from 'react-bootstrap';
import { ABTestingRoutes } from '@pages/routes';
import { useRouter } from '@tripledotstudios/react-core';

import { LabeledInput } from '@components/resource';
import { Form, Button } from '@components';
import { buildResourceResponseHandler } from '@services/response_handler';

const VariantModal = ({
  formRef, variant, handleClose,
}) => {
  const { query } = useRouter();
  const variantsPath = 'variantsAttributes';

  const onSuccess = ({ data }) => {
    const { setValue, getValues } = formRef.current;
    const { id: newVariantId } = data;
    const variantsData = getValues(variantsPath);

    ABTestingRoutes.Experiments.editRequest(query).then((res) => {
      const addedVariant = res.data.variantsAttributes.find(({ id }) => +id === +newVariantId);
      setValue(variantsPath, [...variantsData, addedVariant]);
    });

    handleClose();
  };
  const submitRequest = (values) => ABTestingRoutes.Experiments.addVariantRequest({ ...query, ...values });

  return (
    <Modal show={!!variant} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new variant</Modal.Title>
      </Modal.Header>
      <Form defaultValues={variant} enableReinitialize>
        {({ setError }) => {
          const responseHandler = buildResourceResponseHandler({
            setError,
            onSuccess,
            successMessage: 'Variant was added successfully',
          });

          const onSubmit = (values) => submitRequest(values).then(responseHandler);

          return (
            <Form.Component onSubmit={onSubmit}>
              <Modal.Body>
                <LabeledInput label="Name" name="name" />
                <LabeledInput
                  label="Weight"
                  name="weight"
                  required
                  type="number"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button.Cancel onClick={handleClose} />
                <Button.Submit />
              </Modal.Footer>
            </Form.Component>
          );
        }}
      </Form>
    </Modal>
  );
};

export default VariantModal;
