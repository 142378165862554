import React from 'react';
import { Link } from 'react-router-dom';
import {
  PaymentsRoutes, CampaignsRoutes, PiggyBanksRoutes, EventsRoutes,
} from '@pages/routes';

const EntitiesTexts = {
  'Payments::CatalogPreset': (({ entity }) => (
    `Payment Catalog "${entity.catalogName}", "${entity.presetName}" preset`)
  ),
  'Campaigns::Creative': (({ entity }) => (
    `Campaign "${entity.configurationName}", Creative #${entity.creativeId}`)
  ),
  'PiggyBanks::ConfigurationSegment': (({ entity }) => (
    `Piggy Bank "${entity.configurationName}", Segment "${entity.segmentName}"`)
  ),
  'Events::Configuration': (({ entity }) => (
    `Events "${entity.name}"`)
  ),
};

const EntitiesLinks = {
  'Payments::CatalogPreset': ((
    experimentEntity, variantId, applicationId,
  ) => {
    const { entity: { catalogId, catalogPresetId } } = experimentEntity;
    const params = { catalogPresetId };

    if (variantId) params.variantId = variantId;

    return (
      <Link
        to={PaymentsRoutes.Catalogs.editPath({ applicationId, id: catalogId }, params)}
        key={`payment_catalog_${catalogId}`}
        target="_blank"
      >
        {EntitiesTexts['Payments::CatalogPreset'](experimentEntity)}
      </Link>
    );
  }),
  'Campaigns::Creative': ((experimentEntity, variantId, applicationId) => {
    const { entity: { configurationId, configurationSegmentId, creativeId } } = experimentEntity;
    const params = { configurationSegmentId, creativeId };

    if (variantId) params.variantId = variantId;

    return (
      <Link
        to={CampaignsRoutes.Configurations.editPath({ applicationId, id: configurationId }, params)}
        key={`campaigns_creative_${creativeId}`}
        target="_blank"
      >
        {EntitiesTexts['Campaigns::Creative'](experimentEntity)}
      </Link>
    );
  }),
  'PiggyBanks::ConfigurationSegment': ((experimentEntity, variantId, applicationId) => {
    const { entity: { configurationId, configurationSegmentId } } = experimentEntity;
    const params = { configurationSegmentId };

    if (variantId) params.variantId = variantId;

    return (
      <Link
        to={PiggyBanksRoutes.Configurations.editPath({ applicationId, id: configurationId }, params)}
        key={`piggy_bank_configuration_${configurationSegmentId}`}
        target="_blank"
      >
        {EntitiesTexts['PiggyBanks::ConfigurationSegment'](experimentEntity)}
      </Link>
    );
  }),
  'Events::Configuration': ((experimentEntity, variantId, applicationId) => {
    const { entity: { id } } = experimentEntity;
    const params = {};

    if (variantId) params.variantId = variantId;

    return (
      <Link
        to={EventsRoutes.Configurations.editPath({ applicationId, id }, params)}
        key={`events_configuration_${id}`}
        target="_blank"
      >
        {EntitiesTexts['Events::Configuration'](experimentEntity)}
      </Link>
    );
  }),
};

export default EntitiesLinks;

export { EntitiesTexts };
