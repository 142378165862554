import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@tripledotstudios/react-core';

import { EditIcon } from '../icons';
import BaseTransparentButton from './BaseTransparentButton';

const StyledButton = styled(BaseTransparentButton)`
  &:hover, &:focus {
    svg path {
      fill: #3b5fad;
    }
  }
`;

const EditButton = ({
  title = 'Edit',
  icon = <EditIcon />,
  ...props
}) => (
  <Tooltip placement="bottom" text={title}>
    <StyledButton {...props}>
      {icon}
    </StyledButton>
  </Tooltip>
);

EditButton.Color = '#3b5fad';

export default EditButton;
