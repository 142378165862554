import React from 'react';
import styled from 'styled-components';

// we need this in order to remove focus after click and to hide tooltip
const BaseTransparentButton = React.forwardRef(({ onClick, ...rest }, ref) => {
  const handleClick = (e) => { e.target.closest('button').blur(); onClick(e); };

  return <button type="button" ref={ref} onClick={handleClick} {...rest} />;
});

const BaseTransparentButtonStyled = styled(BaseTransparentButton)`
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 18px;
  background-color: transparent;
  border-radius: 4px;
  margin: -2px 0;
  padding: 1px 6px; // default chrome button padding
  -webkit-appearance: auto!important; // prevent safari from drawing extra button wrapper

  ${({ disabled }) => !disabled && `
    &:hover, &:focus {
      background-color: #ebebeb;
    }
  `}
`;

export default BaseTransparentButtonStyled;
