import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';
import { get } from 'lodash';
import classnames from 'classnames';

const hasError = (fieldName) => {
  const { formState: { errors: formErrors } } = useFormContext();
  const { generateName } = useFormGroup();

  return get(formErrors, generateName(fieldName));
};

export default function Error({ name, className }) {
  const { formState: { errors: formErrors } } = useFormContext();
  const { generateName } = useFormGroup();
  const errors = get(formErrors, generateName(name));

  return errors
    ? <p className={classnames('text-danger', className)}>{errors.message}</p>
    : '';
}

const RegisteredError = ({ name, ...props }) => {
  const { register } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);

  register(fullName);

  return (
    <Error name={name} {...props} />
  );
};

export { hasError, RegisteredError };
