import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';
import { get } from 'lodash';

const isDestroyed = (name) => {
  const { formState } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(`${name}._destroy`);
  return get(formState.dirtyFields, fullName);
};

export default isDestroyed;
