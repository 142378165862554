import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';
import { useExtendedFieldArray } from '@hooks';
import { LabeledInput, LabeledSelect } from '@components/resource';

export default function GachaChests({ tierChestOptions }) {
  const { generateName } = useFormGroup();
  const formGroupName = generateName('tierChestsAttributes');
  const { fields } = useExtendedFieldArray({ name: formGroupName });

  return (
    <PageSection title="Gacha Chests">
      {fields.map((field, index) => (
        <FormGroup key={field.id} name={`${formGroupName}.${index}`}>
          <LabeledInput type="hidden" name="tier" />
          <LabeledSelect
            label={`Tier ${field.tier}`}
            name="gachaChestId"
            options={tierChestOptions[field.tier] || []}
          />
        </FormGroup>
      ))}
    </PageSection>
  );
}
