import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

import { PageSection } from '@tripledotstudios/react-core';

export default function DevicesView({ devices }) {
  return (
    <>
      <PageSection title="Devices" />

      <Row>
        <Col xxl={8} lg={10} xs={12}>
          <Table className="table-bordered table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>Install ID</th>
                <th>IDFV</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device.id}>
                  <td>{device.id}</td>
                  <td>{device.installId}</td>
                  <td className="text-nowrap">{device.idfv}</td>
                  <td>{device.createdAt}</td>
                  <td>{device.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
