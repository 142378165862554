import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { map } from 'lodash';
import { PageSection, FormGroup } from '@tripledotstudios/react-core';

import { Input } from '@components/resource';
import { useFormPermissions, useExtendedFieldArray } from '@hooks';
import Button from '@components/buttons';

export default function ArgumentsForm({ newRecord, readOnly }) {
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: 'triggerArgumentsAttributes',
    keyName: 'uiKey',
  });

  const { readOnly: readOnlyPermission } = useFormPermissions();

  return (
    <PageSection
      title="Arguments"
      actions={(
        !readOnlyPermission && !readOnly && (
          <Button.Add title="Add Key" size="sm" onClick={() => append({})} />
        )
      )}
    >
      {readOnly && newRecord && <p>Default arguments will be shown after creation</p>}
      {map(fields, (argument, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`${argument.uiKey}.${index}`} className="mb-2">
          {!argument._destroy && (
            <FormGroup name={`triggerArgumentsAttributes.${index}`}>

              <Row>
                <Col><Input disabled={readOnly} name="key" /></Col>
                {!readOnlyPermission && !readOnly && (
                  <Col sm={1}>
                    <Button.Delete onClick={() => handleDelete(argument, index)} title="Remove" />
                  </Col>
                )}
              </Row>
            </FormGroup>
          )}
        </div>
      ))}
    </PageSection>
  );
}
