import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Table, Form, Alert, Stack,
} from 'react-bootstrap';

import { PageSection, useRouter, useFlashMessages } from '@tripledotstudios/react-core';
import { buildResourceResponseHandler } from '@services/response_handler';
import { ApplicationTokensRoutes as Routes } from '@pages/routes';
import { hasPermissions } from '@services/permissions';
import { useQuery } from '@hooks';
import Button from '@components/buttons';

const NewTokenLabel = styled.div`
  width: 12rem;
`;
const NameColumn = styled.td`
  overflow-wrap: anywhere;
`;

export default function ApplicationTokensForm() {
  const [newName, setNewName] = useState('');
  const [tokens, setTokens] = useState([]);

  const { query: { id: applicationId } } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { applicationId, withoutPagination: true });
  const { error } = useFlashMessages();
  const canWrite = hasPermissions(['application', 'write']);

  const responseHandler = buildResourceResponseHandler({
    actionName: 'create',
    setError: (name, { message }) => error(`Error: ${name} - ${message}`),
    onSuccess: ({ data: applicationToken }) => {
      setTokens([...[applicationToken], ...tokens]);
      setNewName('');
    },
  });

  useEffect(() => response?.items && setTokens(response.items), [response?.items]);

  const submitRequest = (values) => Routes.createRequest({ applicationId, ...values });
  const handleCreateClick = () => submitRequest({ name: newName }).then(responseHandler);
  const handleNameChange = ({ target: { value } }) => setNewName(value);

  return (
    <PageSection title="Access tokens">
      {canWrite && (
        <Alert variant="info">
          <Stack direction="horizontal" gap={3}>
            <NewTokenLabel>Create new token: </NewTokenLabel>
            <Form.Control value={newName} onChange={handleNameChange} name="name" placeholder="enter name here" />
            <Button.Action onClick={handleCreateClick} title="Create" />
          </Stack>
        </Alert>
      )}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Token</th>
            <th>Created date</th>
            {canWrite && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {tokens.map(({
            id, name, createdAt, token,
          }) => {
            // eslint-disable-next-line operator-linebreak
            const deleteConfirmationText =
              `If there are any API calls using the “${name}” token, they will stop working after the deletion.`;

            return (
              <tr key={id}>
                <NameColumn>{name}</NameColumn>
                <td>
                  {token.startsWith('***') ? token : (
                    <>
                      {token}
                      {' '}
                      <Button.Copy value={token} />
                    </>
                  )}
                </td>
                <td>{createdAt}</td>
                {canWrite && (
                  <td>
                    <Button.WarningConfirm
                      size="sm"
                      confirmTitle="Are you sure you want to delete this token?"
                      successText="Application token successfully deleted"
                      confirmText={deleteConfirmationText}
                      requestParams={{ applicationId, id }}
                      onSuccess={refetch}
                      request={Routes.deleteRequest}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PageSection>
  );
}
