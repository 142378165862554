import React from 'react';
import { Button, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { FlashMessages } from '@tripledotstudios/react-core';

import TripledotLogo from '@components/shared/TripledotLogo';

const StyledCardBody = styled(Card.Body)`
  padding: 2em 7em;
`;

const StyledButton = styled(Button)`
  &:hover, &:focus {
    color: white;
  }
`;

const StyledIcon = styled.i`
  font-size: 1.2rem;
`;

export default function SignInButton({ href }) {
  return (
    <>
      <FlashMessages />
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Card className="text-center">
          <StyledCardBody>
            <TripledotLogo width="100" height="100" className="mb-3" />

            <Card.Title as="h2">LiveOps Server</Card.Title>
            <StyledButton className="mt-5" size="lg" href={href}>
              <StyledIcon className="bi bi-google me-2" />
              Sign in with Google
            </StyledButton>
          </StyledCardBody>
        </Card>
      </div>
    </>
  );
}
