import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { get } from 'lodash';
import {
  FormGroup, useFormGroup, useRouter, updateQuery,
} from '@tripledotstudios/react-core';

import { useFormContext } from 'react-hook-form';
import {
  useQuery, useLocationQuery, useFormPermissions, useExtendedFieldArray, useAppData,
} from '@hooks';

import Button from '@components/buttons';
import { ErrorIcon } from '@components/icons';
import { RegisteredError } from '@components/resource';
import {
  ABTestingRoutes,
  AppLinksRoutes,
  LocalisationsRoutes,
} from '@pages/routes';

import Creative from './Creative';

export default function CreativesList({
  triggers,
  eventsConfigurationsOptions,
  sequentialOffersOptions,
  creativeImagesOptions,
  creativeTemplates,
}) {
  const [currentCreativeIndex, setCurrentCreativeIndex] = useState();
  const { readOnly } = useFormPermissions();

  const { localizedEnums } = useAppData();
  const contentTypes = localizedEnums['Campaigns::CreativeContentTypes'];

  const router = useRouter();
  const { query } = router;
  const { watch, formState: { errors }, getValues } = useFormContext();
  const { generateName } = useFormGroup();
  const formGroupName = generateName('creativesAttributes');
  const {
    fields, append, handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const { response: appLinkConfigurations } = useQuery(
    AppLinksRoutes.Configurations.indexRequest,
    { ...query },
  );
  const { response: experiments } = useQuery(
    ABTestingRoutes.Experiments.indexRequest,
    { ...query, withoutPagination: true },
  );
  const { response: localisationKeys } = useQuery(
    LocalisationsRoutes.Keys.indexRequest,
    { ...query, deleted: false, withoutPagination: true },
  );

  const params = useLocationQuery();

  const handleCreativeAdd = () => {
    append({
      experiments: [],
      priority: null,
      variantsAttributes: [
        {
          enabled: true,
          impressionsCap: 0,
          impressionsCapPerDuration: 0,
          triggerCooldown: 0,
          duration: 0,
          durationCooldown: 0,
          availabilityCap: 0,
          availabilityCapPerDuration: 0,
          contentType: 'popup',
          chainedBehaviour: 'not_purchased',
          chainedRotateEnabled: true,
        },
      ],
    });
    updateQuery(router, { creativeId: null, variantId: null });
    setCurrentCreativeIndex(getValues(formGroupName).length - 1);
  };
  const handleCreativeEdit = (creative, index) => {
    setCurrentCreativeIndex(index);
    updateQuery(router, { creativeId: creative.id, variantId: null });
  };
  const isSelectedCreative = (index) => currentCreativeIndex === index;

  useEffect(() => {
    const creativeId = params.get('creativeId');
    if (creativeId) {
      setCurrentCreativeIndex(fields.findIndex(({ id }) => id === +creativeId));
    }
  }, [JSON.stringify(fields.map(({ key }) => key)), params.get('creativeId')]);

  return experiments && appLinkConfigurations && localisationKeys && (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Priority</th>
            <th>Content Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((creative, index) => {
            const rowClasses = [];
            if (isSelectedCreative(index)) rowClasses.push('table-primary');
            const baseGroup = `${formGroupName}.${index}`;
            const hasError = (get(errors, baseGroup));
            const priority = watch(`${baseGroup}.priority`);
            const contentType = contentTypes[watch(`${baseGroup}.variantsAttributes.0.contentType`).toUpperCase()];

            return creative._destroy || (
              <tr key={creative.key} className={rowClasses.join(' ')}>
                <td>
                  {creative.id}
                  {hasError && (
                    <>
                      <ErrorIcon className="ms-1" />
                      <RegisteredError name={`creativesAttributes.${index}.priority`} className="d-none" />
                    </>
                  )}
                </td>
                <td>{isNaN(priority) || priority}</td>
                <td>{contentType}</td>
                <td>
                  <Button.Edit onClick={() => handleCreativeEdit(creative, index)} title="Edit" />
                  {!readOnly && (
                    <Button.Delete onClick={() => handleDelete(creative, index)} title="Remove" />
                  )}
                </td>
              </tr>
            );
          })}
          {!readOnly && (
            <tr>
              <td colSpan={4}>
                <Button.Add size="sm" onClick={handleCreativeAdd} />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {fields.map((creative, index) => (
        isSelectedCreative(index) && (
          <FormGroup key={creative.key} name={`creativesAttributes.${index}`}>
            <Creative
              experiments={experiments.items}
              triggers={triggers}
              eventsConfigurationsOptions={eventsConfigurationsOptions}
              sequentialOffersOptions={sequentialOffersOptions}
              creativeImagesOptions={creativeImagesOptions}
              creativeTemplates={creativeTemplates}
              appLinkConfigurations={appLinkConfigurations.items}
              localisationKeys={localisationKeys.items}
            />
          </FormGroup>
        )
      ))}
    </>
  );
}
