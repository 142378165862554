import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { toNumber, toInteger, isNil } from 'lodash';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useFormContext } from 'react-hook-form';
import { useFormPermissions } from '@hooks';

import Label from './Label';
import FloatingLabel from './FloatingLabel';
import { RegisteredError } from './Error';

const TimeInputGroup = ({ text, children, floatingLabelOptions }) => (
  <InputGroup className="mb-3">
    {floatingLabelOptions && <FloatingLabel className="" {...floatingLabelOptions}>{children}</FloatingLabel>}
    {!floatingLabelOptions && children}
    <InputGroup.Text>{text}</InputGroup.Text>
  </InputGroup>
);

// supported inputs ['days', 'hours', 'minutes', 'seconds']
const TimePeriodField = ({
  name, disabled, onKeyPress, onBlur, autoFocus, inputs = ['hours', 'minutes'], floatingLabelOptions,
  onChange, emptyIfNotSet = false,
}) => {
  const { getValues, setValue } = useFormContext();
  const { generateName } = useFormGroup();
  const fullName = generateName(name);
  const firstInputRef = useRef();
  const { readOnly } = useFormPermissions();

  const [secondsValue, setSecondsValue] = useState(getValues(fullName));

  const [days, setDays] = useState(null);
  const [hours, setHours] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [seconds, setSeconds] = useState(null);

  const [
    showDays,
    showHours,
    showMinutes,
    showSeconds,
  ] = ['days', 'hours', 'minutes', 'seconds'].map((input) => inputs.includes(input));

  const applyChanges = () => {
    const totalSeconds = (toInteger(days) * 24 * 60 * 60)
      + (toInteger(hours) * 60 * 60)
      + (toInteger(minutes) * 60)
      + toNumber(seconds || 0);

    setValue(fullName, totalSeconds, { shouldDirty: true, shouldTouch: true });
    setSecondsValue(totalSeconds);
    if (onChange) try { onChange(totalSeconds); } catch (e) { console.error(e); }
  };

  useEffect(() => {
    let initialValue = secondsValue;

    const extractTime = (divider) => {
      if (isNil(secondsValue) && emptyIfNotSet) { return null; }

      const time = toInteger(initialValue / divider);
      initialValue -= (time * divider);

      return time;
    };

    setDays(showDays ? extractTime(60 * 60 * 24) : 0);
    setHours(showHours ? extractTime(60 * 60) : 0);
    setMinutes(showMinutes ? extractTime(60) : 0);
    setSeconds(initialValue);
  }, [secondsValue]);

  useEffect(() => {
    if (firstInputRef.current) firstInputRef.current.focus();
  }, []);

  const handleChange = (fn) => ({ target: { value: newValue } }) => fn(newValue);

  const inputProps = (inputName) => ({
    type: 'number',
    step: '1',
    onBlur: () => {
      if (onBlur) onBlur();
      applyChanges();
    },
    disabled,
    onKeyPress: (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        applyChanges();
      }
      if (onKeyPress) { onKeyPress(event); }
    },
    ...autoFocus && inputs[0] === inputName ? { ref: firstInputRef } : {},
  });

  return (
    <>
      <div className="d-flex gap-1">
        {showDays && (
          <TimeInputGroup floatingLabelOptions={floatingLabelOptions} text="days">
            <FormControl
              value={days}
              onChange={handleChange(setDays)}
              {...inputProps('days')}
              disabled={readOnly || disabled}
            />
          </TimeInputGroup>
        )}
        {showHours && (
          <TimeInputGroup floatingLabelOptions={!showDays && floatingLabelOptions} text="hours">
            <FormControl
              value={hours}
              onChange={handleChange(setHours)}
              {...inputProps('hours')}
              disabled={readOnly || disabled}
            />
          </TimeInputGroup>
        )}
        {showMinutes && (
          <TimeInputGroup floatingLabelOptions={!showHours && floatingLabelOptions} text="minutes">
            <FormControl
              value={minutes}
              onChange={handleChange(setMinutes)}
              {...inputProps('minutes')}
              disabled={readOnly || disabled}
            />
          </TimeInputGroup>
        )}
        {showSeconds && (
          <TimeInputGroup floatingLabelOptions={!showMinutes && floatingLabelOptions} text="seconds">
            <FormControl
              value={seconds}
              onChange={handleChange(setSeconds)}
              {...inputProps('seconds')}
              disabled={readOnly || disabled}
            />
          </TimeInputGroup>
        )}
      </div>
      <RegisteredError name={name} />
    </>
  );
};

const LabeledTimePeriodField = ({
  label, sizes, tooltipText, labelProps = {}, ...rest
}) => (
  <Label label={label} sizes={sizes} tooltipText={tooltipText} {...labelProps}>
    <TimePeriodField {...rest} />
  </Label>
);

const FloatingLabeledTimePeriodField = ({
  label, tooltipText, sizes: _sizes, ...rest
}) => (
  <TimePeriodField floatingfloatingLabelOptions={{ label, tooltipText }} {...rest} />
);

export { LabeledTimePeriodField, FloatingLabeledTimePeriodField, TimePeriodField };
