import React, { useState } from 'react';
import {
  Row, Col, Badge, Modal, Image, Table, Button as BsButton,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useRouter } from '@tripledotstudios/react-core';
import { Status } from '@components/collection';
import { Label, SelectWithAddon } from '@components/resource';
import { Button, ImageSquare, InUse } from '@components';
import { ShowIcon } from '@components/icons';
import { CreativesRoutes } from '@pages/routes';

const PreviewImage = styled(Image)`
  max-height: calc(100vh - 20rem);
`;

const ColumnContent = ({ item }) => (
  <tr key={item.id}>
    <td>{item.id}</td>
    <td>{item.createdAt}</td>
    <td><Status value={item.status} /></td>
    <td><InUse.InLiveBadge inLive={item.availabilityStateLive} /></td>
  </tr>
);

const VersionsTable = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const maxItemsToShow = 10;

  const handleShowMoreClick = () => setShowMore(!showMore);

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Created at (UTC)</th>
            <th>Status</th>
            <th><InUse.InLiveColumnHeader /></th>
          </tr>
        </thead>
        <tbody>
          {data.map((templateVersion, index) => (
            (showMore || index < maxItemsToShow) && (
              <ColumnContent key={templateVersion.id} item={templateVersion} />
            )
          ))}
        </tbody>
      </Table>
      {data.length > maxItemsToShow && (
        <BsButton variant="light" size="sm" onClick={handleShowMoreClick}>
          {showMore ? 'Show less' : 'Show more...'}
        </BsButton>
      )}
    </>
  );
};

const PreviewModal = ({ showModal, template, handleClose }) => {
  if (!template) return null;

  const { query } = useRouter();
  const templateUrl = CreativesRoutes.Templates.editPath({ applicationId: query.applicationId, id: template.id });

  return (
    <Modal show={!!showModal} onHide={handleClose} dialogClassName="modal-80w">
      <Modal.Header closeButton>
        <Modal.Title>
          Preview
          {' '}
          {template?.internalName}
          {' '}
          image and versions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={8}>
            <div className="mb-2">
              <Link to={templateUrl} target="_blank">
                Go to template
              </Link>
            </div>
            <div className="mb-2">
              Min supported version:
              {' '}
              {template.minSupportedVersion || 'None'}
            </div>
            {template?.templateVersionsAttributes && (
              <VersionsTable data={template.templateVersionsAttributes} />
            )}
          </Col>
          <Col md={4}>
            <PreviewImage className="img-fluid d-flex m-auto" src={template?.previewImageUrl} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default function CreativeTemplateSelect({
  label: labelText, sizes, tooltipText, displayType = false, template, ...props
}) {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const creativeTemplatesLabelFormat = ({
    label, previewImageUrl, availabilityState, availabilityStateLive, typeNameHumanized,
  }) => (
    <div>
      {label}
      {previewImageUrl && <ImageSquare className="ms-2" size="25px" src={previewImageUrl} />}
      {' '}
      <div className="align-self-center float-end">
        {displayType && typeNameHumanized && (
          <Badge bg="warning" className="me-1">
            {typeNameHumanized}
          </Badge>
        )}
        <Badge bg={availabilityStateLive ? 'success' : 'info'}>
          {availabilityState}
        </Badge>
      </div>
    </div>
  );
  const handlePreview = () => template && setShowPreviewModal(true);

  return (
    <>
      <Label label={labelText} sizes={sizes} tooltipText={tooltipText}>
        <SelectWithAddon
          {...props}
          formatOptionLabel={creativeTemplatesLabelFormat}
          after={(
            <Button.Action.Transparent
              onClick={handlePreview}
              title="Preview"
              icon={<ShowIcon color="var(--bs-body-color)" />}
            />
          )}
        />
      </Label>
      <PreviewModal showModal={showPreviewModal} handleClose={() => setShowPreviewModal(false)} template={template} />
    </>
  );
}
