import { useEffect } from 'react';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRouter } from '@tripledotstudios/react-core';

const currentLocation = () => window.location.pathname + window.location.search;

const useCrudlRequests = (Routes, setResource, refetch, callbacks = {}, fetchDependencies = []) => {
  const router = useRouter();
  const { query } = router;
  const pathParams = useParams();
  const searchQuery = omit(query, Object.keys(pathParams));

  const fetchResource = (params) => {
    Routes.editRequest(params).then((res) => {
      setResource({
        data: res.data,
        request: Routes.updateRequest,
        actionName: 'update',
      });
    });
  };

  useEffect(() => {
    if (typeof Routes.editMatchPath === 'function' && Routes.editMatchPath(router.pathname)) {
      if (typeof callbacks.beforeEdit === 'function') callbacks.beforeEdit();

      fetchResource(query);
    } else if (typeof Routes.newMatchPath === 'function' && Routes.newMatchPath(router.pathname)) {
      if (typeof callbacks.beforeNew === 'function') callbacks.beforeNew();

      Routes.newRequest(query).then((res) => setResource({
        data: res.data || {},
        request: Routes.createRequest,
        actionName: 'create',
      }));
    }
  }, [router.pathname, ...fetchDependencies]);

  const pushIfChanged = (newPath) => {
    if (newPath !== currentLocation()) {
      router.push(newPath);
      // need this to check if we really moved to the new location or RouteLeavingGuard stopped it with modal
      if (newPath === currentLocation()) {
        setResource(null);
      }
    }
  };

  const handleNew = (_e, params = {}) => { pushIfChanged(Routes.newPath(query, { ...searchQuery, ...params })); };
  const handleEdit = (id) => { pushIfChanged(Routes.editPath({ ...query, id }, searchQuery)); };
  const handleOnCancel = () => { pushIfChanged(Routes.indexPath(query, searchQuery)); };
  // TODO: Call handleOnCancel only when current resource deleted
  const handleDelete = () => { refetch(); handleOnCancel(); };
  const handleDiscard = (newPath) => {
    setResource(null);
    pushIfChanged(newPath);
  };
  const handleSave = ({ data: { id } }) => {
    refetch();
    setResource(null);
    const params = { ...query, id };
    const newPath = Routes.editPath(params, searchQuery);

    if (newPath === currentLocation()) {
      fetchResource(params);
    } else {
      router.push(newPath);
    }
  };

  return {
    handleNew, handleEdit, handleDelete, handleOnCancel, handleDiscard, handleSave,
  };
};

export default useCrudlRequests;
