import React, { useCallback, useMemo } from 'react';
import { Badge } from 'react-bootstrap';
import { useQuery } from '@hooks';
import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import {
  Pagination,
  PageHeader,
  Button,
  InUse,
  Pane,
} from '@components';
import { Table } from '@components/collection';
import { AssetBundlesRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';
import { hasApplicationPermissions } from '@services/permissions';
import Filters from './Filters';

const Routes = AssetBundlesRoutes;

export default function Index() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { includeInUse: true }, [query]);
  const canSynchronize = hasApplicationPermissions(['asset_bundle', 'synchronize']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'UUID',
        accessor: 'uuid',
      },
      {
        Header: 'External ID',
        accessor: 'externalId',
      },
      {
        Header: 'Info',
        accessor: 'info',
      },
      {
        Header: 'Types',
        Cell: ({ row: { original: { types } } }) => types.map((type) => (
          <Badge key={type} bg="info" className="me-1">{type}</Badge>
        )),
      },

      InUse.InUseColumn,

      {
        Header: 'Deleted',
        Cell: ({ row: { original: { deleted } } }) => deleted && 'yes',
      },
      {
        Header: 'Synchronized at (UTC)',
        accessor: 'updatedAt',
      },
    ],
    [],
  );

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeAssetBundles = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    response && (
      <>
        <PageHeader title="Asset Bundles">
          <Filters refetch={refetch} />
          {canSynchronize && <Button.Synchronize onClick={handleSynchronizeAssetBundles} />}
        </PageHeader>
        <Pane>
          <Table columns={columns} data={response.items} />
          <Pagination
            pageCount={response._meta.pages}
            refetch={refetch}
          />
        </Pane>
      </>
    )
  );
}
