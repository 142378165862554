import React from 'react';
import { Modal } from 'react-bootstrap';

import { buildResourceResponseHandler } from '@services/response_handler';
import Button from './buttons';
import Form from './Form';

const DuplicationModal = ({
  handleClose, record, submitRequest, onSuccess, children,
}) => (
  <Modal show={!!record} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Duplicate record</Modal.Title>
    </Modal.Header>
    <Form defaultValues={record}>
      {({ setError }) => {
        const responseHandler = buildResourceResponseHandler({
          actionName: 'duplicate',
          setError,
          onSuccess,
        });
        const onSubmit = (values) => submitRequest(values).then(responseHandler);

        return (
          <Form.Component onSubmit={onSubmit}>
            <Modal.Body>
              {children}
            </Modal.Body>
            <Modal.Footer>
              <Button.Cancel onClick={handleClose} />
              <Button.Submit />
            </Modal.Footer>
          </Form.Component>
        );
      }}
    </Form>
  </Modal>
);

export default DuplicationModal;
