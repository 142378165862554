import React from 'react';
import { useAppData } from '@hooks';
import { FormGroup } from '@tripledotstudios/react-core';
import { Input, LabeledSelect, DiffWrapper } from '@components/resource';
import tooltipFor from '@components/creatives/CreativesTooltipForLayout';

export default function CreativeLayout({
  layoutDataAttributeName,
  template,
  layoutDataAttributes,
  localisationKeys,
}) {
  const localisationKeysOptions = localisationKeys.map(({ id, name }) => ({ label: name, value: id }));
  const layoutTypes = useAppData().enums['Creatives::LayoutTypes'];

  return (
    <>
      {layoutDataAttributes.map((creativeLayoutsData, index) => creativeLayoutsData._destroy || (
        <FormGroup
          key={creativeLayoutsData.key}
          name={`${layoutDataAttributeName}.${index}`}
        >
          <Input type="hidden" name="internalName" />
          <Input type="hidden" name="layoutType" />

          {creativeLayoutsData.layoutType === layoutTypes.TEXT_LOCALISED && (
            <DiffWrapper for="entityId">
              <LabeledSelect
                label={creativeLayoutsData.internalName}
                tooltipText={tooltipFor(template, creativeLayoutsData.internalName)}
                name="entityId"
                options={localisationKeysOptions}

              />
            </DiffWrapper>
          )}
        </FormGroup>
      ))}
    </>
  );
}
