import React from 'react';
import { Sidebar } from '@tripledotstudios/react-core';

import { hasApplicationPermissions, hasPermissions } from '@services/permissions';

/**
 * @template T
 * @type {T extends true ? AppScopedPermissionsPath : AppGlobalPermissionsPath} PermissionsPath
 */

/**
 * @typedef {Object} Props
 * @property {boolean} [showIf=true]
 * @property {boolean} [applicationScoped=true]
 * @property {string} permissionsPath
 * @property {...Record<string, any>} rest
 */

/**
 * @typedef AppScopedProps
 * @property {true} applicationScoped
 * @property {AppScopedPermissionsPath} permissionsPath
 */

/**
 * @typedef GlobalProps
 * @property {false} applicationScoped
 * @property {AppGlobalPermissionsPath} permissionsPath
 */

/**
 * @param {Props & (AppScopedProps | GlobalProps)} props
 */
export default function AuthorizedLinkItem({
  permissionsPath,
  showIf = true,
  applicationScoped = true,
  ...rest
}) {
  const permissionScopeMethod = applicationScoped ? hasApplicationPermissions : hasPermissions;

  if (!showIf) {
    return null;
  }

  return permissionScopeMethod(permissionsPath) && <Sidebar.LinkItem {...rest} />;
}
