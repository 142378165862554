import React, { useEffect } from 'react';

import { SignInButton } from '@components';
import {
  useAuth, useRouter, useFlashMessages,
} from '@tripledotstudios/react-core';

export default function SignIn() {
  const auth = useAuth();
  const router = useRouter();
  const { error } = useFlashMessages();

  useEffect(() => {
    if (router.query.user_name) {
      auth.login(router.query);
    }

    if (router.query.session_expired) {
      error('Your session has expired. Please log in again to continue working.');
    }

    if (auth.user) {
      router.push('/admin');
    }
  }, []);

  return <SignInButton href="/auth/sso" />;
}
