import React from 'react';
import { Container } from 'react-bootstrap';

import { useAppData } from '@hooks';
import { Form, InUse } from '@components';
import {
  FloatingLabeledInput, FloatingLabeledSelect, FloatingLabeledDateTimeInput,
} from '@components/resource';
import Segments from './form/Segments';

export default function ConfigurationsForm(props) {
  const configurationStatuses = useAppData().enumOptions['PiggyBanks::ConfigurationStatuses'];

  return (
    <Form.Resource {...props}>
      <Container fluid className="form-container">
        <Form.ControlsLayout>
          <FloatingLabeledInput label="Name" name="name" />
          <FloatingLabeledInput label="Priority" name="priority" />
          <FloatingLabeledSelect label="Status" name="status" options={configurationStatuses} />
          <FloatingLabeledDateTimeInput label="Start At" name="startAt" />
          <FloatingLabeledDateTimeInput label="End At" name="endAt" />
          <InUse.AvailabilityStateFormGroup floating />
        </Form.ControlsLayout>

        <hr />

        <Segments />
      </Container>
    </Form.Resource>
  );
}
