import styled from 'styled-components';

const WrappedSegmentsSelect = styled.div`
  .react-select__multi-value:not(:first-child) {
    &::before {
      content: 'OR';
      padding-top: 0.2rem;
      padding-right: 0.3em;
      background-color: white;
    }
  }
`;

export default WrappedSegmentsSelect;
