import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Accordion, Button as BsButton } from 'react-bootstrap';
import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';
import { PositionControl } from '@components/resource';
import { Button } from '@components/index';
import { useExtendedFieldArray, useFormPermissions, useMoveArrayFieldPosition } from '@hooks';

const AccordionItem = styled(Accordion.Item)`
  border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
`;

// This function copies a record and clears internal ids. It saves us from errors like this following:
// Couldn't find Events::ConfigurationObjective with ID=1020 for Events::ActivitySetIteration with ID=
function copyRecord(record) {
  const { id, key, ...values } = record;

  Object.keys(values).forEach((attribute) => {
    if (!attribute.endsWith('Attributes')) return;

    if (Array.isArray(record[attribute])) {
      values[attribute] = record[attribute].map((attrs) => copyRecord(attrs));
    } else {
      values[attribute] = copyRecord(record[attribute]);
    }
  });

  return values;
}

export default function NestedRecordsList({
  attributesPath, entityName, bodyContent, headerContent, buildNewItem, addDisabled,
}) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const { getValues, watch } = useFormContext();
  const formGroupName = generateName(attributesPath);
  const [activeKeys, setActiveKeys] = useState([]);
  const [appendedItemPosition, setAppendedItemPosition] = useState(null);

  const {
    fields,
    append,
    replace,
    handleDelete,
  } = useExtendedFieldArray({
    name: formGroupName,
    keyName: 'key',
  });

  const fieldsWatch = watch(formGroupName);
  const controlledFields = fields.map((field, i) => ({ ...field, ...fieldsWatch[i] }));

  const {
    sortedFields,
    increasePosition,
    decreasePosition,
    resetPositions,
    move,
  } = useMoveArrayFieldPosition({ fields: controlledFields, replace, formGroupName });
  const persistedFields = sortedFields.filter((f) => !f._destroy);
  useEffect(() => {
    if (persistedFields.length > 0) return;

    resetPositions();
  }, [persistedFields.length > 0]);

  useEffect(() => {
    const appendedItem = sortedFields[appendedItemPosition - 1];
    if (appendedItem) {
      setActiveKeys((keys) => [...keys, appendedItem.key]);
      setAppendedItemPosition(null);
    }
  }, [appendedItemPosition]);

  const appendItem = () => {
    const position = increasePosition();
    append({ ...buildNewItem(), position });
    setAppendedItemPosition(position);
  };

  return (
    <>
      <Accordion alwaysOpen activeKey={activeKeys} onSelect={setActiveKeys}>
        {sortedFields.map((field, index) => {
          if (field._destroy) { return null; }

          const itemPath = `${formGroupName}.${index}`;
          const duplicateItem = () => {
            const position = increasePosition();
            append({ ...copyRecord(getValues(itemPath)), position });
            setAppendedItemPosition(position);
          };
          const deleteItem = () => {
            decreasePosition(field);
            handleDelete(field, index);
          };
          return (
            <FormGroup name={itemPath} key={`${field.key}-${field.position}`}>
              <div className="d-flex">
                <PositionControl
                  onUp={() => move('up', field.key)}
                  onDown={() => move('down', field.key)}
                  disableUp={field.position === 1}
                  disableDown={field.position === persistedFields.length}
                />
                <AccordionItem eventKey={field.key} className="w-100">
                  <Accordion.Header>
                    {headerContent({ item: field })}
                  </Accordion.Header>
                  <Accordion.Body>
                    {bodyContent({ item: field })}
                    <div className="mt-2 d-flex justify-content-end">
                      <BsButton className="me-2" variant="primary" onClick={duplicateItem}>
                        Duplicate
                      </BsButton>
                      <BsButton variant="danger" onClick={deleteItem}>
                        Delete
                      </BsButton>
                    </div>
                  </Accordion.Body>
                </AccordionItem>
              </div>
            </FormGroup>
          );
        })}
      </Accordion>
      {!readOnly && (
        <Button.Add title={`Add ${entityName}`} className="mt-2" onClick={appendItem} disabled={addDisabled} />
      )}
    </>
  );
}
