import { useState } from 'react';
import { isEmpty } from 'lodash';
import { PageSection, FormGroup, useFormGroup } from '@tripledotstudios/react-core';
import { useExtendedFieldArray } from '@hooks';

import { Form, Weights } from '@components';
import {
  Label,
  FloatingLabeledInput,
  Input,
  RegisteredError,
} from '@components/resource';

function RarityLabel({ rarity }) {
  const { bg, text } = Weights.getIndexColors(rarity);

  return (
    <div className="rounded-5 px-4 text-center" style={{ backgroundColor: bg, color: text }}>
      {rarity}
    </div>
  );
}

function Items() {
  const [activeKey, setActiveKey] = useState(null);
  const { generateName } = useFormGroup();
  const formGroupName = generateName('itemsAttributes');
  const { fields } = useExtendedFieldArray({ name: formGroupName });

  return (
    <PageSection title="Probabilities">
      <Weights
        name={formGroupName}
        activeKey={activeKey}
        transformData={({ rarity, weight }, index) => ({
          key: fields[index].id,
          index,
          weight,
          title: `Item Rarity ${rarity}`,
        })}
      />
      <div className="grid align-items-baseline" style={{ gridTemplateColumns: 'max-content 1fr' }}>
        <Label label="Item rarity" />
        <Label label="Weight" />

        {fields.map((field, index) => {
          const itemPath = `${formGroupName}.${index}`;

          return (
            <FormGroup key={field.id} name={itemPath}>
              <div>
                <RarityLabel rarity={field.rarity} />
                <Input name="rarity" hidden />
              </div>
              <div>
                <Input
                  name="weight"
                  type="number"
                  onFocus={() => setActiveKey(field.id)}
                  onBlur={() => setActiveKey(null)}
                />
              </div>
            </FormGroup>
          );
        })}
      </div>
    </PageSection>
  );
}

export default function DropProbabilitiesForm({ resource, ...rest }) {
  const inUse = !isEmpty(resource.data.inUse);

  return (
    <Form.Resource resource={resource} {...rest}>
      <RegisteredError name="base" />
      <Form.ControlsLayout>
        <FloatingLabeledInput label="Name" disabled={inUse} name="name" />
      </Form.ControlsLayout>
      <Items />
    </Form.Resource>
  );
}
