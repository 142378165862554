import React from 'react';

import { Alert } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import PrettyJsonDisplay from './PrettyJsonDisplay';

export default function ExampleJsonDisplay({ rootKey, exampleKey, exampleData }) {
  const { description, args } = isEmpty(exampleData) ? {} : exampleData;
  const element = { key: exampleKey };

  if (!isEmpty(args)) element.arguments = args;

  const data = { [rootKey]: [element] };

  return (
    <div className="mt-3">
      {description && (
      <Alert variant="primary">
        <Alert.Heading>Description</Alert.Heading>
        <hr />
        {description && <p>{description}</p>}

      </Alert>
      )}
      {args && (
      <Alert variant="primary">
        <Alert.Heading>JSON Example</Alert.Heading>
        <hr />
        <PrettyJsonDisplay data={data} />
      </Alert>
      )}
    </div>
  );
}
