import React, { useState, useEffect } from 'react';
import {
  Tab, Row, Col, Nav,
} from 'react-bootstrap';
import { each } from 'lodash';
import styled from 'styled-components';
import {
  Tooltip, useRouter, FormGroup, updateQuery,
} from '@tripledotstudios/react-core';

import { useLocationQuery } from '@hooks';
import { hasError } from '@components/resource';
import CatalogPresetVariants from './CatalogPresetVariants';

const ActivePresetBadge = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  background-color: rgb(46, 184, 92)!important;
  border: 1px solid #ebedef !important;
  padding: 0.3rem !important;
  border-radius: 50% !important;
  transform: translate(-50%, -50%) !important;
`;

export default function CatalogPresetsTabs({
  products, storeProducts, catalogPresetsAttributes, experiments, productLabels,
}) {
  const router = useRouter();
  const [activeKey, setActiveKey] = useState();
  const buildPresetTabKey = (index) => `catalog_${index}`;
  const params = useLocationQuery();

  const handleSelectTab = (key) => {
    setActiveKey(key);
    const activePreset = catalogPresetsAttributes[+key.split('_')[1]];
    if (activePreset) updateQuery(router, { catalogPresetId: activePreset.id, variantId: null });
  };

  useEffect(() => {
    if (!catalogPresetsAttributes || catalogPresetsAttributes.length < 1) {
      return;
    }
    const catalogPresetId = params.get('catalogPresetId');
    if (catalogPresetId) {
      each(catalogPresetsAttributes, ({ id }, index) => {
        if (id === +catalogPresetId) {
          setActiveKey(buildPresetTabKey(index));
        }
      });
    } else if (!activeKey) {
      // set first as default when no any preset in params
      handleSelectTab(buildPresetTabKey(0));
    }
  }, [catalogPresetsAttributes, params.get('catalogPresetId')]);

  return (
    <Tab.Container
      activeKey={activeKey}
      onSelect={handleSelectTab}
    >
      <Row>
        <Col xl={1} sm={2}>
          <Nav variant="pills" className="flex-column">
            {catalogPresetsAttributes.map((catalogPreset, index) => (
              <Nav.Item key={buildPresetTabKey(index)}>
                <Nav.Link
                  className={`position-relative ${hasError(`catalogPresetsAttributes.${index}`) ? 'has-errors' : ''}`}
                  eventKey={buildPresetTabKey(index)}
                >
                  {catalogPreset.name}
                  {catalogPreset.active
                    && (
                    <Tooltip text="Activated and live by a campaign" placement="top"><ActivePresetBadge /></Tooltip>
                    )}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col xl={11} sm={10}>
          <Tab.Content>
            {catalogPresetsAttributes.map((_, index) => (
              <Tab.Pane
                key={buildPresetTabKey(index)}
                eventKey={buildPresetTabKey(index)}
              >
                <FormGroup name={`catalogPresetsAttributes.${index}`}>
                  <CatalogPresetVariants
                    products={products}
                    storeProducts={storeProducts}
                    productLabels={productLabels}
                    experiments={experiments}
                  />
                </FormGroup>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
}
