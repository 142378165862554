import React from 'react';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Sidebar, useAuth, useRouter } from '@tripledotstudios/react-core';

import { UserRoutes } from '@pages/routes';

export default function UserSectionItem({ minimized }) {
  const auth = useAuth();
  const router = useRouter();

  const afterSignOut = () => {
    auth.logout();
    router.push('/admin/logged_out');
  };

  const signOutUser = () => {
    UserRoutes.signOutRequest().then(afterSignOut);
  };

  return (
    <Sidebar.SectionItem icon={faUser} title={auth.user.user_name} minimized={minimized}>
      <Sidebar.ActionItem icon={faSignOutAlt} title="Sign Out" onClick={signOutUser} />
    </Sidebar.SectionItem>
  );
}
