import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledButton = styled(Button)`
  ${({ color, bordercolor }) => color && (`
    border-color: ${bordercolor || color} !important;
    background-color: ${color}!important;
  `)}

  ${({ textcolor }) => textcolor && (`color: ${textcolor};`)}

  &:hover, &:focus {
    ${({ textcolor }) => textcolor && (`color: ${textcolor};`)}
  }
`;

const ActionButton = ({
  title,
  icon,
  textcolor = 'white',
  ...props
}) => (
  <StyledButton textcolor={textcolor} {...props}>
    {icon}
    {' '}
    {title}
  </StyledButton>
);

ActionButton.Transparent = ({
  color = 'transparent',
  textcolor = 'var(--bs-body-color)',
  bordercolor = 'var(--bs-border-color)',
  ...rest
}) => (
  <ActionButton color={color} textcolor={textcolor} bordercolor={bordercolor} {...rest} />
);

export default ActionButton;
