import { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { Button, InUse } from '@components';
import { hasApplicationPermissions } from '@services/permissions';

export default function List({
  response,
  onEditButtonClick,
  routes, onDelete,
  onDuplicateButtonClick,
  selectedResourceId,
}) {
  const canCreate = hasApplicationPermissions(['events', 'race', 'write']);

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },

      InUse.InUseColumn,
      InUse.AvailabilityStateColumn,

      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id,
              name,
              priority,
              permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {canCreate && (
              <Button.Duplicate
                className="me-2"
                onClick={() => onDuplicateButtonClick({ id, name, priority })}
              />
            )}
            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Events Race "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No races yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
