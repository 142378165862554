import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { IconTooltip } from '@tripledotstudios/react-core';

const StyledLabel = styled.label`
  z-index: 1 !important; // override default value 2 to fix overlapping with react select
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
`;

const StyledFLoating = styled(Form.Floating)`
  // min-height: 35px;
`;

export default function FloatingLabel({
  label, htmlFor, tooltipText = null, children, ...rest
}) {
  const labelContent = tooltipText ? (
    <IconTooltip.Help text={tooltipText}>{label}</IconTooltip.Help>
  ) : label;

  return (
    <StyledFLoating className="mb-1 mt-2" {...rest}>
      {children}
      <StyledLabel htmlFor={htmlFor}>{labelContent}</StyledLabel>
    </StyledFLoating>
  );
}
