import React from 'react';
import { IconTooltip } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';

export default function InLiveColumnHeader() {
  const availabilityStateTooltip = useAppData().i18n.tooltips.availability_state;

  return (
    <IconTooltip.Help text={availabilityStateTooltip}>
      Availability
    </IconTooltip.Help>
  );
}
