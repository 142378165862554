import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { each } from 'lodash';
import { Form, ExampleJsonDisplay } from '@components';
import { LabeledInput, LabeledSelect } from '@components/resource';
import { useAppData } from '@hooks';
import ArgumentsForm from './ArgumentsForm';

export default function TriggersForm({ resource, ...rest }) {
  const { enumOptions, enums, i18n: { examples: { triggers } } } = useAppData();
  const triggerTypes = enumOptions['Segmentation::TriggerTypes'];
  const triggerTypesEnum = enums['Segmentation::TriggerTypes'];

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ setValue, watch }) => {
        const onTriggerTypeChange = (value) => {
          setValue('key', (value === triggerTypesEnum.CUSTOM ? '' : value));
          if (value !== triggerTypesEnum.CUSTOM) setValue('triggerArgumentsAttributes', []);
        };
        const isCustomType = watch('triggerType') === triggerTypesEnum.CUSTOM;

        const exampleData = triggers[watch('triggerType')];
        if (isCustomType && exampleData) {
          const defaultArgumentValue = exampleData.default_value;
          exampleData.args = {};
          each(watch('triggerArgumentsAttributes'), ({ key, _destroy }) => {
            if (!_destroy) exampleData.args[key] = defaultArgumentValue;
          });
        }

        return (
          <Row>
            <Col>
              <LabeledInput label="Name" name="name" />
              <LabeledSelect
                label="Trigger Type"
                name="triggerType"
                onChange={onTriggerTypeChange}
                options={triggerTypes}
                disabled={resource.actionName === 'update'}

              />

              {isCustomType && (
                <LabeledInput label="Key" name="key" />
              )}

              <ExampleJsonDisplay
                rootKey="triggers"
                exampleKey={watch('key')}
                exampleData={exampleData}
              />
            </Col>
            <Col>
              <ArgumentsForm newRecord={!resource.data.id} readOnly={!isCustomType} />
            </Col>
          </Row>
        );
      }}
    </Form.Resource>
  );
}
