import React from 'react';
import { useRouter, useConfirm } from '@tripledotstudios/react-core';

import { buildSimpleResponseHandler } from '@services/response_handler';
import ActionButton from './ActionButton';

const ConfirmButton = ({
  title,
  confirmTitle = 'Confirm',
  ButtonComponent = ActionButton,
  confirmText,
  successText,
  request,
  requestParams,
  onSuccess,
  ...props
}) => {
  const router = useRouter();
  const confirm = useConfirm();

  const responseHandler = buildSimpleResponseHandler({ onSuccess, successText });

  const handleClick = () => {
    confirm.showConfirmation({ title: confirmTitle, body: confirmText })
      .then(() => request({ ...router.query, ...requestParams }).then(responseHandler));
  };

  return (
    <ButtonComponent title={title} onClick={handleClick} {...props} />
  );
};

export default ConfirmButton;
