import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${({ size }) => size}rem;
  color: ${({ color }) => color};
`;

const SlashCircleIcon = ({
  size = 1.2,
  color = '#bebebe',
  className,
  ...rest
}) => (
  <StyledIcon color={color} size={size} className={`bi bi-slash-circle ${className}`} {...rest} />
);

export default memo(SlashCircleIcon);
