
export function findById(response, idToFind) {
  const collection = response?.items ? response.items : response;

  return collection.find((item) => +item.id === +idToFind);
}

export function toOptions(
  items,
  {
    labelKey = 'name',
    valueKey = 'id',
    // eslint-disable-next-line no-unused-vars
    transform = (option, _item) => option,
  } = {},
) {
  return items.map((item) => {
    const option = { label: item[labelKey], value: item[valueKey] };

    return transform(option, item);
  });
}
