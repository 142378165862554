import React from 'react';
import { map } from 'lodash';

import { Filters } from '@tripledotstudios/react-core/table';
import { useAppData } from '@hooks';

export default function Filter({ filtersData, showAppFilter }) {
  const { enumOptions: { 'TDS::RailsCore::Enumerations::ActivityLogActions': ActivityLogActions } } = useAppData();
  const { adminEmails, entityTypes, applications } = filtersData;

  const actionTypeOptions = [{ label: 'All', value: '' }, ...ActivityLogActions];
  const applicationsOptions = [
    { label: 'All', value: '' },
    ...map(applications, ((name, id) => ({ label: name, value: id }))),
  ];
  const entityTypeOptions = [
    { label: 'All', value: '' },
    ...entityTypes,
  ];
  const adminEmailsOptions = [
    { label: 'System', value: '0' },
    ...adminEmails.map(({ label, value }) => ({ label, value: value.toString() })),
  ];

  return (
    <Filters.Container>
      <Filters.Select label="Action Type" name="actionEq" options={actionTypeOptions} />
      <Filters.Select label="Entity Type" name="ownerTypeEq" options={entityTypeOptions} />
      <Filters.Text label="Entity ID" name="ownerIdIn" />
      <Filters.Select
        label="Author"
        name="adminUserIdIn"
        placeholder="All"
        options={adminEmailsOptions}
        isMulti
      />
      {showAppFilter && (
      <Filters.Select
        label="Application"
        name="applicationIdEq"
        options={applicationsOptions}
      />
      )}
    </Filters.Container>
  );
}
