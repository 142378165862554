import React from 'react';
import { Route } from 'react-router-dom';

import { ActivityLogRoutes } from '@pages/routes';
import PageTitle from '@pages/PageTitle';

import Index from './Index';

const Routes = ActivityLogRoutes;

export default function ActivityLogsRoot() {
  return [
    <Route
      exact
      key={Routes.indexRawPath}
      path={Routes.indexRawPath}
      element={(
        <>
          <PageTitle text="Activity Log" />
          <Index showAppFilter routes={Routes} />
        </>
      )}
    />,
  ];
}
