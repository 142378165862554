import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { Form, Button } from '@components';
import { Select, Input } from '@components/resource';
import { SearchIcon } from '@components/icons';
import { useRouter } from '@tripledotstudios/react-core';
import { AccountsRoutes } from '@pages/routes';

const Routes = AccountsRoutes.Users;
const fieldOptions = [
  { label: 'IDFV', value: 'idfv' },
  { label: 'Account ID', value: 'account_id' },
  { label: 'Install ID', value: 'install_id' },
  { label: 'User ID', value: 'user_id' },
];

export default function SearchForm({ setResponse }) {
  const { query } = useRouter();

  const responseHandler = (response) => setResponse(response.data);
  const onSubmit = (values) => Routes.searchRequest({ ...values, ...query }).then(responseHandler);

  return (
    <Form defaultValues={{ field: 'idfv' }}>
      <Form.Component disableDirtyChecker onSubmit={onSubmit}>
        <Row>
          <Col xs="5">
            <Select name="field" options={fieldOptions} />
          </Col>
          <Col xs="5">
            <Input name="value" />
          </Col>
          <Col xs="1">
            <Button.Submit title="Search" icon={<SearchIcon />} />
          </Col>
        </Row>
      </Form.Component>
    </Form>
  );
}
