import React from 'react';
import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

import { InUseCollapse, isInUse } from './InUse';

const InUseWrapper = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 40%;
  width: 30rem;

  .accordion-header .accordion-button {
    padding: 0.5rem;
  }

  @media only screen and (max-width: 1020px) {
    min-width: auto;
    max-width: 100%;
  }
`;

const InUseAccordion = ({ title = 'Entities in Use:', inUse }) => {
  if (!isInUse(inUse)) {
    return null;
  }

  return (
    <InUseWrapper>
      <Accordion defaultActiveKey={[]}>
        <Accordion.Item>
          <Accordion.Header>
            {title}
          </Accordion.Header>
          <Accordion.Body>
            <InUseCollapse inUse={inUse} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </InUseWrapper>
  );
};

export default InUseAccordion;
