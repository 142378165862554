import { useEffect, useState } from 'react';

const formatNumber = (number) => (`0${number}`).slice(-2);
const formatTime = (date) => `${formatNumber(date.getUTCHours())}:${formatNumber(date.getMinutes())}`;

export default function UtcClock() {
  const [time, setTime] = useState(formatTime(new Date()));

  useEffect(() => {
    const interval = setInterval(() => setTime(formatTime(new Date())), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return time;
}
