import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useAuth, useMergedRefs } from '@tripledotstudios/react-core';

export default function CreatedByMeFilter() {
  const auth = useAuth();
  const inputRef = useRef(null);

  return (
    <Controller
      name="createdBy"
      render={({
        field: {
          value,
          onChange: onFieldChange,
          ref,
          ...props
        },
      }) => {
        const onChange = (event) => {
          onFieldChange(event.currentTarget.checked && auth.user.id);
          inputRef.current.form.requestSubmit();
        };

        const mergedRef = useMergedRefs([inputRef, ref]);

        return (
          <div>
            <Form.Label className="fw-bold">Created By Me</Form.Label>
            <Form.Check
              type="checkbox"
              ref={mergedRef}
              onChange={onChange}
              {...props}
            />
          </div>
        );
      }}
    />
  );
}
