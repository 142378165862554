import React, { useState, useMemo, useEffect } from 'react';

import { useRequireAuth, useRouter } from '@tripledotstudios/react-core';
import { PageHeader, Button } from '@components';
import { Table } from '@components/collection';
import { AccountsRoutes } from '@pages/routes';

import SearchForm from './SearchForm';

const Routes = AccountsRoutes.Users;

export default function Index() {
  const [response, setResponse] = useState(null);
  const router = useRouter();

  useEffect(
    () => {
      if (response && response.items && response.items.length === 1) {
        router.push(Routes.editPath({ id: response.items[0].id, ...router.query }));
      }
    },
    [response],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Account ID',
        accessor: 'accountId',
      },
      {
        Header: 'Created at (UTC)',
        accessor: 'createdAt',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { id } } }) => (
          <Button.EditLink id={id} routes={Routes} />
        ),
      },
    ],
    [],
  );

  useRequireAuth();

  return (
    <>
      <PageHeader title="Search Players" />
      <SearchForm setResponse={setResponse} />
      <hr />
      {response && <Table emptyPlaceholder="No players for these filters" columns={columns} data={response.items} />}
    </>
  );
}
