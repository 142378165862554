import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup, useRouter } from '@tripledotstudios/react-core';

import { LabeledInput } from '@components/resource';
import { ExperimentTabs } from '@components/ab_testing';

import { CampaignsRoutes } from '@pages/routes';

import CreativeVariant from './CreativeVariant';

export default function Creative({ experiments, ...props }) {
  const { query } = useRouter();
  const { getValues } = useFormContext();
  const { generateName } = useFormGroup();

  const rootPath = generateName('').slice(0, -1);
  const creativeAttributes = getValues(rootPath);
  const { id: campaignId } = getValues();

  const reloadEntityRequest = () => CampaignsRoutes.Configurations.editRequest({ id: campaignId, ...query });

  return (
    <>
      <LabeledInput label="Priority" name="priority" type="number" />
      <ExperimentTabs
        entityId={creativeAttributes.id}
        entityType="Campaigns::Creative"
        experiments={experiments}
        reloadEntityRequest={reloadEntityRequest}
        renderView={({ variantAttributes, defaultVariant }) => (
          <CreativeVariant
            {...props}
            defaultVariantAttributes={!variantAttributes.isDefaultVariant && defaultVariant}
            currentAttributes={variantAttributes}
          />
        )}
      />
    </>
  );
}
