import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { SaveIcon } from '../icons';

const StyledButton = styled(Button)`
  margin-right: 4px;

  &:hover, &:focus {
    color: white;
  }
`;

const SubmitButton = React.forwardRef(({
  title = 'Save',
  icon = <SaveIcon />,
  type = 'submit',
  ...props
}, ref) => (
  <StyledButton ref={ref} type={type} {...props}>
    {icon}
    {' '}
    {title}
  </StyledButton>
));

export default SubmitButton;
