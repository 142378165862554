import React, { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  ${({ opened, rotate }) => opened && `transform: rotate(${rotate}deg)`};
`;

const OpenedIcon = ({
  icon = faArrowRight,
  rotate = 90,
  opened,
  ...rest
}) => (
  <StyledIcon size="1x" opened={opened ? 'true' : undefined} icon={icon} rotate={rotate} {...rest} />
);

export default memo(OpenedIcon);
