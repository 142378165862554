import React, { memo } from 'react';
import { useRouter, FormGroup } from '@tripledotstudios/react-core';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { isDestroyed } from '@components/resource';
import EntitiesLinks, { EntitiesTexts } from './EntitiesLinks';

const Container = styled.div`
  min-height: 200px;
  margin-top: 1rem;
`;

const ExperimentEntities = ({
  variantId, data,
}) => {
  const { query: { applicationId } } = useRouter();

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Container>
      <Table className="table-bordered">
        <thead>
          <tr>
            <th>Variant entities</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((experimentEntity, index) => (
            <FormGroup key={experimentEntity.id} name={index}>
              <tr key={experimentEntity.id} className={isDestroyed(index) && 'table-secondary'}>
                <td>
                  {EntitiesTexts[experimentEntity.entityType](experimentEntity)}
                </td>
                <td>
                  {EntitiesLinks[experimentEntity.entityType](experimentEntity,
                    variantId,
                    applicationId,
                    'edit variant')}
                </td>
              </tr>
            </FormGroup>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default memo(ExperimentEntities);
