import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import { InUse, Button, ImageSquare } from '@components';

export default function List({
  data, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Image',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original: { fileUrl } } }) => <ImageSquare src={fileUrl} size="32px" />,
      },

      InUse.InUseColumn,

      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, name, permissions,
            },
          },
        }) => (
          <>
            {permissions.edit && <Button.Edit className="me-2" onClick={() => onEditButtonClick(id)} />}
            {permissions.destroy && (
              <Button.DeleteConfirm
                id={id}
                routes={routes}
                onDelete={onDelete}
                recordName={`Creatives Image "${name}"`}
              />
            )}
          </>
        ),
      },
    ],
    [],
  );

  if (!data || data.items.length < 1) {
    return <EmptyList title="No creatives images yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={data.items} />
  );
}
