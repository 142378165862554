import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import {
  get, sortBy, some, uniq,
} from 'lodash';
import { useFormContext } from 'react-hook-form';
import { IconTooltip, FormGroup, useFormGroup } from '@tripledotstudios/react-core';

import { useAppData, useCurrentApplication } from '@hooks';
import { Select, Input, InputWithAddon } from '@components/resource';

const StyledTd = styled.td`
${({ differsFromControlCell }) => differsFromControlCell
  && `
    background-color: #fff8e4 !important;

    div[class*="-control"], input.form-control {
      background-color: #fff8e4 !important;
    }

    span.input-group-text {
      background-color: #fff1cd !important;
    }
  `
};
`;

const gameProductsPriceTypes = ['store_product', 'in_game_currency', 'ads'];
const gameProductsSaleTypes = ['none', 'more_value', 'price_cut', 'extra_amount'];

const cashProductsPriceTypes = ['cash'];
const cashProductsSaleTypes = ['none', 'extra_amount'];

const useBaseColumns = (products, storeProducts, productLabels) => {
  const currentApplication = useCurrentApplication();
  const productTypes = useAppData().enums.ApplicationProductsTypes;
  const isGameProductsApplication = currentApplication.productsType === productTypes.GAME_PRODUCTS;

  const productsOptions = useMemo(() => {
    const options = products.filter(({ deleted }) => !deleted).map(({ id, name }) => ({ label: name, value: id }));
    return sortBy(options, ['label']);
  }, [products]);

  const storeProductsOptions = useMemo(() => {
    if (!isGameProductsApplication) {
      return [];
    }
    const options = sortBy(storeProducts, ({ price }) => parseFloat(price));
    return options.map(({ id, price }) => ({ label: price, value: id }));
  }, [storeProducts]);
  const productSaleTypes = useAppData().enumOptions['Payments::CatalogProductSaleTypes'].filter((priceType) => (
    (isGameProductsApplication ? gameProductsSaleTypes : cashProductsSaleTypes).includes(priceType.value)
  ));
  const platforms = [
    { value: '', label: 'All' },
    ...useAppData().enumOptions.ClientPlatforms,
  ];
  const priceTypesEnum = useAppData().enums['Payments::PriceTypes'];
  const priceTypes = useAppData().enumOptions['Payments::PriceTypes'].filter((priceType) => (
    (isGameProductsApplication ? gameProductsPriceTypes : cashProductsPriceTypes).includes(priceType.value)
  ));
  const roundTypes = useAppData().enumOptions['Payments::RoundingTypes'];
  const roundTypesEnum = useAppData().enums['Payments::RoundingTypes'];
  const saleTypesEnum = useAppData().enums['Payments::CatalogProductSaleTypes'];

  const defaultProductValues = {
    priceType: priceTypes[0].value,
    saleType: productSaleTypes[0].value,
    labelId: productLabels[0]?.value,
    purchaseLimit: 0,
  };

  const clearSaleValues = (setValue, rowPath, value) => {
    switch (value) {
      case 'none':
        setValue(`${rowPath}.moreValue`, null);
        setValue(`${rowPath}.saleStoreProductId`, null);
        setValue(`${rowPath}.saleValue`, null);
        setValue(`${rowPath}.extraAmount`, null);
        setValue(`${rowPath}.roundingType`, roundTypesEnum.MATH_ROUND);
        break;
      case 'more_value':
        setValue(`${rowPath}.saleStoreProductId`, null);
        setValue(`${rowPath}.saleValue`, null);
        setValue(`${rowPath}.extraAmount`, null);
        break;
      case 'price_cut':
        setValue(`${rowPath}.moreValue`, null);
        setValue(`${rowPath}.extraAmount`, null);
        setValue(`${rowPath}.roundingType`, roundTypesEnum.MATH_ROUND);
        break;
      case 'extra_amount':
        setValue(`${rowPath}.moreValue`, null);
        setValue(`${rowPath}.saleStoreProductId`, null);
        setValue(`${rowPath}.saleValue`, null);
        setValue(`${rowPath}.roundingType`, roundTypesEnum.MATH_ROUND);
        break;
      default:
    }
  };

  const handlePriceTypeChange = (setValue, rowPath, value) => {
    switch (value) {
      case 'store_product':
        setValue(`${rowPath}.inGameValue`, null);
        setValue(`${rowPath}.saleValue`, null);
        break;
      case 'in_game_currency':
        setValue(`${rowPath}.storeProductId`, null);
        setValue(`${rowPath}.saleStoreProductId`, null);
        break;
      case 'ads':
        setValue(`${rowPath}.storeProductId`, null);
        setValue(`${rowPath}.inGameValue`, null);
        setValue(`${rowPath}.saleType`, 'none');
        clearSaleValues(setValue, rowPath, 'none');
        break;
      default:
    }
  };

  const numberToString = (value) => (isNaN(value) ? '' : value.toString());

  const columns = [
    {
      key: 'id',
      Header: () => 'ID',
      Cell: ({ row: { id }, hasErrors }) => (
        <>
          {id}
          {hasErrors && (
            <IconTooltip.Exclamation text="Product row has errors" placement="right" />
          )}
        </>
      ),
      EditableCell: ({ row: { id } }) => id,
    },
    {
      key: 'productId',
      Header: () => 'Product',
      width: '220px',
      Cell: ({ row: { productId } }) => products.find(({ id }) => productId === id)?.name,
      EditableCell: () => <Select name="productId" options={productsOptions} isSearchable />,
    },

    ...(isGameProductsApplication ? [
      {
        key: 'priceType',
        Header: () => 'Price Type',
        Cell: ({ row: { priceType } }) => priceTypes.find(({ value }) => value === priceType)?.label,
        EditableCell: ({ setValue, rowPath }) => (
          <Select
            name="priceType"
            onChange={(value) => handlePriceTypeChange(setValue, rowPath, value)}
            options={priceTypes}
          />
        ),
      },
    ] : []),

    ...(isGameProductsApplication ? [
      {
        key: 'storeProductId',
        Header: () => 'Price',
        keysToCheck: ['storeProductId', 'inGameValue'],
        Cell: ({ row: { priceType, storeProductId, inGameValue } }) => (
          <>
            {priceType === priceTypesEnum.STORE_PRODUCT
              && storeProducts.find(({ id }) => storeProductId === id)?.price}
            {priceType === priceTypesEnum.IN_GAME_CURRENCY && inGameValue}
          </>
        ),
        EditableCell: ({ row: { priceType } }) => (
          <>
            {priceType === priceTypesEnum.STORE_PRODUCT && (
              <Select name="storeProductId" options={storeProductsOptions} isSearchable />
            )}
            {priceType === priceTypesEnum.IN_GAME_CURRENCY && <Input name="inGameValue" />}
          </>
        ),
      },
    ] : []),

    {
      key: 'platform',
      Header: () => 'Platform',
      Cell: ({ row: { platform } }) => platforms.find(({ value }) => value === (platform || ''))?.label,
      EditableCell: () => <Select name="platform" options={platforms} />,
    },
    {
      key: 'lineGroup',
      Header: () => (
        <IconTooltip.Help text="Shop Items with the same Line Group will be presented in the same line to the players">
          Line Group
        </IconTooltip.Help>
      ),
      Cell: ({ row: { lineGroup } }) => numberToString(lineGroup),
      EditableCell: () => <Input name="lineGroup" type="number" />,
    },
    {
      key: 'labelId',
      Header: () => 'Label',
      Cell: ({ row: { labelId } }) => productLabels.find(({ value }) => value === labelId)?.label,
      EditableCell: () => <Select name="labelId" options={productLabels} />,
    },
    {
      key: 'purchaseLimit',
      Header: () => (
        <IconTooltip.Help text="This limit should prevent only abuse of the limited sale products. The product will
          appear in the Shop if the user hasn't changed their segment"
        >
          Purchase Limit
        </IconTooltip.Help>
      ),
      Cell: ({ row: { purchaseLimit } }) => numberToString(purchaseLimit),
      EditableCell: () => <Input name="purchaseLimit" type="number" step="1" />,
    },
    {
      key: 'saleType',
      Header: () => 'Sale Type',
      Cell: ({ row: { saleType } }) => productSaleTypes.find(({ value }) => value === saleType)?.label,
      EditableCell: ({ setValue, rowPath, row: { priceType } }) => (
        priceType !== priceTypesEnum.ADS
          && (
            <Select
              name="saleType"
              onChange={(value) => clearSaleValues(setValue, rowPath, value)}
              options={productSaleTypes}
            />
          )
      ),
    },
    {
      key: 'saleValue',
      Header: () => 'Sale Value',
      keysToCheck: ['saleStoreProductId', 'saleValue', 'moreValue', 'extraAmount'],
      Cell: ({
        row: {
          priceType, saleType, moreValue, saleStoreProductId, saleValue, extraAmount,
        },
      }) => (
        <>
          {saleType === saleTypesEnum.MORE_VALUE && `${numberToString(moreValue)}% more`}
          {saleType === saleTypesEnum.EXTRA_AMOUNT && `${numberToString(extraAmount)} extra`}
          {priceType === priceTypesEnum.STORE_PRODUCT && saleType === saleTypesEnum.PRICE_CUT
            && storeProductsOptions.find(({ value }) => value === saleStoreProductId)?.label}
          {priceType === priceTypesEnum.IN_GAME_CURRENCY && saleType === saleTypesEnum.PRICE_CUT
            && numberToString(saleValue)}
        </>
      ),
      EditableCell: ({ row: { priceType, saleType } }) => (
        <>
          {saleType === saleTypesEnum.MORE_VALUE
            && (
            <InputWithAddon
              name="moreValue"
              after="% more"
              type="number"
              style={{ width: '2rem' }}
              groupOptions={{ style: { minWidth: '10rem', maxWidth: '10rem' } }}
            />
            )}
          {saleType === saleTypesEnum.EXTRA_AMOUNT && (
            <InputWithAddon
              name="extraAmount"
              after="extra"
              step="0.01"
              type="number"
              style={{ width: '2rem' }}
              groupOptions={{ style: { minWidth: '10rem', maxWidth: '10rem' } }}
            />
          )}
          {priceType === priceTypesEnum.STORE_PRODUCT
            && saleType === saleTypesEnum.PRICE_CUT
            && <Select name="saleStoreProductId" options={storeProductsOptions} isSearchable />}
          {priceType === priceTypesEnum.IN_GAME_CURRENCY
            && saleType === saleTypesEnum.PRICE_CUT
            && <Input name="saleValue" />}
        </>
      ),
    },
    {
      key: 'roundingType',
      Header: () => 'Items Rounding Type',
      Cell: ({ row: { priceType, saleType, roundingType } }) => (
        priceType !== priceTypesEnum.ADS && saleType === saleTypesEnum.MORE_VALUE
          && roundTypes.find(({ value }) => value === roundingType)?.label
      ),
      EditableCell: ({ row: { priceType, saleType } }) => (
        priceType !== priceTypesEnum.ADS
          && saleType === saleTypesEnum.MORE_VALUE
          && <Select name="roundingType" options={roundTypes} />
      ),
    },
  ];
  return [defaultProductValues, columns];
};

const ProductRow = ({
  columns, index, tableEditable, productsDiff,
}) => {
  const { generateName } = useFormGroup();
  const { watch, formState: { errors }, setValue } = useFormContext();
  const rowPath = generateName('').slice(0, -1);
  const row = watch(rowPath);
  const rowDiff = productsDiff?.[index];

  return (
    columns.map(({
      key, EditableCell, Cell, keysToCheck,
    }) => {
      const Component = tableEditable ? EditableCell : Cell;
      const hasErrors = get(errors, rowPath);
      const differs = rowDiff && (rowDiff.isNew || some(uniq([key, ...(keysToCheck || [])]).map((k) => !!rowDiff[k])));

      return (
        <StyledTd key={key} differsFromControlCell={differs}>
          <Component key={key} row={row} index={index} hasErrors={hasErrors} setValue={setValue} rowPath={rowPath} />
        </StyledTd>
      );
    })
  );
};

const ProductsTable = ({
  fields, columns, productPath, tableEditable, productsDiff,
}) => (
  <Table size="sm">
    <thead>
      <tr>
        {columns.map(({ key, Header, width }) => <th key={key} width={width}><Header key={key} /></th>)}
      </tr>
    </thead>
    <tbody>
      {fields.map((field, index) => (
        field._destroy || (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`${productPath}.${field.key}.${index}`}>
            <FormGroup name={`${productPath}.${index}`}>
              <ProductRow columns={columns} index={index} tableEditable={tableEditable} productsDiff={productsDiff} />
            </FormGroup>
          </tr>
        )
      ))}
    </tbody>
  </Table>
);

export { useBaseColumns };
export default ProductsTable;
