import React, { useCallback } from 'react';
import { useRequireAuth, useRouter, Alert } from '@tripledotstudios/react-core';

import { useQuery, useAppData, useCurrentApplication } from '@hooks';
import { SegmentationRoutes } from '@pages/routes';
import { buildSimpleResponseHandler } from '@services/response_handler';
import {
  Pagination,
  PageHeader,
  Button,
  Pane,
} from '@components';

import List from './List';

const Routes = SegmentationRoutes.Segments;

const harmonySegmentsUrl = () => {
  const { environment } = useAppData();
  const { app_id: appId } = useCurrentApplication();

  const env = environment === 'production' ? 'harmony' : 'harmony-staging';
  return `https://${env}.tripledotapi.com/admin/applications/ps/${appId}/segmentation/segments`;
};

export default function SegmentsIndex() {
  useRequireAuth();

  const { query } = useRouter();
  const { response, refetch } = useQuery(Routes.indexRequest, { includeInUse: true });
  const canSynchronize = true;

  const synchronizeResponseHandler = buildSimpleResponseHandler({ onSuccess: refetch });
  const handleSynchronizeItems = useCallback(
    () => Routes.synchronizeRequest(query).then(synchronizeResponseHandler),
    [],
  );

  return (
    <>
      <PageHeader title="Segments">
        <Alert variant="info" className="me-5 m-0 p-2">
          Segments can be created and edited only
          {' '}
          <a href={harmonySegmentsUrl()} target="_blank" rel="noreferrer">through Harmony</a>
        </Alert>
        {canSynchronize && <Button.Synchronize onClick={handleSynchronizeItems} />}
      </PageHeader>
      <Pane>
        <List response={response} />
        <Pagination
          pageCount={response?._meta?.pages}
          refetch={refetch}
        />
      </Pane>
    </>
  );
}
