import React from 'react';
import { Table as BsTable } from 'react-bootstrap';
import { useTable } from 'react-table';
import styled from 'styled-components';

const StyledTable = styled(BsTable)`
  tr td {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  tr:hover td {
    background: #f4f4f4;
  }
  tr.active td, tr.active:hover td{
    background: #3c699e2b;
  }
`;

const columnWidth = (column) => {
  const { customMinWidth, customMaxWidth } = column;
  if (column.id === 'id') return { minWidth: customMinWidth || 25, maxWidth: customMaxWidth || 40 };
  if (column.id === 'name') return { minWidth: customMinWidth || 200, maxWidth: customMaxWidth || 300 };

  return { minWidth: customMinWidth || 130, maxWidth: customMaxWidth || 240 };
};

const Table = ({
  columns, data, onRowClick, emptyPlaceholder, selectedRowId,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <StyledTable className="table-sm" {...getTableProps()}>
      <thead className="sticky-top">
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr {...headerGroupProps} key={key}>
              {headerGroup.headers.map((column) => {
                const { key: headerKey, ...headerProps } = column.getHeaderProps({
                  style: columnWidth(column),
                });
                return (
                  <th {...headerProps} key={headerKey}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {emptyPlaceholder && rows.length === 0 && (
          <tr><td align="center" colSpan={columns.length}>{emptyPlaceholder}</td></tr>
        )}
        {rows.map((row) => {
          prepareRow(row);
          const { key, ...rowProps } = row.getRowProps();
          return (
            <tr
              {...rowProps}
              key={key}
              className={row.original.id === selectedRowId ? 'active' : ''}
              onClick={onRowClick && (() => onRowClick(row.original))}
            >
              {row.cells.map((cell) => {
                const { key: cellKey, ...cellProps } = cell.getCellProps({
                  style: {
                    ...columnWidth(cell.column),
                    overflow: 'auto',
                  },
                  className: cell.column.className,
                });

                return (
                  <td {...cellProps} key={cellKey}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
