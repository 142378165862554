import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${(props) => props.size}rem;
`;

const UploadIcon = ({ size = 1.2 }) => <StyledIcon size={size} className="bi bi-cloud-arrow-up-fill" />;

export default memo(UploadIcon);
