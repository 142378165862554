import React from 'react';
import { isEmpty } from 'lodash';

import { useAppData } from '@hooks';
import { Form } from '@components';
import { FloatingLabeledInput } from '@components/resource';
import { TemplateParametersForm } from '@components/dynamic_parameters';

const ALLOWED_TYPES = ['boolean', 'integer', 'string', 'float', 'asset_bundle'];

export default function ObjectiveTemplatesForm({ resource, ...rest }) {
  const inUse = !isEmpty(resource.data.inUse);
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_OBJECTIVE];

  return (
    <Form.Resource resource={resource} {...rest}>
      <Form.ControlsLayout>
        <FloatingLabeledInput label="Name" disabled={inUse} name="name" />
        <FloatingLabeledInput label="Key" disabled={inUse} name="key" />
      </Form.ControlsLayout>

      <TemplateParametersForm inUse={inUse} allowedTypes={ALLOWED_TYPES} allowedAssetBundlesTypes={assetBundlesTypes} />
    </Form.Resource>
  );
}
