import React from 'react';

export default function Logo({ minimized, className }) {
  return (
    <img
      src={minimized ? '/images/logo-min.svg' : '/images/logo-text.svg'}
      className={className}
      alt="LiveOps Server Logo"
    />
  );
}
