import React from 'react';
import { Table } from 'react-bootstrap';
import { map } from 'lodash';
import { FormGroup, useFormGroup } from '@tripledotstudios/react-core';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { Input } from '@components/resource';
import { useFormPermissions } from '@hooks';
import Button from '@components/buttons';

export default function ConfigurationList({ disabled }) {
  const { readOnly } = useFormPermissions();
  const { generateName } = useFormGroup();
  const { control, clearErrors, setValue } = useFormContext();
  const formPath = 'listOptions';
  const formGroupName = generateName(formPath);
  const { fields, append, remove } = useFieldArray({ control, name: formGroupName });

  const clearDefault = () => setValue(generateName('defaultValue'), null);

  const handleAdd = () => {
    append({ key: '' });
    clearDefault();
    clearErrors();
  };
  const handleDelete = (targetIndex) => {
    remove(targetIndex);
    clearDefault();
    clearErrors();
  };

  return (
    <>
      {fields.length > 0 && (
        <Table size="sm" borderless>
          <tbody>
            {map(fields, (field, index) => (
              <FormGroup key={`${field.id}.${formPath}.${index}`} name={`${formPath}.${index}`}>
                <tr>
                  <td className="p-0"><Input disabled={disabled} name="key" /></td>
                  {!readOnly && !disabled && (
                    <td className="p-0">
                      <Button.Delete onClick={() => handleDelete(index)} title="Remove" />
                    </td>
                  )}
                </tr>
              </FormGroup>

            ))}
          </tbody>
        </Table>
      )}
      {!readOnly && !disabled && <Button.Add title="Add option" size="sm" onClick={handleAdd} />}
    </>
  );
}
