import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${({ size }) => size}rem;
  color: ${({ color }) => color};
`;

const UploadedIcon = ({ size = 1.2, color = '#2eb85c' }) => (
  <StyledIcon color={color} size={size} className="bi bi-cloud-check-fill" />
);

export default memo(UploadedIcon);
