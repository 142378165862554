import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';

import { Input, Select, DiffWrapper } from '@components/resource';
import Button from '@components/buttons';

export default function ContributionConfiguration({
  triggers, item, handleDelete, index, readOnly, triggersOptions,
}) {
  const { generateName } = useFormGroup();
  const [triggerArgumentsOptions, setTriggerArgumentsOptions] = useState([]);
  const { getValues, setValue } = useFormContext();
  const triggerId = getValues(generateName('triggerId'));

  useEffect(() => {
    if (!triggerId) {
      return;
    }

    setTriggerArgumentsOptions(
      triggers.find(({ id }) => +id === +triggerId)
        .triggerArgumentsAttributes.map(({ id, key }) => ({ label: key, value: id })),
    );
  }, []);

  const handleTriggerChange = (newTriggerId) => {
    setTriggerArgumentsOptions(
      triggers.find(({ id }) => +id === +newTriggerId)
        .triggerArgumentsAttributes.map(({ id, key }) => ({ label: key, value: id })),
    );
    setValue(generateName('triggerArgumentId'), null);
  };
  const argumentWrapperKey = `argument_select_key_${triggerId}`;

  return (
    <tr>
      <td>
        <DiffWrapper for="triggerId">
          <Select
            name="triggerId"
            label="Topup Trigger"
            onChange={handleTriggerChange}
            options={triggersOptions}
          />
        </DiffWrapper>
      </td>
      <td key={argumentWrapperKey}>
        <DiffWrapper for="triggerArgumentId">
          <Select
            name="triggerArgumentId"
            label="Contribution Parameter"
            options={triggerArgumentsOptions}
          />
        </DiffWrapper>
      </td>
      <td>
        <DiffWrapper for="contributionPercent">
          <Input name="contributionPercent" type="number" step="0.01" />
        </DiffWrapper>
      </td>
      {!readOnly && (
        <td><Button.Delete title="Remove" onClick={() => handleDelete(item, index)} /></td>
      )}
    </tr>
  );
}
