import React, { memo } from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
  font-size: ${(props) => props.size}rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: ${(props) => props.size - 1.5}rem;
    color: white;
    position: absolute;
    animation: rotation 2s infinite linear;
  }
`;

const UploadProcessingIcon = ({ size = 1.2 }) => (
  <StyledIcon size={size} className="bi bi-cloud-fill">
    <i className="bi bi-arrow-repeat" />
  </StyledIcon>
);

export default memo(UploadProcessingIcon);
