import { reduce } from 'lodash';

const minuteDuration = 60;
const hourDuration = minuteDuration * 60;
const dayDuration = hourDuration * 24;

export default function durationText(durationInSeconds) {
  const units = {
    day: Math.floor(durationInSeconds / dayDuration),
    hour: Math.floor((durationInSeconds % dayDuration) / hourDuration),
    minute: Math.floor((durationInSeconds % hourDuration) / minuteDuration),
    second: durationInSeconds % minuteDuration,
  };
  return reduce(
    units,
    (strings, value, unit) => (
      value === 0
        ? strings
        : [...strings, `${value} ${value === 1 ? unit : `${unit}s`}`]),
    [],
  ).join(', ');
}
