import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useBlocker } from 'react-router-dom';

import { useRouter } from '@tripledotstudios/react-core';

import { DeleteIcon } from '@components/icons';
import Button from '@components/buttons';

export default function RouteLeavingGuard({
  when, onSave, onDiscard,
}) {
  const router = useRouter();

  const whenValue = when instanceof Function ? when() : when;
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname === nextLocation.pathname) return false;

    return whenValue;
  });

  const closeModal = () => {
    blocker.reset?.();
  };

  const handleSaveClick = async () => {
    const response = await onSave();
    blocker.reset?.();
    if (response.status < 300) {
      router.push(blocker.location.pathname);
    }
  };

  const handleDiscardClick = () => {
    blocker.proceed?.();
    onDiscard();
  };

  useEffect(() => {
    if (blocker.state === 'blocked' && !whenValue) {
      blocker.reset?.();
    }
  }, [blocker, whenValue]);

  return (
    <Modal
      show={blocker.state === 'blocked' && location.pathname !== blocker.location.pathname}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Review changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have made changes. Do you want to discard or save them?
      </Modal.Body>
      <Modal.Footer>
        <Button.Cancel variant="secondary" onClick={closeModal} />
        <Button.Action
          icon={<DeleteIcon color="#fff" />}
          variant="danger"
          className="me-1"
          onClick={handleDiscardClick}
          title="Discard"
        />
        <Button.Submit onClick={handleSaveClick} />
      </Modal.Footer>
    </Modal>
  );
}
