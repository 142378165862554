import React, { createRef, useState } from 'react';
import { map, join } from 'lodash';
import { useFlashMessages } from '@tripledotstudios/react-core';
import JSZip from 'jszip';

const DropFile = ({
  allowedExtensions = [], // any extensions
  maxFileSize = 104857600,
  maxFileSizeHuman = '100Mb',
  uiRender,
  onFile,
}) => {
  const inputOpenFileRef = createRef(null);
  const [dragOver, setDragOver] = useState(false);
  const { error } = useFlashMessages();

  const onFilesOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragOver(true);
  };

  const onFilesOut = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragOver(false);
  };

  const allowedExtension = (file) => {
    if (!allowedExtensions || allowedExtensions.length === 0) {
      return true;
    }

    const result = allowedExtensions.indexOf(file.name.split('.').pop().toLowerCase()) > -1;
    if (!result) error(`Only ${join(allowedExtensions, ',')} file extensions are allowed`);
    return result;
  };

  const allowedSize = (file) => {
    const result = file.size <= maxFileSize;
    if (!result) error(`File size should be less than ${maxFileSizeHuman}`);
    return result;
  };

  const onDropFiles = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragOver(false);
    const file = event.dataTransfer.files[0];

    if (file && allowedExtension(file) && allowedSize(file)) {
      onFile(file);
    }
  };

  const onUploadFiles = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setDragOver(false);
    const file = event.target.files[0];
    if (file && allowedSize(file)) {
      onFile(file);
    }
    /* eslint-disable no-param-reassign */
    event.target.value = null; // to be able to select file with same name twice
  };

  const showOpenFileDlg = () => inputOpenFileRef.current.click();
  /* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
  return (
    <div onClick={showOpenFileDlg}>
      <input
        className="input"
        ref={inputOpenFileRef}
        type="file"
        multiple={false}
        accept={join(map(allowedExtensions, (ext) => `.${ext}`), ',')}
        style={{ display: 'none' }}
        onChange={onUploadFiles}
      />
      <div
        onDrop={onDropFiles}
        onDragOver={onFilesOver}
        onDragLeave={onFilesOut}
      >

        {uiRender(dragOver ? 'onDragOver' : '')}
      </div>
    </div>
  );
};

DropFile.Zip = ({
  allowedExtensions = ['zip'],
  requiredFiles = [],
  onFile,
  ...rest
}) => {
  const { error } = useFlashMessages();

  const handleFileChange = (zipFile) => {
    new JSZip().loadAsync(zipFile)
      .then((zip) => {
        const missedFiles = [];

        map(requiredFiles, (filename) => {
          const file = zip.files[filename];
          if (!file || file.dir) {
            missedFiles.push(filename);
          }
        });
        if (missedFiles.length === 0) {
          onFile(zipFile);
        } else {
          error(`Zip file should include files: ${join(missedFiles, ', ')}`);
        }
      }).catch(() => error(`File "${zipFile.name}" does not look like valid zip`));
  };

  return (
    <DropFile
      onFile={handleFileChange}
      allowedExtensions={allowedExtensions}
      {...rest}
    />
  );
};

export default DropFile;
