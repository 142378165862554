import { get, isString, isObject } from 'lodash';

import { useRouter, useFlashMessages } from '@tripledotstudios/react-core';
import flattenErrors from '@services/flatten_errors';

const redirectToPath = (path) => {
  const router = useRouter();

  return () => router.forcePush(path(router.query));
};

// TODO: Remove after all forms migration to the buildFormResponseHandler
const buildResourceResponseHandler = ({
  actionName,
  setError,
  onSuccess,
  successMessage = `Record ${actionName} have been successfully finished`,
}) => {
  const { success, error } = useFlashMessages();

  return (response) => {
    const errors = get(response, ['data', '_meta', 'errors']);

    switch (response.status) {
      case 200:
      case 201:
      case 204:
        success(successMessage);
        onSuccess(response);
        break;
      case 403:
        error(response.data.reason);
        break;
      case 422:
        if (isObject(errors)) {
          Object.entries(flattenErrors(errors) || [])
            .forEach(([name, messages]) => setError(name, { message: messages.join(', ') }));
        }
        if (isString(errors)) error(errors);

        error(response.data.message || `Record ${actionName} has failed`);
        break;
      default:
        error('Something went wrong');
    }

    return response;
  };
};

const buildFormResponseHandler = ({ setError, onSuccess, flashMessages: { success, error } }) => (response) => {
  const errors = get(response, ['data', '_meta', 'errors']);

  switch (response.status) {
    case 200:
    case 201:
    case 204:
      success('Saved successfully');
      onSuccess(response);
      break;
    case 403:
      error(response.data.reason);
      break;
    case 422:
      if (isObject(errors)) {
        Object.entries(flattenErrors(errors) || [])
          .forEach(([name, messages]) => setError(name, { message: messages.join(', ') }));
      }
      if (isString(errors)) error(errors);

      error(response.data.message || 'Operation has failed');
      break;
    default:
      error('Something went wrong');
  }

  return response;
};

const buildDeleteResourceResponseHandler = ({ onSuccess }) => {
  const { success, error } = useFlashMessages();

  return (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 204:
        success('Record have been successfully deleted');
        onSuccess(response);
        break;
      case 403:
        error(response.data.reason);
        break;
      case 422:
        error(response.data._meta.errors);
        break;
      default:
        error('Record deletion has failed');
    }

    return response;
  };
};

const buildSimpleResponseHandler = ({ onSuccess, successText = 'Operation was finished successfully' }) => {
  const { success, error } = useFlashMessages();

  const buildErrorMessages = (response) => {
    const responseErrors = get(response, ['data', '_meta', 'errors']);
    let result;

    if (isObject(responseErrors)) {
      result = Object.entries(flattenErrors(responseErrors))
        .reduce((memo, [key, msg]) => [...memo, `${key}: ${msg}\n`], []);
    } else {
      result = [responseErrors];
    }

    return result;
  };

  return (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 204:
        if (successText) success(successText);
        onSuccess(response);
        break;
      case 403:
        error(response.data.reason);
        break;
      case 422:
        error(buildErrorMessages(response).join(', '));
        break;
      default:
        error('Operation has failed');
    }

    return response;
  };
};

const buildUploadHandler = ({ onResult }) => {
  const { success, error } = useFlashMessages();

  return (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 204:
        success('File have been successfully processed');
        onResult(response);
        break;
      case 403:
        error(response.data.reason);
        break;
      case 422:
        onResult(response);
        error('File processing has failed');
        break;
      default:
        onResult({});
        error('Something went wrong');
    }

    return response;
  };
};

export {
  buildDeleteResourceResponseHandler,
  buildResourceResponseHandler,
  buildFormResponseHandler,
  buildSimpleResponseHandler,
  buildUploadHandler,
  redirectToPath,
};
