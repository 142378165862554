import React, { useState, useEffect } from 'react';
import { Modal, Card, Table } from 'react-bootstrap';
import {
  map, isObject, isEmpty, merge, isNull, isArray,
} from 'lodash';
import { useRouter, Tooltip } from '@tripledotstudios/react-core';
import styled from 'styled-components';
import { buildSimpleResponseHandler } from '@services/response_handler';

import { Button } from '@components';
import { ErrorIcon, OpenedIcon } from '@components/icons';
import buildDiff, { findDiffObject } from '@services/build_diff';

const VALUE_CREATED = 'created';
const VALUE_UPDATED = 'updated';
const VALUE_DELETED = 'deleted';

const compareValues = (value1, value2) => {
  if (value1 === value2) {
    return null;
  }
  if (value1 === undefined) {
    return { [VALUE_CREATED]: true };
  }
  if (value2 === undefined) {
    return { [VALUE_DELETED]: true };
  }
  return { [VALUE_UPDATED]: true };
};

const isValue = (x) => !isObject(x) && !isArray(x);

const validArrayElement = () => true;

const clearValue = (value) => isNull(value) || isEmpty(value);

const diffBuilderOptions = {
  compareValues, clearValue, isValue, validArrayElement,
};

const TdDiffWrapper = styled.td`
  max-width: 25vw;
  ${({ width2x }) => `width: ${width2x ? '48' : '24'}%`};
  ${({ nestingLevel }) => nestingLevel && `padding-left: ${nestingLevel + 0.25}rem !important`};
  ${({ updated, created, isNew }) => (updated || created) && isNew && 'background: #daffd1 !important'};
  ${({
    deleted, updated, isNew, isOld,
  }) => ((deleted && isNew) || (updated && isOld)) && 'background: #ffc3c3 !important'};
`;

const TdBorderless = styled.td`border: none;`;

const hasInnerDiff = (item) => isObject(item) && !item.updated && !item.created && !item.deleted;

const Item = ({
  field, newData, oldData, diff, nestingLevel = -1, intialOpened = false, skipField = false,
}) => {
  const [opened, setOpened] = useState(intialOpened);
  const newNestingLevel = nestingLevel + 1;

  let dataToIterate;
  if (!isEmpty(newData)) {
    if (isObject(oldData)) dataToIterate = merge({}, newData, oldData);
    else dataToIterate = newData;
  } else if (!isEmpty(oldData)) {
    if (isObject(newData)) dataToIterate = merge({}, oldData, newData);
    else dataToIterate = oldData;
  }

  if (dataToIterate && isObject(dataToIterate)) {
    const values = (
      <Table size="sm">
        <tbody>
          {map(dataToIterate, (_internalValue, internalKey) => {
            let itemDiff;
            if (diff && diff[field] && (diff[field].updated || diff[field].created || diff[field].deleted)) {
              itemDiff = { [internalKey]: diff[field] };
            } else {
              itemDiff = skipField ? diff : (diff[field] || {});
            }

            const newDataObject = findDiffObject(newData, oldData?.[internalKey], internalKey);

            return (
              <Item
                key={internalKey}
                nestingLevel={newNestingLevel}
                diff={itemDiff}
                field={internalKey}
                newData={(isObject(newData) ? newDataObject : newData)}
                oldData={(isObject(oldData) ? oldData[internalKey] : oldData)}
              />
            );
          })}
        </tbody>
      </Table>
    );
    if (skipField) {
      return values;
    }
    const innerDiff = diff[field] && hasInnerDiff(diff[field]);

    let diffOptions = diff;
    if (diff[field] && !innerDiff) diffOptions = diff[field];
    const header = (tdOptions) => (
      <TdDiffWrapper
        nestingLevel={nestingLevel}
        {...tdOptions}
        {...diffOptions}
        colSpan={2}
        style={{ border: 'none' }}
        width2x
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div style={{ cursor: 'pointer' }} onClick={() => setOpened(!opened)}>
          <OpenedIcon opened={opened} />
          {' '}
          {innerDiff ? (
            <Tooltip text="Has changes" placement="right">
              {field}
              <ErrorIcon color="#dda500" />
            </Tooltip>
          )
            : field}
        </div>
      </TdDiffWrapper>
    );
    return (
      <>
        <tr key={field}>
          {header({ isOld: true })}
          <TdBorderless width="30px" />
          {header({ isNew: true })}
        </tr>
        <tr key={`${field}-values`}>
          <TdBorderless colSpan={5}>
            {opened && values}
          </TdBorderless>
        </tr>
      </>
    );
  }

  return (
    <tr key={field}>
      <TdDiffWrapper nestingLevel={nestingLevel} isOld {...diff[field]}>{field}</TdDiffWrapper>
      <TdDiffWrapper nestingLevel={nestingLevel} isOld {...diff[field]}>{oldData && String(oldData)}</TdDiffWrapper>
      <TdBorderless width="30px" />
      <TdDiffWrapper nestingLevel={nestingLevel} isNew {...diff[field]}>{field}</TdDiffWrapper>
      <TdDiffWrapper nestingLevel={nestingLevel} isNew {...diff[field]}>{newData && String(newData)}</TdDiffWrapper>
    </tr>
  );
};

const LogModal = ({ handleClose, routes, logId }) => {
  const [log, setLog] = useState();
  const { query } = useRouter();

  const handleResponse = buildSimpleResponseHandler({ onSuccess: (res) => setLog(res.data), successText: null });
  useEffect(() => {
    routes.editRequest({ ...query, id: logId }).then(handleResponse);
  }, [logId]);

  if (!log) return null;

  const {
    action, ownerType, ownerId, author, previousData: previousDataRaw, data: dataRaw,
  } = log;
  const previousData = JSON.parse(previousDataRaw);
  const data = JSON.parse(dataRaw);

  const diff = buildDiff(previousData, data, diffBuilderOptions);

  return (
    <Modal show={!!log} onHide={handleClose} dialogClassName="modal-80w">
      <Modal.Header closeButton>
        <Modal.Title>
          {author}
          {' '}
          {action}
          {' '}
          {ownerType}
          #
          {ownerId}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body>
            {data && <Item field="Data" diff={diff} skipField intialOpened oldData={previousData} newData={data} />}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button.Cancel title="Close" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
};

export default LogModal;
