import React from 'react';

import { LabeledSelectWithLink, LabeledTimePeriodField } from '@components/resource';
import { useCurrentApplication } from '@hooks';
import { Form } from '@components';
import { PaymentsRoutes } from '@pages/routes';

export default function PresetConfig({ presets }) {
  const applicationId = useCurrentApplication().id;
  const presetsOptions = [
    { label: 'None', value: 0 },
    ...presets.items.map(({ id, name }) => ({ label: name, value: id })),
  ];
  const periodInputs = ['hours', 'minutes', 'seconds'];

  return (
    <>
      <LabeledSelectWithLink
        label="Preset"
        name="presetId"
        buildLinkUrl={(id) => PaymentsRoutes.Presets.editPath({ applicationId, id })}
        options={presetsOptions}
      />
      <Form.When name="presetId" value={(value) => value}>
        <LabeledTimePeriodField
          label="Duration"
          name="presetDuration"
          tooltipText="How long the preset is active, max up to the campaign end.
            Zero means serve until the campaign ends."
          inputs={periodInputs}
        />
        <LabeledTimePeriodField
          label="Minimum Duration"
          name="presetMinimumDuration"
          tooltipText="Minimum time to serve; inactive if the campaign ends sooner."
          inputs={periodInputs}
        />
      </Form.When>
    </>
  );
}
