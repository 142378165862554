import React, { useMemo } from 'react';
import { Table, EmptyList } from '@components/collection';
import Button from '@components/buttons';
import { ShowIcon } from '@components/icons';
import { InUse } from '@components';

export default function StoreProductsList({
  response, onEditButtonClick, routes, onDelete, selectedResourceId,
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Store ID',
        accessor: 'storeId',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Updated at (UTC)',
        accessor: 'updatedAt',
      },

      InUse.InUseColumn,

      {
        Header: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row: {
            original: {
              id, inUse, storeId, permissions,
            },
          },
        }) => {
          const isInUse = InUse.isInUse(inUse);

          return (
            <>
              {permissions.edit && (
                <Button.Edit icon={<ShowIcon />} title="Show" className="me-2" onClick={() => onEditButtonClick(id)} />
              )}
              {!isInUse && permissions.destroy && (
                <Button.DeleteConfirm
                  id={id}
                  routes={routes}
                  onDelete={onDelete}
                  recordName={`Store Product "${storeId}"`}
                />
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  if (!response || response.items.length < 1) {
    return <EmptyList title="No store products yet." />;
  }

  return (
    <Table columns={columns} selectedRowId={selectedResourceId} data={response.items} />
  );
}
