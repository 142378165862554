import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { CancelIcon } from '../icons';

const StyledButton = styled(Button)`
  margin-right: 4px;
`;

const CancelButton = ({ title = 'Cancel', ...props }) => (
  <StyledButton variant="secondary" {...props}>
    <CancelIcon />
    {' '}
    {title}
  </StyledButton>
);

export default CancelButton;
