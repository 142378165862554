import { useState } from 'react';
import { LabeledSelect } from '@components/resource';
import { useFormContext } from 'react-hook-form';
import { useFormGroup } from '@tripledotstudios/react-core';

import { useAppData } from '@hooks';
import { findById, toOptions } from '@services/utils';
import { ParametersForm } from '@components/dynamic_parameters';

export default function DynamicParametersWithTemplate({
  emptyTemplateOptionLabel,
  objectiveTemplates,
  objectiveTemplateLabel = 'Objective template',
  objectiveTemplateName = 'objectiveTemplateId',
}) {
  const { generateName } = useFormGroup();
  const { getValues } = useFormContext();
  const [newTemplate, setNewTemplate] = useState(null);
  const objectiveTemplateId = getValues(generateName(objectiveTemplateName));
  const template = findById(objectiveTemplates, objectiveTemplateId);
  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_OBJECTIVE];

  const objectiveTemplatesOptions = toOptions(objectiveTemplates.items);

  if (emptyTemplateOptionLabel) {
    objectiveTemplatesOptions.unshift({ label: emptyTemplateOptionLabel, value: '' });
  }

  const handleActionChange = (val) => setNewTemplate(findById(objectiveTemplates, val));

  return (
    <>
      <LabeledSelect
        label={objectiveTemplateLabel}
        name={objectiveTemplateName}
        options={objectiveTemplatesOptions}
        onChange={handleActionChange}
        sizes={[3, 9]}
      />
      {(template || newTemplate) && (
        <ParametersForm
          newSourceEntity={newTemplate}
          sourceEntity={template}
          allowedAssetBundlesTypes={assetBundlesTypes}
          entityName="Objective Template"
        />
      )}
    </>
  );
}
