import React from 'react';

import InLiveBadge from './InLiveBadge';
import InLiveColumnHeader from './InLiveColumnHeader';

export default {
  id: 'availability_header',
  Header: () => <InLiveColumnHeader />,
  Cell: ({ row }) => <InLiveBadge inLive={row.original.inLive} />,
};
