import React from 'react';
import tripledotLogo from './tripledot-logo.svg';

const TripledotLogo = ({ width, height, className }) => (
  <img
    src={tripledotLogo}
    width={width}
    height={height}
    className={className}
    alt="Tripledot Logo"
  />
);

export default TripledotLogo;
